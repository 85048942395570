export const FETCH_HOME_DATA = "FETCH_HOME_DATA";
export const FETCH_HOME_RESPONSE = "FETCH_HOME_RESPONSE";
export const HOME_DATA_FAILED = "HOME_DATA_FAILED";


export const FETCH_EXPLORE_BANNER_DATA = "FETCH_EXPLORE_BANNER_DATA";
export const FETCH_EXPLORE_BANNER_RESPONSE = "FETCH_EXPLORE_BANNER_RESPONSE";
export const EXPLORE_BANNER_DATA_FAILED = "EXPLORE_BANNER_DATA_FAILED";


export const FETCH_DESTINATION_BANNER_DATA = "FETCH_DESTINATION_BANNER_DATA";
export const FETCH_DESTINATION_BANNER_RESPONSE = "FETCH_DESTINATION_BANNER_RESPONSE";
export const DESTINATION_BANNER_DATA_FAILED = "DESTINATION_BANNER_DATA_FAILED";


export const FETCH_INTEREST_DATA = "FETCH_INTEREST_DATA";
export const FETCH_INTEREST_RESPONSE = "FETCH_INTEREST_RESPONSE";
export const INTEREST_DATA_FAILED = "INTEREST_DATA_FAILED";


export const FETCH_DESTINATION_DATA = "FETCH_DESTINATION_DATA";
export const FETCH_DESTINATION_RESPONSE = "FETCH_DESTINATION_RESPONSE";
export const DESTINATION_DATA_FAILED = "DESTINATION_DATA_FAILED";


export const FETCH_BOOK_NEXT_DATA = "FETCH_BOOK_NEXT_DATA";
export const FETCH_BOOK_NEXT_RESPONSE = "FETCH_BOOK_NEXT_RESPONSE";
export const BOOK_NEXT_DATA_FAILED = "BOOK_NEXT_DATA_FAILED";


export const FETCH_ACTIVITIES_DATA = "FETCH_ACTIVITIES_DATA";
export const FETCH_ACTIVITIES_RESPONSE = "FETCH_ACTIVITIES_RESPONSE";
export const ACTIVITIES_DATA_FAILED = "ACTIVITIES_DATA_FAILED";

export const FETCH_TOURS_DATA = "FETCH_TOURS_DATA";
export const FETCH_TOURS_RESPONSE = "FETCH_TOURS_RESPONSE";
export const TOURS_DATA_FAILED = "TOURS_DATA_FAILED";

export const FETCH_ACCOMMODATIONS_DATA = "FETCH_ACCOMMODATIONS_DATA";
export const FETCH_ACCOMMODATIONS_RESPONSE = "FETCH_ACCOMMODATIONS_RESPONSE";
export const ACCOMMODATIONS_DATA_FAILED = "ACCOMMODATIONS_DATA_FAILED";


export const FETCH_ORGANIZATION_DATA = "FETCH_ORGANIZATION_DATA";
export const FETCH_ORGANIZATION_RESPONSE = "FETCH_ORGANIZATION_RESPONSE";
export const ORGANIZATION_DATA_FAILED = "ORGANIZATION_DATA_FAILED";


export const FETCH_ABOUTUS_BANNER = "FETCH_ABOUT_BANNER";
export const ABOUTUS_RESPONSE_BANNER = "ABOUTUS_RESPONSE_BANNER";
export const ABOUTUS_BANNER_FAILED = "ABOUTUS_BANNER_FAILED";


export const FETCH_ENQUERY_BANNER = "FETCH_ENQUERY_BANNER";
export const ENQUERY_RESPONSE_BANNER = "ENQUERY_RESPONSE_BANNER";
export const ENQUERY_BANNER_FAILED = "ENQUERY_BANNER_FAILED";


export const FETCH_CONTACTUS_BANNER = "FETCH_CONTACTUS_BANNER";
export const CONTACTUS_RESPONSE_BANNER = "CONTACTUS_RESPONSE_BANNER";
export const CONTACTUS_BANNER_FAILED = "CONTACTUS_BANNER_FAILED";


export const FETCH_COUNTRIES_DATA = "FETCH_COUNTRIES_DATA";
export const COUNTRIES_RESPONSE_DATA = "COUNTRIES_RESPONSE_DATA";
export const COUNTRIES_DATA_FAILED = "COUNTRIES_DATA_FAILED";


export const FETCH_SALUTATION_DATA = "FETCH_SALUTATION_DATA";
export const SALUTATION_RESPONSE_DATA = "SALUTATION_RESPONSE_DATA";
export const SALUTATION_DATA_FAILED = "SALUTATION_DATA_FAILED";


export const FETCH_TENTATIVEBUDGET_DATA = "FETCH_TENTATIVEBUDGET_DATA";
export const TENTATIVEBUDGET_RESPONSE_DATA = "TENTATIVEBUDGET_RESPONSE_DATA";
export const TENTATIVEBUDGET_DATA_FAILED = "TENTATIVEBUDGET_DATA_FAILED";


export const FETCH_HOLIDAYDURATION_DATA = "FETCH_HOLIDAYDURATION_DATA";
export const HOLIDAYDURATION_RESPONSE_DATA = "HOLIDAYDURATION_RESPONSE_DATA";
export const HOLIDAYDURATION_DATA_FAILED = "HOLIDAYDURATION_DATA_FAILED";


export const FETCH_VACCINATION_DATA = "FETCH_VACCINATION_DATA";
export const VACCINATION_RESPONSE_DATA = "VACCINATION_RESPONSE_DATA";
export const VACCINATION_DATA_FAILED = "VACCINATION_DATA_FAILED";


export const FETCH_ENQUIRSOURCE_DATA = "FETCH_ENQUIRSOURCE_DATA";
export const ENQUIRSOURCE_RESPONSE_DATA = "ENQUIRSOURCE_RESPONSE_DATA";
export const ENQUIRSOURCE_DATA_FAILED = "ENQUIRSOURCE_DATA_FAILED";


export const FETCH_TESTIMONIALS_DATA = "FETCH_TESTIMONIALS_DATA";
export const TESTIMONIALS_RESPONSE_DATA = "TESTIMONIALS_RESPONSE_DATA";
export const TESTIMONIALS_DATA_FAILED = "TESTIMONIALS_DATA_FAILED";


export const FETCH_TESTIMONIALS_BANNER = "FETCH_TESTIMONIALS_BANNER";
export const TESTIMONIALS_RESPONSE_BANNER = "TESTIMONIALS_RESPONSE_BANNER";
export const TESTIMONIALS_BANNER_FAILED = "TESTIMONIALS_BANNER_FAILED";


export const FETCH_FEATURED_TOURS = "FETCH_FEATURED_TOURS";
export const FEATURED_TOURS_RESPONSE = "FEATURED_TOURS_RESPONSE";
export const FEATURED_TOURS_FAILED = "FEATURED_TOURS_FAILED";


export const FETCH_ENQUIRY_TOURS = "FETCH_ENQUIRY_TOURS";
export const ENQUIRY_TOURS_RESPONSE = "ENQUIRY_TOURS_RESPONSE";
export const ENQUIRY_TOURS_FAILED = "ENQUIRY_TOURS_FAILED";


export const FETCH_ENQUIRY_ACTIVITY = "FETCH_ENQUIRY_ACTIVITY";
export const ENQUIRY_ACTIVITY_RESPONSE = "ENQUIRY_ACTIVITY_RESPONSE";
export const ENQUIRY_ACTIVITY_FAILED = "ENQUIRY_ACTIVITY_FAILED";


export const FETCH_EXCEPTIONAL_EXPERIENCE = "FETCH_EXCEPTIONAL_EXPERIENCE";
export const EXCEPTIONAL_EXPERIENCE_RESPONSE = "EXCEPTIONAL_EXPERIENCE_RESPONSE";
export const EXCEPTIONAL_EXPERIENCE_FAILED = "EXCEPTIONAL_EXPERIENCE_FAILED";


export const FETCH_EXPERIENCES_DATA = "FETCH_EXPERIENCES_DATA";
export const EXPERIENCES_DATA_RESPONSE = "EXPERIENCES_DATA_RESPONSE";
export const EXPERIENCES_DATA_FAILED = "EXPERIENCES_DATA_FAILED";
