import { put } from 'redux-saga/effects';
import homeApi from "../services/postApi";
import {
    FETCH_HOME_RESPONSE,HOME_DATA_FAILED,
    FETCH_INTEREST_RESPONSE,INTEREST_DATA_FAILED,
    FETCH_DESTINATION_RESPONSE,DESTINATION_DATA_FAILED,
    FETCH_BOOK_NEXT_RESPONSE,BOOK_NEXT_DATA_FAILED,
    FETCH_ACTIVITIES_RESPONSE,ACTIVITIES_DATA_FAILED, 
    FETCH_TOURS_RESPONSE, TOURS_DATA_FAILED, 
    FETCH_ACCOMMODATIONS_RESPONSE,ACCOMMODATIONS_DATA_FAILED, 
    FETCH_EXPLORE_BANNER_RESPONSE, EXPLORE_BANNER_DATA_FAILED,
    FETCH_DESTINATION_BANNER_RESPONSE,DESTINATION_BANNER_DATA_FAILED, 
    FETCH_ORGANIZATION_RESPONSE, ORGANIZATION_DATA_FAILED, 
    ABOUTUS_RESPONSE_BANNER, ABOUTUS_BANNER_FAILED, ENQUERY_RESPONSE_BANNER, 
    ENQUERY_BANNER_FAILED, CONTACTUS_RESPONSE_BANNER, CONTACTUS_BANNER_FAILED, 
    COUNTRIES_RESPONSE_DATA, COUNTRIES_DATA_FAILED, SALUTATION_RESPONSE_DATA, 
    SALUTATION_DATA_FAILED, TENTATIVEBUDGET_RESPONSE_DATA, TENTATIVEBUDGET_DATA_FAILED,
    HOLIDAYDURATION_RESPONSE_DATA, HOLIDAYDURATION_DATA_FAILED, VACCINATION_RESPONSE_DATA, 
    VACCINATION_DATA_FAILED, ENQUIRSOURCE_RESPONSE_DATA, ENQUIRSOURCE_DATA_FAILED, 
    TESTIMONIALS_RESPONSE_DATA, TESTIMONIALS_DATA_FAILED, TESTIMONIALS_RESPONSE_BANNER, 
    TESTIMONIALS_BANNER_FAILED, FEATURED_TOURS_RESPONSE, FEATURED_TOURS_FAILED, ENQUIRY_TOURS_RESPONSE, 
    ENQUIRY_TOURS_FAILED, ENQUIRY_ACTIVITY_RESPONSE, ENQUIRY_ACTIVITY_FAILED, EXCEPTIONAL_EXPERIENCE_RESPONSE, EXCEPTIONAL_EXPERIENCE_FAILED, EXPERIENCES_DATA_RESPONSE, EXPERIENCES_DATA_FAILED

} from '../redux/types/homeType';
let api = new homeApi();

export function* homeSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.HomeApi();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200 ) {
            yield put({ type: FETCH_HOME_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            window.location.reload()
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: HOME_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('HOME_DATA_FAILED', e);
        yield put({ type: HOME_DATA_FAILED, payload: e })

    }
}


export function* interestSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {

        let data = yield api.InterestApi();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: FETCH_INTEREST_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: INTEREST_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('INTEREST_DATA_FAILED', e);
        yield put({ type: INTEREST_DATA_FAILED, payload: e })

    }
}


export function* destinationSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {

        let data = yield api.DestinationApi();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: FETCH_DESTINATION_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: DESTINATION_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('DESTINATION_DATA_FAILED', e);
        yield put({ type: DESTINATION_DATA_FAILED, payload: e })

    }
}


export function* bookYourNextSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {

        let data = yield api.BookYourNextApi();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: FETCH_BOOK_NEXT_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: BOOK_NEXT_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('BOOK_NEXT_DATA_FAILED', e);
        yield put({ type: BOOK_NEXT_DATA_FAILED, payload: e })

    }
}


export function* activitiesSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {

        let data = yield api.Activities();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: FETCH_ACTIVITIES_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: ACTIVITIES_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('ACTIVITIES_DATA_FAILED', e);
        yield put({ type: ACTIVITIES_DATA_FAILED, payload: e })

    }
}

export function* toursSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {

        let data = yield api.Tours();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: FETCH_TOURS_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: TOURS_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('TOURS_DATA_FAILED', e);
        yield put({ type: TOURS_DATA_FAILED, payload: e })

    }
}

export function* accommodationsSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {

        let data = yield api.Accommodations();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: FETCH_ACCOMMODATIONS_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: ACCOMMODATIONS_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('ACCOMMODATIONS_DATA_FAILED', e);
        yield put({ type: ACCOMMODATIONS_DATA_FAILED, payload: e })

    }
}

export function* exploreInterestSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {

        let data = yield api.ExploreBanner();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: FETCH_EXPLORE_BANNER_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: EXPLORE_BANNER_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('EXPLORE_BANNER_DATA_FAILED', e);
        yield put({ type: EXPLORE_BANNER_DATA_FAILED, payload: e })

    }
}


export function* destinationBannerSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {

        let data = yield api.DestinationBanner();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: FETCH_DESTINATION_BANNER_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: DESTINATION_BANNER_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('DESTINATION_BANNER_DATA_FAILED', e);
        yield put({ type: DESTINATION_BANNER_DATA_FAILED, payload: e })

    }
}


export function* organizationSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Organization();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: FETCH_ORGANIZATION_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: ORGANIZATION_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('ORGANIZATION_DATA_FAILED', e);
        yield put({ type: ORGANIZATION_DATA_FAILED, payload: e })

    }
}


export function* aboutusSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Aboutus();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: ABOUTUS_RESPONSE_BANNER, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: ABOUTUS_BANNER_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('ABOUTUS_BANNER_FAILED', e);
        yield put({ type: ABOUTUS_BANNER_FAILED, payload: e })

    }
}


export function* enquirybannerSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Enquirybanner();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: ENQUERY_RESPONSE_BANNER, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: ENQUERY_BANNER_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('ENQUERY_BANNER_FAILED', e);
        yield put({ type: ENQUERY_BANNER_FAILED, payload: e })

    }
}


export function* contactusbannerSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Contactus();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: CONTACTUS_RESPONSE_BANNER, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: CONTACTUS_BANNER_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('CONTACTUS_BANNER_FAILED', e);
        yield put({ type: CONTACTUS_BANNER_FAILED, payload: e })

    }
}


export function* countriesSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Countries();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: COUNTRIES_RESPONSE_DATA, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: COUNTRIES_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('COUNTRIES_DATA_FAILED', e);
        yield put({ type: COUNTRIES_DATA_FAILED, payload: e })

    }
}


export function* salutationSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Salutation();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: SALUTATION_RESPONSE_DATA, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: SALUTATION_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('SALUTATION_DATA_FAILED', e);
        yield put({ type: SALUTATION_DATA_FAILED, payload: e })

    }
}


export function* tentativeBudgetSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.TentativeBudget();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: TENTATIVEBUDGET_RESPONSE_DATA, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: TENTATIVEBUDGET_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('TENTATIVEBUDGET_DATA_FAILED', e);
        yield put({ type: TENTATIVEBUDGET_DATA_FAILED, payload: e })

    }
}


export function* holidayDurationSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.HolidayDuration();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: HOLIDAYDURATION_RESPONSE_DATA, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: HOLIDAYDURATION_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('HOLIDAYDURATION_DATA_FAILED', e);
        yield put({ type: HOLIDAYDURATION_DATA_FAILED, payload: e })

    }
}


export function* vaccinationSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Vaccination();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: VACCINATION_RESPONSE_DATA, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: VACCINATION_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('VACCINATION_DATA_FAILED', e);
        yield put({ type: VACCINATION_DATA_FAILED, payload: e })

    }
}

export function* enquirsourceSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Enquirsource();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: ENQUIRSOURCE_RESPONSE_DATA, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: ENQUIRSOURCE_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('ENQUIRSOURCE_DATA_FAILED', e);
        yield put({ type: ENQUIRSOURCE_DATA_FAILED, payload: e })

    }
}


export function* testimonialSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Testimonials();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: TESTIMONIALS_RESPONSE_DATA, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: TESTIMONIALS_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('TESTIMONIALS_DATA_FAILED', e);
        yield put({ type: TESTIMONIALS_DATA_FAILED, payload: e })

    }
}


export function* testimoBannarSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.TestimonialsBanner();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: TESTIMONIALS_RESPONSE_BANNER, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: TESTIMONIALS_BANNER_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('TESTIMONIALS_BANNER_FAILED', e);
        yield put({ type: TESTIMONIALS_BANNER_FAILED, payload: e })

    }
}


export function* featuredToursSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.FeaturedTours();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: FEATURED_TOURS_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: FEATURED_TOURS_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('FEATURED_TOURS_FAILED', e);
        yield put({ type: FEATURED_TOURS_FAILED, payload: e })

    }
}


export function* tourenquirySaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Tourenquiry();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: ENQUIRY_TOURS_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: ENQUIRY_TOURS_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('ENQUIRY_TOURS_FAILED', e);
        yield put({ type: ENQUIRY_TOURS_FAILED, payload: e })

    }
}


export function* activityEnquirySaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Activityenquiry();
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: ENQUIRY_ACTIVITY_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: ENQUIRY_ACTIVITY_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('ENQUIRY_ACTIVITY_FAILED', e);
        yield put({ type: ENQUIRY_ACTIVITY_FAILED, payload: e })

    }
}

export function* exceptionalExpSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.ExceptionalExperience()
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: EXCEPTIONAL_EXPERIENCE_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: EXCEPTIONAL_EXPERIENCE_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('EXCEPTIONAL_EXPERIENCE_FAILED', e);
        yield put({ type: EXCEPTIONAL_EXPERIENCE_FAILED, payload: e })

    }
}

export function* exceptionalSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Experiences()
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data.status === 200) {
            yield put({ type: EXPERIENCES_DATA_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: EXPERIENCES_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('EXPERIENCES_DATA_FAILED', e);
        yield put({ type: EXPERIENCES_DATA_FAILED, payload: e })

    }
}