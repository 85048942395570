import React,{ useState } from "react";
import {FaStar} from"react-icons/fa";


const StarRating = ({stars, size, activColor, diActivColor }) => {

  const [rating,setRating] = useState(null);
  const [hover,setHover]=useState(null);
  return(
    <div className="star-rating" >
      {
        [...Array(stars || 5 )].map((item,i) => {
          const ratingValue = i + 1;
          return(
            <label>
                <input type="radio"
                name="rating"
                value= {ratingValue}
                onClick= { () => setRating(ratingValue)}
              />
              <FaStar 
                className ="stars"
                color ={ratingValue <= (hover || rating ) ? activColor || "#ffc107" : diActivColor || "#e4e5e9" }
                size = {size || 20 }
                onMouseEnter = {() => setHover(ratingValue)}
                onMouseLeave = { () => setHover(null)}
              />
            </label>
          )
        })
      }
    </div>
  )
}

export default StarRating;
    