import React,{ useEffect,useState } from 'react';
import { useNavigate } from "react-router-dom";


let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";

const Monsary = ({interest}) => {
    const navigate = useNavigate();

    const [monsaryData, setMonsaryData] = useState()

    useEffect(() => {
        if(interest !== undefined){
            const size = 2; 
                const res = interest.reduce((acc, curr, i) => {
                if ( !(i % size)  ) {   
                    acc.push(interest.slice(i, i + size)); 
                }
                return acc;
                }, []);
                setMonsaryData(res)
            }
        },[interest])

    return (
        <>
            {
                monsaryData !== undefined && monsaryData.map((item,index)=>{
                        return <React.Fragment key={index}>
                        {
                            Array.isArray(item) && item.map((data, i) => {
                                return<div className="col-md-6 col-sm-6 interest-image" key={i}>
                                    {
                                        Array.isArray(data.mdaMedia) && data.mdaMedia.map((key,id) =>{
                                            if(index % 2 == 0){
                                                return(
                                                    <div key={id} 
                                                        onClick={() => navigate(`/interest/${data.slug}`, {state:[data, data.slug]})}
                                                    >
                                                        <figure className={ i == 0 ? "first" : "second"}>
                                                            <img className="monsary-img desktop" src={`${imageUrl}/${key.mediaFilePath}`} alt={key.bannerImageAlt} title={key.bannerImageTitle} />
                                                            {/* <img className="monsary-img tablet" src={`${imageUrl}/${key.mediaTabletFilePath}`} alt={key.bannerImageAlt} title={key.bannerImageTitle} />
                                                            <img className="monsary-img mobile" src={`${imageUrl}/${key.mediaMobileFilePath}`} alt={key.bannerImageAlt} title={key.bannerImageTitle} />  */}
                                                            <figcaption>
                                                                <div className="title-icon">
                                                                    <img src={`${imageUrl}/${data.iconPath}`} alt={data.interest} title={data.interest} />
                                                                </div>
                                                                <h2>{data.interest}</h2>
                                                            </figcaption>
                                                        </figure>
                                                    </div>
                                                )
                                            }else{
                                                return(
                                                    <div key={id}
                                                        onClick={() => navigate(`/interest/${data.slug}`, {state:[data, data.slug]})}
                                                    >
                                                        <figure className={ i == 0 ? "second" : "first"}>
                                                            <img className="monsary-img desktop" src={`${imageUrl}/${key.mediaFilePath}`} alt={key.bannerImageAlt} title={key.bannerImageTitle} />
                                                            {/* <img className="monsary-img tablet" src={`${imageUrl}/${key.mediaTabletFilePath}`} alt={key.bannerImageAlt} title={key.bannerImageTitle} />
                                                            <img className="monsary-img mobile" src={`${imageUrl}/${key.mediaMobileFilePath}`} alt={key.bannerImageAlt} title={key.bannerImageTitle} />  */}
                                                            <figcaption>
                                                                <div className="title-icon">
                                                                    <img src={`${imageUrl}/${data.iconPath}`} 
                                                                    alt={data.interest} title={data.interest} 
                                                                    />
                                                                </div>
                                                                <h2>{data.interest}</h2>
                                                            </figcaption>
                                                        </figure>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            })
                        }
                     </React.Fragment>
                })
            }
        </>
    );
}

export default Monsary;