import React,{useEffect, useState} from 'react';
import tick from '../../assets/icons/tick.png'
import { useLocation, Link } from "react-router-dom";
import Navbar from '../../shared/navbar';

const ThankYou = (props) => {
    
    const location = useLocation();

    const [datas] = useState(location.state)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <React.Fragment>
            <Navbar bgcolor={true} data={props.data} />
              <div className="wrapper thankyou-page">
                
                <section className="container">
                    <ul className="breadcrumb">
                        <li><Link to={'/'}>Home</Link></li>
                        <li className="active">Thank You</li>
                    </ul>
                    <div className="thankyou text-center">
                        <div className="icon"><img src={tick} alt="tick-icon" title="tick-icon" /></div>
                        <h2>Thank You</h2>
                        <p>Your {`${datas === "enquiry" ? " Activity enquiry " : " Tour enquiry " }`} has been sent successfully.</p>
                        <p>We will get in touch with you soon.</p>
                        <div className="mx-5">
                            <div className="row">
                                <div className="col-2"><button className="btn-outline"><Link to="/">Home</Link></button></div>
                                <div className="col-2"><button className="btn-outline"><Link to="/interest/">Explore Interest</Link></button></div>
                                <div className="col-2"><button className="btn-outline"><Link to="/destinations">Discover Destinations</Link></button></div>
                                <div className="col-2"><button className="btn-outline"><Link to="/comments">Experiential Comments</Link></button></div>
                                <div className="col-2"><button className="btn-outline"><Link to="/about-us">About LE</Link></button></div>
                                <div className="col-2"><button className="btn-outline"><Link to="contact-us">Contact With US</Link></button></div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </React.Fragment>
    );
}

export default ThankYou;