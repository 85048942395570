import { put } from 'redux-saga/effects';
import postApi from "../services/postApi";
import {
    FETCH_AUTH_RESPONSE,AUTH_DATA_FAILED,
    FETCH_ENQUERY_RESPONSE,ENQUERY_DATA_FAILED, 
    FETCH_SUBSCRIBE_RESPONSE, SUBSCRIBE_DATA_FAILED, 
    FETCH_CONTACTUS_RESPONSE, CONTACTUS_DATA_FAILED, FETCH_EXPE_ENQUERY_RESPONSE, EXPE_ENQUERY_DATA_FAILED
} from '../redux/types/authType';
let api = new postApi();

export function* authSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Auth(payload);
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data) {
            yield put({ type: FETCH_AUTH_RESPONSE, payload: data })
            // yield (sessionStorage.setItem("token", `${data.token}`) )
            yield (localStorage.setItem("token", `${data.token}`) )
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: AUTH_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('AUTH_DATA_FAILED', e);
        yield put({ type: AUTH_DATA_FAILED, payload: e })

    }
}


export function* enquerySaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Enquiry(payload);
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data) {
            yield put({ type: FETCH_ENQUERY_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: ENQUERY_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('ENQUERY_DATA_FAILED', e);
        yield put({ type: ENQUERY_DATA_FAILED, payload: e })

    }
}


export function* subscribeSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.Newsletter(payload);
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data) {
            yield put({ type: FETCH_SUBSCRIBE_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: SUBSCRIBE_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('SUBSCRIBE_DATA_FAILED', e);
        yield put({ type: SUBSCRIBE_DATA_FAILED, payload: e })

    }
}


export function* contactusSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.ContactFormSubmit(payload);
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data) {
            yield put({ type: FETCH_CONTACTUS_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: CONTACTUS_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('CONTACTUS_DATA_FAILED', e);
        yield put({ type: CONTACTUS_DATA_FAILED, payload: e })

    }
}


export function* ExperienceFormSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {
        let data = yield api.ExperienceFormSubmit(payload);
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data) {
            yield put({ type: FETCH_EXPE_ENQUERY_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: EXPE_ENQUERY_DATA_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('EXPE_ENQUERY_DATA_FAILED', e);
        yield put({ type: EXPE_ENQUERY_DATA_FAILED, payload: e })

    }
}