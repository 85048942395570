import { regEx } from './constant'

export function isValidMobileNo(mobileNo) {
    const panRegex =new RegExp(regEx.mobileNumber);
    return panRegex.test(mobileNo)
}

export function numberText(data) {
    let check =new RegExp(regEx.number);
    return check.test(data)
}

export function alphabatesText(data) {
    let check =new RegExp(regEx.alphabates);
    return check.test(data)
}

export function alphabatesWithSpace(data) {
    let check =new RegExp(regEx.alphabatesWithSpace);
    return check.test(data)
}


export function fetchWithWait({ dispatch, action }) {
    return new Promise((resolve, reject) => {
        dispatch({ ...action, resolve, reject })
    })
}