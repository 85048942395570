import React,{ useState,useEffect } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { activitiesAction} from '../../redux/actions/homeAction'
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaMapMarkerAlt }  from "react-icons/fa";
import HeroInterest from '../atom/HeroInterest';
import Exprience from '../atom/Exprience';
import PageLaoder from '../atom/loader/pageLaoder';
import { Helmet } from 'react-helmet';


let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";
let baseUrl = process.env.REACT_APP_BASE_URL;


const InterestRomance = () => {
    let dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { path } = useParams();
   


    const [cardData, setcardData] = useState()
    const [banner, setBanner] = useState()
    const [modalShow, setModalShow] = useState(false);
    const [details, setDetails] = useState()
    const [activiti, setActiviti] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [allDatas, setAllDatas] = useState([])
    const [modalImage, setModalImage] = useState("");
    const [selectInterest, setSelectInterest] = useState([]);
    const [mapData, setMapData] = useState();
    const [pageData, setPageData] = useState([]);
    const [selectOption, setSelectOption] = useState("All");
    const [loader, setLoading] = useState(false);
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)


    useEffect(() => {
        const token = localStorage.getItem("token")
        const fetchData = async() =>{
            const fetchApi = await fetch(`${baseUrl}getActivityByInterestSlugName/${path}`,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + `${token}`,
                }
            })
            const data = await fetchApi.json();
            if(data.message === "success"){
                let arr = [];
                let pagedata = data.data;
                pagedata.forEach(item => {
                    if(item.pageDisplay === true && item.isActive === true ){
                        arr.push(item)
                    }
                })
                setPageData(data.pageData);
                setDetails(arr)
                setAllDatas(arr)
                setLoading(true)
            }
        }
        fetchData();
    }, [])


    useEffect(() => {
        dispatch(activitiesAction())
	},[]);


    useEffect(() => {
        if(details !== undefined){
            let size = 5;
            let arr = [], select = [] ;
            const res = details.reduce((acc, curr, i) => {
                if ( !(i % size) ) {   
                    acc.push(details.slice(i, i + size)); 
                }
            return acc;
            }, []);
            setMapData(res)
            allDatas.forEach((item) => {
                if(!arr.includes(item.activity)){
                    arr.push(item.activity)
                    select.push({label: item.activity, value: item.activity})
                }
            })
            setSelectInterest(select)
        }
    },[details])


    useEffect(() => {
        if(pageData.length){
            setBanner(pageData[0])
            setTitle(pageData[0].interest)
            setDescription(pageData[0].description)
            
            if(pageData[0].hasOwnProperty("seoContent") && pageData[0].seoContent !== null ){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = pageData[0].seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
        }
    }, [pageData])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const handelCounterViews = (data) =>{
        const index = cardData.findIndex((item) => item.id === data.id);
        const updataData = [...cardData]
        updataData[index] = {...updataData[index], view: updataData[index].view ++}
        setModalShow(true)
        setModalImage(data.src)
        setcardData([...cardData])
    }
    
    const handleTextboxState = (e) => {
        setActiviti(e.target.value )
    }

    const handelCounterLikes = (id) =>{
        const index = cardData.findIndex((item) => item.id === id);
        const updataData = [...cardData]
        updataData[index] = {...updataData[index], like: updataData[index].like ++}
        setcardData([...cardData])
    }

    const handleInterestOption = (value) =>{
        let arr = []
        allDatas.length && allDatas.filter((item) => {
            if(value === item.activity){
                arr.push(item)
                return setDetails(arr)
            }else if(value === "All"){
                return setDetails(allDatas)
            }
        })
    }

    return <div className="interest-page">

        <Helmet>
            <title>{ metaTitle !== null ? metaTitle : null }</title>
            <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
            <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
            <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
            <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
            <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
            <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
            <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
            <meta property="og:type" content={ socialType !== null ? socialType : null } />
        </Helmet>

        { banner !== undefined?
            <HeroInterest 
            items={1}
            loop = {false}
            margin= {10}
            animateOut= {'fadeOut'}
            outoplay = {true}
            autoplayHoverPause= {true}
            speed = {5000}
            responsiveClass = {true}
            banner={banner}
        /> : <PageLaoder /> }
        
        <section className="explore-romance explore-int">
            <div className="container">
                <div className='row'>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="non-active"><Link to="/interest">Explore Interest</Link></li>
                        <li className='active'>{title.length ? title : "" }</li>
                    </ul>
                </div>
                <h2>{ title.length ? title : null }</h2>
                <div className="row info-spacer">
                    <div className="col-12">
                        <div className="inte-spacer">
                            {description !== null ? <div dangerouslySetInnerHTML={{__html: description}} /> : null }
                        </div>  
                    </div>
                    <div className="col-lg-5 col-md-7 col-sm-12">
                    <span>I'd like to visit</span>
                        <select className="form-control" onChange={(e) => handleInterestOption(e.target.value)}>
                            <option value="All">All</option>
                            {
                                selectInterest !== undefined && selectInterest.map((item, index) =>{
                                    return <option key={index} value={item.value}>{item.label}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="offset-8"></div>
                </div>
            </div>
        </section>
        <section className='photo-gallery'>
            <div className="container">
            {   mapData !== undefined && mapData.length  ?  
                <div className="">
                    {  
                        mapData !== undefined && mapData.map((data, i) => {
                            return<div className='row' key={i}>
                           {     data.map((item, index) => {
                                    if(index === 0 || index === 1){
                                        return <div className="col-md-6 col-sm-6" key={index}>
                                        {
                                            item.mdaMedia.map((key, j) =>{
                                            return<React.Fragment key={j}>
                                                {
                                                    key.mediaListing.map((li, k) =>{
                                                        if(li.id === 1){
                                                            return <li className="card first-row" key={k}>
                                                            <figure>
                                                                <img src={`${imageUrl}/${key.mediaFilePath}`} 
                                                                    alt={key.mediaAlText} 
                                                                    title={key.mediaTitle}
                                                                    onClick={() => navigate(`/interest/${path}/${item.slugName}`, {state:item})}
                                                                />
                                                            </figure>
                                                                <div className="banners">
                                                                    <p>{item.activity}</p>
                                                                    <div className="icon-sec"> 
                                                                        <div>
                                                                            {/* <span className="view-icon" onClick={() => handelCounterViews(2)}><FaEye /></span> <span>{120}</span>
                                                                            <span className="thumb-icon" onClick={() => handelCounterLikes(1)}><FaThumbsUp /></span> <span>{110}</span> */}
                                                                        </div>
                                                                        <div className='maps'>
                                                                            <span className="location"><FaMapMarkerAlt /></span><span>{item.mstCountry}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        }
                                                    })
                                                }
                                            </React.Fragment>
                                            })
                                        }
                                    </div>
                                    }else{
                                        return <div className="col-md-4 col-sm-6 int-landing"  key={index}>
                                            {
                                            item.mdaMedia.map((key,j) =>{
                                            return<React.Fragment key={j}>
                                                {
                                                    key.mediaListing.map((li, k) =>{
                                                        if(li.id === 1){
                                                            return <li className="card first-row" key={k}>
                                                            <figure>
                                                                <img src={`${imageUrl}/${key.mediaFilePath}`} 
                                                                    alt={key.mediaAlText} 
                                                                    title={key.mediaTitle}
                                                                    onClick={() => navigate(`/interest/${path}/${item.slugName}`, {state:item})}
                                                                />
                                                            </figure>
                                                                <div className="banners">
                                                                    <p>{item.activity}</p>
                                                                    <div className="icon-sec"> 
                                                                        <div>
                                                                            {/* <span className="view-icon" onClick={() => handelCounterViews(2)}><FaEye /></span> <span>{120}</span>
                                                                            <span className="thumb-icon" onClick={() => handelCounterLikes(1)}><FaThumbsUp /></span> <span>{110}</span> */}
                                                                        </div>
                                                                        <div className='maps'>
                                                                            <span className="location"><FaMapMarkerAlt /></span><span>{item.mstCountry}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        }
                                                    })
                                                }
                                            </React.Fragment>
                                                })
                                            }
                                        </div>
                                    }
                                })}
                            </div>
                        })  
                    }
                 </div>
                 : <div className='card'>
                        <h3 style={{padding:"1rem", fontSize:"20px"}}>No records found</h3>
                    </div>
                }
                <Modal
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    >
                    <img src={modalImage} className="modal-image" />
                </Modal>
                
            </div>
               
        </section>   
        <Exprience />        
        {!loader ? <PageLaoder /> : null}    
    </div>
    
}

export default InterestRomance;