import { takeEvery, all } from 'redux-saga/effects';
import { FETCH_POST_DATA } from '../redux/types/postType'
import { FETCH_HOME_DATA, FETCH_INTEREST_DATA, FETCH_DESTINATION_DATA, FETCH_BOOK_NEXT_DATA, 
        FETCH_ACTIVITIES_DATA, FETCH_TOURS_DATA, FETCH_ACCOMMODATIONS_DATA, FETCH_EXPLORE_BANNER_DATA, 
        FETCH_DESTINATION_BANNER_DATA, 
        FETCH_ORGANIZATION_DATA,
        FETCH_ABOUTUS_BANNER,
        FETCH_ENQUERY_BANNER,
        FETCH_CONTACTUS_BANNER,
        FETCH_COUNTRIES_DATA,
        FETCH_SALUTATION_DATA,
        FETCH_TENTATIVEBUDGET_DATA,
        FETCH_HOLIDAYDURATION_DATA,
        FETCH_VACCINATION_DATA,
        FETCH_ENQUIRSOURCE_DATA,
        FETCH_TESTIMONIALS_DATA,
        FETCH_TESTIMONIALS_BANNER,
        FETCH_FEATURED_TOURS,
        FETCH_ENQUIRY_TOURS,
        FETCH_ENQUIRY_ACTIVITY,
        FETCH_EXCEPTIONAL_EXPERIENCE,
        FETCH_EXPERIENCES_DATA} from '../redux/types/homeType'
import { FETCH_AUTH_DATA, FETCH_CONTACTUS_DATA, FETCH_ENQUERY_DATA, FETCH_EXPE_ENQUERY_DATA, FETCH_SUBSCRIBE_DATA } from '../redux/types/authType'
import { postSaga } from '../sagas/posts'
import { homeSaga,interestSaga, destinationSaga, bookYourNextSaga, activitiesSaga, 
        toursSaga, accommodationsSaga, exploreInterestSaga, destinationBannerSaga, 
        organizationSaga, aboutusSaga, contactusbannerSaga, enquirybannerSaga, countriesSaga, 
        salutationSaga, tentativeBudgetSaga, holidayDurationSaga, vaccinationSaga, enquirsourceSaga, 
        testimonialSaga, testimoBannarSaga, featuredToursSaga, tourenquirySaga, activityEnquirySaga, 
        exceptionalExpSaga, exceptionalSaga } from '../sagas/homePage'
import { authSaga, contactusSaga, enquerySaga, ExperienceFormSaga, subscribeSaga } from '../sagas/authSaga'


function* rootSaga() {
    yield all([
        takeEvery(FETCH_AUTH_DATA, authSaga ),
        takeEvery(FETCH_POST_DATA, postSaga ),
        takeEvery(FETCH_HOME_DATA, homeSaga ),
        takeEvery(FETCH_ORGANIZATION_DATA, organizationSaga ),
        takeEvery(FETCH_INTEREST_DATA, interestSaga ),
        takeEvery(FETCH_DESTINATION_DATA, destinationSaga ),
        takeEvery(FETCH_BOOK_NEXT_DATA, bookYourNextSaga ),
        takeEvery(FETCH_ACTIVITIES_DATA, activitiesSaga ),
        takeEvery(FETCH_TOURS_DATA, toursSaga ),
        takeEvery(FETCH_ACCOMMODATIONS_DATA, accommodationsSaga ),
        takeEvery(FETCH_EXPLORE_BANNER_DATA, exploreInterestSaga ),
        takeEvery(FETCH_DESTINATION_BANNER_DATA, destinationBannerSaga ),
        takeEvery(FETCH_ENQUERY_DATA, enquerySaga ),
        takeEvery(FETCH_SUBSCRIBE_DATA, subscribeSaga ),
        takeEvery(FETCH_ABOUTUS_BANNER, aboutusSaga ),
        takeEvery(FETCH_ENQUERY_BANNER, enquirybannerSaga ),
        takeEvery(FETCH_CONTACTUS_BANNER, contactusbannerSaga ),
        takeEvery(FETCH_CONTACTUS_DATA, contactusSaga ),
        takeEvery(FETCH_COUNTRIES_DATA, countriesSaga ),
        takeEvery(FETCH_SALUTATION_DATA, salutationSaga ),
        takeEvery(FETCH_TENTATIVEBUDGET_DATA, tentativeBudgetSaga ),
        takeEvery(FETCH_HOLIDAYDURATION_DATA, holidayDurationSaga ),
        takeEvery(FETCH_VACCINATION_DATA, vaccinationSaga ),
        takeEvery(FETCH_ENQUIRSOURCE_DATA, enquirsourceSaga ),
        takeEvery(FETCH_TESTIMONIALS_DATA, testimonialSaga ),
        takeEvery(FETCH_TESTIMONIALS_BANNER, testimoBannarSaga ),
        takeEvery(FETCH_FEATURED_TOURS, featuredToursSaga ),
        takeEvery(FETCH_ENQUIRY_TOURS, tourenquirySaga ),
        takeEvery(FETCH_ENQUIRY_ACTIVITY, activityEnquirySaga ),
        takeEvery(FETCH_EXCEPTIONAL_EXPERIENCE, exceptionalExpSaga ),
        takeEvery(FETCH_EXPERIENCES_DATA, exceptionalSaga ),
        takeEvery(FETCH_EXPE_ENQUERY_DATA, ExperienceFormSaga ),
    ]);
}

export default rootSaga;