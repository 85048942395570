import React,{ useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

import MaskGroup36 from '../../assets/images/MaskGroup36.jpg'
import MaskGroup37 from '../../assets/images/MaskGroup37.jpg'
import MaskGroup38 from '../../assets/images/MaskGroup38.jpg'
import leftArrow from '../../assets/icons/left-arrow.png'
import rightArrow from '../../assets/icons/right-arrow.png'
import { Link, useParams } from 'react-router-dom';
import { FaEye,FaThumbsUp,FaMapMarkerAlt }  from "react-icons/fa";
import { FaEnvelope,FaLinkedinIn,FaInstagram,FaFacebookF,FaYoutube } from "react-icons/fa";
import { FacebookShareButton, LinkedinShareButton, EmailShareButton } from "react-share";
import { RWebShare } from "react-web-share";
import { useLocation, useNavigate, useHistory } from 'react-router-dom';
import HeroActivitis from '../atom/HeroActivitis';
import PageLaoder from '../atom/loader/pageLaoder';
import { Helmet } from 'react-helmet';



const Images1 = [
    { id: 1 , src: MaskGroup36, location: "Maldives", content: 'Say "I Do" once again', view: 201, like: 210},
    { id: 2 , src: MaskGroup37, location: "Maldives", content: 'Castaway Valentine Bliss', view: 154, like: 200},
    { id: 3 , src: MaskGroup38, location: "Maldives", content: 'Celibrate your valentines day in maldives', view: 201, like: 210},
]

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";
let baseUrl = process.env.REACT_APP_BASE_URL;

const IntrestRomanceTourDetails = () => { 
    const location = useLocation();
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const { prvpath, path } = useParams();

    const [cardData, setcardData] = useState(Images1) 
    const [id, setId] = useState(0) 
    const [title, setTitle] = useState("") 
    const [prvPath, setPrvpath] = useState("") 
    const [similarView, setSimilarView] = useState() 
    const [similarCard, setSimilarCard] = useState([]) 
    const [modalShow, setModalShow] = useState(false);
    const [itslider, setItslider] = useState(false);
    const [details, setDetails] = useState();
    const [prdActiviti, setPrdActiviti] = useState("");
    const [banner, setBanner] = useState([]);
    const [pageData, setPageData] = useState();
    const [slider, setSlider] = useState();
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)

    useEffect(() => {
        let arr = []
        let count = 0
        banner.map(item => {
            const {mediaListing} = item
            mediaListing.forEach(data => {
                const {id} = data
                if(id === 8 ){
                    arr.push(item)
                    count ++
                }
            })
        });
        setSlider(arr)
        count > 0 ? setItslider(true) : setItslider(false)
    }, [banner])

    useEffect(() => {
        const token = localStorage.getItem("token")
        const fetchData = async() =>{
            const fetchApi = await fetch(`${baseUrl}activities/${path}`,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + `${token}`,
                }
            })
            const data = await fetchApi.json();
            setDetails(data.data)
        }
        fetchData();
    }, [])

    useEffect(() => {
        if( details !== undefined ){
            const { mdaMedia, prdActivityInterest, id, activity } =  details[0]
            setBanner(mdaMedia)
            setPageData(details[0])
            setPrdActiviti(prdActivityInterest[0].slug)
            setId(id)
            setTitle(activity)
            setPrvpath(prvpath.charAt(0).toUpperCase() + prvpath.slice(1))

            if( details[0].hasOwnProperty("seoContent") && details[0].seoContent !== null ){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = details[0].seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
        }
    }, [details])

    useEffect(() => {
        const token = localStorage.getItem("token")
        const fetchData = async() =>{
            const fetchApi = await fetch(`${baseUrl}getActivityByInterestSlugName/${prdActiviti}`,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + `${token}`,
                }
            })
            const data = await fetchApi.json();
            setSimilarView(data.data)
        }
        if(prdActiviti.length){
            fetchData();
        }
    }, [prdActiviti])

    useEffect(() => {
        let arr = []
        let count = 0
       if(similarView !== undefined ){
            similarView.forEach((item) => {
                if(count < 3 && item.id !== id && item.isActive ){
                    arr.push(item);
                    count ++
                }
            })
            setSimilarCard(arr)
            count = 0
       }
    }, [similarView, id])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handelCounterViews = (id) =>{
        const index = cardData.findIndex((item) => item.id === id);
        const updataData = [...cardData]
        updataData[index] = {...updataData[index], view: updataData[index].view ++}
        setcardData([...cardData])
    }
    
    const handelCounterLikes = (id) =>{
        const index = cardData.findIndex((item) => item.id === id);
        const updataData = [...cardData]
        updataData[index] = {...updataData[index], like: updataData[index].like ++}
        setcardData([...cardData])
    }

    const handleSimilarView = (value) => {
        navigate(`/interest/${prvpath}/${value}`)
        window.location.reload()
    }


    return <div className="romance-interest">
        <Helmet>
            <title>{ metaTitle !== null ? metaTitle : null }</title>
            <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
            <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
            <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
            <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
            <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
            <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
            <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
            <meta property="og:type" content={ socialType !== null ? socialType : null } />
        </Helmet>

        { banner.length ? 
            <HeroActivitis 
                items={1}
                loop = {false}
                margin= {10}
                animateOut= {'fadeOut'}
                outoplay = {true}
                autoplayHoverPause= {true}
                speed = {5000}
                responsiveClass = {true}
                banner={banner}
            />: <PageLaoder />
        }
        
        <section className="interest-details">
            <div className="container">
                <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                        <ul className='breadcrumb'>
                            <li><Link to="/">Home</Link></li>
                            <li className="non-active"><Link to="/interest">Explore Interest</Link></li>
                            <li className="non-active"><Link to={`/interest/${prvpath}`}>{prvPath}</Link></li>
                            <li className='active'>{title !== null ? title : null}</li>
                        </ul>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <div className="icon-list">
                            <ul className='details-icon'>
                                {/* <li><span className="view-icon" onClick={() => handelCounterViews()}><FaEye /></span> <span>500</span></li>
                                <li><span className="thumb-icon" onClick={() => handelCounterLikes()}><FaThumbsUp /></span> <span>352</span></li> */}
                                <li>
                                    <RWebShare
                                        data={{
                                        text: title !== undefined && title.length ? title : null,
                                        url: `https://dev-le.sublimis.dev/details-of/${path}`,
                                        title: title !== undefined && title.length ? title : null
                                        }}
                                    >
                                        <span className="icons">
                                            Share On
                                        </span>
                                    </RWebShare>
                                </li>
                                <li>
                                    <EmailShareButton
                                        url={`https://dev-le.sublimis.dev/tours/details//${path}`}
                                        className=""
                                    >
                                        <FaEnvelope  />
                                    </EmailShareButton>
                                </li>
                                <li>
                                    <FacebookShareButton
                                        url={`https://dev-le.sublimis.dev/tours/details//${path}`}
                                        className=""
                                    >
                                        <FaFacebookF />
                                    </FacebookShareButton>
                                </li>
                                {/* <li>
                                    <InstagramShareButton
                                        url={`https://dev-le.sublimis.dev/tours/details//${path}`}
                                        className=""
                                    >
                                        <FaInstagram />
                                    </InstagramShareButton>
                                </li> */}
                                <li>
                                    <LinkedinShareButton
                                        url={`https://dev-le.sublimis.dev/tours/details//${path}`}
                                        className=""
                                    >
                                        <FaLinkedinIn />
                                    </LinkedinShareButton>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                { pageData !== undefined ?
                    <div className="text-center interest1">
                    { title !== null ? <h2>{title}</h2> : null}
                    { pageData !== undefined ? <div dangerouslySetInnerHTML={{__html: pageData.activityDescription}} /> : null}
                    <ul className="interest-list">
                        <li className="map-logo"><span className="icon"><FaMapMarkerAlt /></span><span>{`${pageData.mstCity}, ${pageData.mstCountry}`}</span></li>
                        <li className="romance-logo">
                            {pageData !== undefined && pageData.prdActivityInterest &&  pageData.prdActivityInterest.map((item,i) => {
                                return<div key={i}> <span className="icon"><img src={`${imageUrl}/${item.iconPath}`} alt={item.interest} /></span><span className='content'>{`${item.interest}`}</span> </div> 
                            })}
                        </li>
                    </ul>
                    <div className="enquiry-buttn">
                        <button className="btn-primary"
                         onClick={() => navigate(`/activity-enquiry`, {state:[ pageData ]})}
                        >Enquire Now
                        </button>
                    </div>
                </div> : null }
                { pageData !== undefined ? <div className="row interst-location highlight-sec ">
                    {banner !== undefined ? <div className="col-lg-6 col-md-12 col-sm-12">
                        {
                            banner.map((data,i) => {
                                return<React.Fragment key={i}>
                                {   data.mediaListing.length && data.mediaListing.map((item,index) =>{
                                        if(item.id === 1 ){
                                            return<figure key={index}>
                                                <img src={`${imageUrl}/${data.mediaFilePath}`} alt={data.mediaAlText} title={data.mediaTitle} />
                                            </figure>
                                        }
                                    })
                                }
                                </React.Fragment>
                            })
                        }
                    </div> : null}
                    {pageData.Highlights ? <div className="col-lg-6 col-md-12 col-sm-12 highlight">
                        <h3>Highlights</h3>
                        <div className="den-highlights" dangerouslySetInnerHTML={{__html: pageData.Highlights}} />
                    </div> : null}
                </div> : null }
                { pageData !== undefined && pageData.briefDescription ? <div className="row content-sec para">
                    <div className="col-12">
                        <div dangerouslySetInnerHTML={{__html: pageData.briefDescription}} />
                    </div>
                </div> : null}
            </div>
        </section>
        { itslider ? <section className='int-details'>
            <div className="row">
                <div className="tour-slider">
                    <OwlCarousel 
                        items="1.4" 
                        loop={true}
                        center= {true}
                        dots={false}
                        autoplay={false}
                        margin={10}
                        nav={true}
                        navText= {[`<img src=${leftArrow} />`,`<img src=${rightArrow} />`]}
                    > 
                    {

                        slider.length && slider.map((data,index) => {
                            return(<li className="item romance-int-detail" key={index} >
                                <figure>
                                    <img src={`${imageUrl}/${data.mediaFilePath}`} alt={data.mediaAlText} title={data.mediaTitle} />
                                    <figcaption>
                                        <p>{data.mediaName}</p>
                                    </figcaption>
                                </figure>
                            </li>
                            )
                        })
                    }
                    </OwlCarousel>
                </div>
            </div>
        </section> : null } 
        {  similarCard.length ? <section className="similar-view">
            <div className="container">
                <div className="row">
                    <div className="col-12"><h3>View Similar Experiences</h3></div>
                </div>
                <div className='row'>
                    {
                        similarCard.length &&  similarCard.map((item, index) => {
                            if(item.isActive === true ){
                                return<React.Fragment key={index}>
                                {
                                    item.mdaMedia.map((data,i) =>{
                                        const { isActive, mediaListing } = data
                                        return<React.Fragment key={i}>
                                            {
                                                mediaListing.map((li, k) => {
                                                    if(isActive === true && li.id === 1){
                                                        return<div className="col-md-4 col-sm-12"  key={k}>
                                                        <div className="card romance-card" >
                                                            <figure>
                                                                <img className="" 
                                                                onClick={() => handleSimilarView(item.slugName)}
                                                                src={`${imageUrl}/${data.mediaFilePath}`}  />
                                                            </figure>
                                                            <div className="card-body">
                                                                <span className="map-icon"><FaMapMarkerAlt /></span><span>{item.mstCountry}</span>
                                                                <p>{item.activity}</p>
                                                                {/* <div className="logo-sec"> 
                                                                    <span className="view-icon" onClick={() => handelCounterViews(1)}><FaEye /></span> <span>{210}</span>
                                                                    <span className="thumb-icon" onClick={() => handelCounterLikes(1)}><FaThumbsUp /></span> <span>{150}</span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                })
                                            }
                                        </React.Fragment>
                                    })
                                }
                            </React.Fragment>
                            }
                        })                            
                    }
                </div>
            </div>
        </section> :"" }
    </div>;
}

export default IntrestRomanceTourDetails;
