import { put } from 'redux-saga/effects';
import postApi from "../services/postApi";
import {
    FETCH_POST_RESPONSE,POST_FAILED
} from '../redux/types/postType';
let api = new postApi();

export function* postSaga({ payload, resolve }) {
    // let responsePayloadLoader = { start: true, stop: false };
    try {

        let data = yield api.Posts(payload);
        console.log("responseData Post", data);
        // responsePayloadLoader = { start: false, stop: true }; 
        if (data) {
            yield put({ type: FETCH_POST_RESPONSE, payload: data })
            resolve && resolve(data)
        }
        else {
            resolve && resolve(data)
            // yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })
            yield put({ type: POST_FAILED, payload: data })
            // responsePayload = { open: true, severity: "error", message: message }
        }
    } catch (e) {
        console.log('POST_FAILED', e);
        yield put({ type: POST_FAILED, payload: e })

    }
}