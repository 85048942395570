import React,{useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../redux/actions/AuthAction';
import localStorage from 'redux-persist/es/storage';
import PageLaoder from '../atom/loader/pageLaoder';
import { fetchWithWait } from '../../helper/method';

let userName = process.env.REACT_APP_USERNAME
let password = process.env.REACT_APP_PASSWORD

// import { history } from '_helpers';

export { PrivateRoute };

function PrivateRoute({ children }) {
    let dispatch = useDispatch();

    const auth = useSelector((state) => state.home.auth);

    useEffect(() => {
        const token = localStorage.getItem("token")
       if( !auth && !token.length ){
        let data = {
            username: userName,
            password: password
        }
        fetchWithWait({ dispatch, action: authAction(data) }).then((res) => {
            if(res.message == "success"){
                localStorage.setItem("token", `${res.data.token}`)
                let currentTime =  new Date();
            }
            }).catch((e) => {
                console.log(`error`, e)
            })
            // window.location.reload()
        }
    }, [])

    useEffect(() => {
        const token = localStorage.getItem("token")
       if( auth.length && token.length ){
        let data = {
            username: userName,
            password: password
        }
        dispatch(authAction(data))
       }
    }, [auth])
    
    if (!auth) {
        // not logged in so redirect to login page with the return url
        return <PageLaoder />
    }

    // authorized so return child components
    return(<React.Fragment>
            {children}
        </React.Fragment>
    );
}