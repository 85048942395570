import React,{useEffect,useState} from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import hero_comment from "../../assets/images/Comments.jpg"
import { FaQuoteLeft,FaQuoteRight }  from "react-icons/fa";
// import HeroInterest from '../atom/HeroInterest';
import { authAction } from '../../redux/actions/AuthAction';
import { fetchTestiBanner, fetchTestimonials } from '../../redux/actions/homeAction';
import ExploreInteres from '../atom/ExploreInteres';
import PageLaoder from '../atom/loader/pageLaoder';


let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";

const banner = [
    { id: 1 , image: hero_comment,  alt:"Comments", title:"Comments"}
]

const comments = [
    { id: 1, image: hero_comment,  alt:"Luxury Escapades", title:"Luxury Escapades"},
    { id: 2, image: hero_comment,  alt:"Luxury Escapades", title:"Luxury Escapades"},
    { id: 3, image: hero_comment,  alt:"Luxury Escapades", title:"Luxury Escapades"},
    { id: 4, image: hero_comment,  alt:"Luxury Escapades", title:"Luxury Escapades"},
    { id: 5, image: hero_comment,  alt:"Luxury Escapades", title:"Luxury Escapades"},
    { id: 6, image: hero_comment,  alt:"Luxury Escapades", title:"Luxury Escapades"},
    { id: 7, image: hero_comment,  alt:"Luxury Escapades", title:"Luxury Escapades"},
    { id: 8, image: hero_comment,  alt:"Luxury Escapades", title:"Luxury Escapades"}
]


const Comments = () => {
    const navigate = useNavigate();
    let dispatch = useDispatch();


    const auth = useSelector((state) => state.home.auth);
    const testiData = useSelector((state) => state.home.testimonials);
    const datas = useSelector((state) => state.home.testibanner);

    const [testimonial, setTestimonial] = useState([])
    const [banner, setBanner] = useState([])
    const [pageContent, setPageContent] = useState()
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        dispatch(fetchTestimonials())
        dispatch(fetchTestiBanner())
	},[auth]);

    useEffect(() => {
        if(datas !== undefined){
            const {banner, pageContent } = datas
            setBanner(banner)
            setPageContent(pageContent)
        }
    },[datas]);

    useEffect(() => {
        if(testiData !== undefined){
            setTestimonial(testiData)
        }
    },[testiData]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (  
        <div className="comments-page">
            {datas.pageTitle ? <ExploreInteres 
                items={1}
                loop = {false}
                margin= {10}
                animateOut= {'fadeOut'}
                outoplay = {true}
                autoplayHoverPause= {true}
                speed = {5000}
                responsiveClass = {true}
                banner={banner}
            /> : <PageLaoder />}
            <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li className="active">Experiential Comments</li>
            </ul>
            <div className="comment-section">
                <div className="container">
                    <div className="comment-content">
                    { datas.pageTitle && pageContent !== undefined ? <>
                        <h1>{datas.pageTitle}</h1>
                        { <div dangerouslySetInnerHTML={{__html: pageContent[0].content}} /> }
                        </> : ""
                    }
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {
                            Array.isArray(testimonial) && testimonial.map((item, index)=>{
                                return <div className="col-lg-6 col-md-12 col-sm-12" key={index}>
                                    <div className="card">
                                        <div className="item">
                                            <figure>
                                                <figcaption>
                                                    <div className="testimonials_text_before"><FaQuoteLeft /></div>
                                                    <p>{item.testimonialDetail}</p>
                                                    <div className="testimonials_text_after" ><FaQuoteRight /></div>
                                                    <div className="title"><h3><span>{item.name}</span></h3></div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Comments;