import React,{ useEffect,useState } from 'react';
import { Link,useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { exceptionalExperience, experienceAction } from '../../redux/actions/homeAction';
import PageLaoder from '../atom/loader/pageLaoder';
import { Helmet } from 'react-helmet';


let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";

const ExceptionalExperience = () => {
    let dispatch = useDispatch();
    const navigate = useNavigate();


    const experienceData = useSelector((state) => state.home.exceptionalExp);
    const pageData = useSelector((state) => state.home.exceptionals);

    const [data, setData] = useState([])
    const [select, setSelect] = useState([])
    const [banner, setBanner] = useState([])
    const [pageTitel, setPageTitel] = useState("")
    const [pageContent, setPageContent] = useState(null)
    const [expOption, setExpOption] = useState([])
    const [destOption, setDestOption] = useState([])
    const [destSta, setDestSta] = useState(false)
    const [expSta, setExpSta] = useState(false)
    const [count, setCount] = useState(1)
    const [loadMore, setLoadMore] = useState(false)
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)


    useEffect(() => {
        dispatch(exceptionalExperience())
        dispatch(experienceAction())
    }, [])


    useEffect(() => {
        if(experienceData.hasOwnProperty("banner")){
            setBanner(experienceData.banner)
            setPageContent(experienceData.pageContent[0])
            setPageTitel(experienceData.pageTitle)
            if(experienceData.hasOwnProperty("seoContent")  && experienceData.seoContent !== null ){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = experienceData.seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
        }
    }, [experienceData])

    useEffect(() => {
        let arr = [];
        if( pageData.length ){
            pageData.forEach(item => {
                let count = 0;
                const { mdaMedia } = item
                mdaMedia.forEach(data => {
                    const { isActive } = data
                    if(isActive){
                        count ++
                    }
                })
                if(count > 0){
                    arr.push(item)
                }
                count = 0;
            });
            setData([ ...arr.slice(0, count * 9)])
            setSelect([ ...arr.slice(0, count * 9)])
        }
    }, [pageData])

    useEffect(() => {
        let dest = [] , exp = [], check = [], dCheck = [] ;
        if( select.length ){
            select.forEach(item => {
                const { name, mstCountry, isDisplay } = item
                if(isDisplay && !check.includes(name)){
                    check.push(name)
                    exp.push({label: name, value: name})
                }
                if(isDisplay && !dCheck.includes(mstCountry)){
                    dCheck.push(mstCountry)
                    dest.push({label: mstCountry, value: mstCountry})
                }
            });
            setDestOption(dest)
            setExpOption(exp)
        }
    }, [select])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const handleExpChange = (value) =>{
        let arr = []
        setDestSta(false);
        setExpSta(true);
        select.length && select.filter((item) => {
            const { name } = item
            if(value === name){
                arr.push(item)
                setData([...arr])
            }else if(value === "All"){
                return setData(select)
            }
        })
    }

    const handleDestChange = (value) =>{
        let arr = []
        setDestSta(true);
        setExpSta(false);
        select.length && select.filter((item) => {
            const { mstCountry } = item
            if(value === mstCountry){
                arr.push(item)
                setData([...arr])
            }else if(value === "All"){
                return setData(select)
            }
        })
    }

    const handleLoadMore = () => {
        if(!loadMore){
            setLoadMore(true)
            let arr = [];
            pageData.length && pageData.forEach(item => {
                let count = 0;
                const { mdaMedia } = item
                mdaMedia.forEach(data => {
                    const { isActive } = data
                    if(isActive){
                        count ++
                    }
                })
                if(count > 0){
                    arr.push(item)
                }
                count = 0;
            });
            setData(arr)
            setSelect(arr)
        }else{
            setLoadMore(false)
            setData([ ...select.slice(0, count * 9)])
            setSelect([ ...select.slice(0, count * 9)])
        }
    }

    return (
        <div className="exceptional-page">
            <Helmet>
                <title>{ metaTitle !== null ? metaTitle : "Experience" }</title>
                <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
                <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
                <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
                <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
                <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
                <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:type" content={ socialType !== null ? socialType : null } />
            </Helmet>

            { banner.length ? 
                <div className="hero_banner">
                    <div className="explore-slider">
                        {
                            banner.length && banner.map((item,index) => {
                                const { bannerDesktopImagePath,bannerImageAlt,bannerImageTitle, bannerMediaType, mediaPath } = item
                                return<React.Fragment key={index} >
                                    <div className='item' key={index}>
                                        <figure className={`${bannerMediaType === "video" ? "fbclass" : ""}`}>
                                            { bannerMediaType === "image" ? 
                                                <img className="desktop" src={`${imageUrl}/${bannerDesktopImagePath}`} alt={bannerImageAlt} title={bannerImageTitle} /> : 
                                                <iframe src={`${mediaPath}`} scrolling="no" frameBorder="0" 
                                                allow="autoplay" autoPlay={true} allowFullScreen={true}
                                                data-allowfullscreen="true"
                                                data-autoplay="true"
                                                data-show-captions="true"
                                                ></iframe>
                                            }
                                            <figcaption>
                                                <h1>{item.bannerName}</h1>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </React.Fragment>
                            })
                        }
                    </div>
                </div> : <PageLaoder />
            
            }
            
            <section className="experience">
                <div className="container">
                    
                    <div className="row">
                        <ul className="breadcrumb">
                            <li><Link to="/">Home</Link></li> 
                            <li className="active">Exceptional Experience</li>
                        </ul>
                    </div>
 
                    <div className='row'>
                        {pageTitel.length ? <h2>{pageTitel}</h2> : null }
                        <div className="info-spacer">
                            {/* { pageContent !== null ? <div dangerouslySetInnerHTML={{__html: pageContent.content}} /> : null } */}
                        <div className='row'>
                                <div className="col-md-6 col-sm-12 dropdown">
                                    <span>Experiences:</span>
                                    <select className="form-control" onChange={(e) => handleExpChange(e.target.value)}>
                                        <option value={"All"}>{"All"}</option>
                                        {
                                            expOption.length && expOption.map((item, i) => {
                                                return <option key = {i} value = {item.value} >{item.label}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6 col-sm-12 dropdown">
                                    <span>Destinations:</span>
                                    <select className="form-control" onChange={(e) => handleDestChange(e.target.value)}>
                                        <option value={"All"}>{"All"}</option>
                                        {
                                            destOption.length && destOption.map((item, i) => {
                                                return <option key = {i} value = {item.value} >{item.label}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {
                            data.map((item,index) => {
                                const { mdaMedia,isDisplay } = item
                                return<React.Fragment key={index}>
                                    {
                                       isDisplay ? mdaMedia.map((data, i) => {
                                        const { mediaListing, isActive, mediaType,mediaPath } = data
                                        return<React.Fragment key={i}>
                                            {
                                                 mediaListing.map((li,j) => {
                                                    if( isActive && li.id === 14 ){
                                                        return <div key={j} className='col-lg-4  col-md-6 col-sm-12 exceptional'>
                                                        <div className='card' onClick={() => navigate(`/experiences/${item.slug}`)}>
                                                            <figure>
                                                            { mediaType === "image" ? 
                                                                <img src={`${imageUrl}/${data.mediaFilePath}`} alt={data.mediaAlText} title= {data.mediaTitle} />  : 
                                                                <iframe src={`${mediaPath}`} scrolling="no" frameBorder="0" 
                                                                    allow="autoplay" autoPlay={true} allowFullScreen={true}
                                                                    data-allowfullscreen="true" data-autoplay="true"
                                                                    data-show-captions="true"
                                                                ></iframe>
                                                            }
                                                            </figure>
                                                            <figcaption>
                                                                <p>{`${item.mstCountry} - ${item.mstLocation}`}</p>
                                                                <h3>{item.name}</h3>
                                                            </figcaption>
                                                        </div>
                                                        </div>
                                                    }
                                                })
                                            }
                                        </React.Fragment>
                                       })  : null
                                    }
                                </React.Fragment>
                            })
                        }
                    </div>
                    <div className='row'>
                        { data.length >= 9 ?
                        <div className='load-more'>
                            <button className='btn-primary' onClick={handleLoadMore}>{!loadMore ? "View More" : "View less"}</button>
                        </div> : null
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}


export default ExceptionalExperience;