import { combineReducers } from 'redux';
import counter from './countReducer';
import posts from './postReducer'
import homepage from './homeReducer'
// let historyR = !process.env.IS_SERVER ? { router: connectRouter(history) } : {};


export default combineReducers({
   count: counter,
   posts,
   home: homepage,
})
