import React,{useEffect} from 'react';
import OwlCarousel from 'react-owl-carousel';  

import Seat_logo from "../../assets/icons/BusinessSeat.png"
import Clublogo from "../../assets/icons/Club.png"
import Pavilionlogo from "../../assets/icons/Pavilion.png"
import PrivateSuitelogo from "../../assets/icons/PrivateSuite.png"
import Pearl from "../../assets/icons/Pearl.png"
import Sport_banner from '../../assets/images/sports_banner.jpg'
import BusinessSeat from '../../assets/images/BusinessSeat.jpg'
import Club from '../../assets/images/Club.jpg'
import Fifa_boat from '../../assets/images/fifa_boat.jpg'
import India_1 from '../../assets/images/India_1.jpg'
import England from '../../assets/images/England.jpg'
import Pavilion from '../../assets/images/Pavilion.jpg'
import PearlLounge from '../../assets/images/PearlLounge.jpg'
import PrivateSuite from '../../assets/images/PrivateSuite.jpg'
import Icon_SportBrawon from '../../assets/icons/Icon_SportBrawon.png'
import Sydney from '../../assets/images/Sydney.jpg'
import { Link } from 'react-router-dom';
import { FaEye,FaThumbsUp,FaMapMarkerAlt }  from "react-icons/fa";
import { FaEnvelope,FaLinkedinIn,FaInstagram,FaFacebookF,FaYoutube } from "react-icons/fa";
import { FiMapPin }  from "react-icons/fi";
import StarRating from '../atom/StarRating';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  


const Products =[
    { id: 1 , src: PearlLounge, logo: Pearl, title: "Pearl", title1: "Lounge"},
    { id: 2 , src: PrivateSuite, logo: PrivateSuitelogo, title: "MATCH", title1: "Private Suite"},
    { id: 3 , src: BusinessSeat, logo: Seat_logo, title: "MATCH", title1: "Business Seat"},
    { id: 4 , src: Pavilion, logo: Pavilionlogo, title: "MATCH", title1: "Pavilion"},
    { id: 5 , src: Club, logo: Clublogo, title: "MATCH", title1: "Club"},
]

const Experiences = [
    { id: 1 , src: England, location: "England", content: 'Lorem Ipsum', view: 201, like: 210},
    { id: 2 , src: India_1, location: "India", content: 'Lorem Ipsum', view: 154, like: 200},
    { id: 3 , src: Sydney, location: "Sydney", content: 'Lorem Ipsum', view: 201, like: 210},
]


const banner = [
    { id: 1 , image: Sport_banner,  alt:"Sport_banner", title:"Sport banner"}
]

const InterestSportsifaDetails = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const handelCounterViews = (id) =>{
        console.log("handelCounterViews")
    }
    
    const handelCounterLikes = (id) =>{
        console.log("handelCounterLikes")
    }


    return <div className="romance-interest">
        <OwlCarousel 
            items={1}
            loop = {false}
            margin= {10}
            animateOut= {true}
            autoplay = {true}
        > 
        {
            
            banner !== undefined && banner.map((item, index) => {
                return (<React.Fragment key={index}>
                    {
                        <figure>
                            <img className="desktop" src={`${item.image}`} alt={item.alt} title={item.title} />
                            <figcaption>
                            </figcaption>
                        </figure>
                    }
                </React.Fragment>
                )
            })
        }
        </OwlCarousel>
        {/* <HeroBanner 
            items={1}
            loop = {true}
            margin= {10}
            animateOut= {'fadeOut'}
            outoplay = {true}
            autoplayHoverPause= {true}
            speed = {5000}
            responsiveClass = {true}
            banner={banner}
        /> */}
        <ul>
            <li><Link to="/">Home</Link></li>
            <li className="non-active"><Link to="/explore-interest">Explore Interest</Link></li>
            <li className="non-active"><Link to="/sports">Sports</Link></li>
            <li><Link to="/active">FIFA World Cup Qatar 2022TM</Link></li>
        </ul>
        <div className="icon-list">
            <ul>
                <li><span className="icons" onClick={() => handelCounterViews()}><FaEye /></span> <span>500</span></li>
                <li><span className="icons" onClick={() => handelCounterLikes()}><FaThumbsUp /></span> <span>352</span></li>
                <li><span className="icons" onClick={() => handelCounterLikes()}>Share On</span></li>
                <li>
                    <Link to="#" target="_blank" className="fa fa-envelope" title="Mail"><FaEnvelope /></Link>
                </li>
                <li>
                    <Link to="#" target="_blank" className="fa fa-facebook" title="Facebook"><FaFacebookF /></Link>
                </li>
                <li>
                    <Link to="#" target="_blank" className="fa fa-instagram" title="Instagram"><FaInstagram /></Link>
                </li>
                <li>
                    <Link to="#" target="_blank" className="fa fa-linkedin" title="Linkedin"><FaLinkedinIn /></Link>
                </li>
                <li>
                    <Link to="#" target="_blank" className="fa fa-youtube-play" title="Youtube"><FaYoutube /></Link>
                </li>
            </ul>
        </div>
        <section className="interest-details">
            <div className="container">
                <div className="row">
                    <div className="col-12"><h2>Official Hospitality Programme FIFA World Cup Qatar 2022TM</h2></div>
                </div>
                <div className="row interst-location">
                    <div className="offset-3"></div>
                    <div className="col-md-6 col-sm-12">
                        <div className="location"><FiMapPin /><span>Qatar</span></div>
                        <div className="location"><img src={Icon_SportBrawon} /><span>Sports</span></div>
                    </div>
                    <div className="offset-3"></div>
                </div>
                <div className="row interst-link">
                    <div className="col-md-3 col-sm-6 sport-inquire">
                        <button className="btn-primary"><Link to="/activity-enquiry">Inquire Now</Link></button>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <button className="btn-primary"><Link to="/">Brochure</Link></button>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <button className="btn-primary"><Link to="/">Price Sheet</Link></button>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <button className="btn-primary"><Link to="/">Fact Sheet</Link></button>
                    </div>
                </div>
                <div className="row interst-location highlight-sec ">
                    <div className="col-md-6 col-sm-12">
                        <img src={Fifa_boat} alt= "" title= "" />
                    </div>
                    <div className="col-md-6 col-sm-12 paragraph">
                        <h3>Write yourself into this epic tale at a FIFA World CupTM of firsts.</h3>
                        <p>
                            This tournament, the world’s greatest quadrennial football
                            competition will be hosted in a country globally renowned for
                            its outstanding hospitality. Whether you’re a fan looking to
                            simply secure guaranteed tickets to the action, or someone
                            seeking the perfect blend of luxury, preferential access,
                            and unbeatable pitch views, we have a hospitality package
                            to fit your requirements and aspirations! Experience the
                            FIFA World Cup™ in a world-class destination.
                        </p>
                        <strong>Your story starts here.</strong>
                    </div>
                </div>
                
            </div>
        </section>
        <section className='products-details'>
            <div className="container">
                <div className="row content-sec">
                    <div className="col-md-12 col-sm-12">
                        <h3>Products</h3>
                        <p>Choose your desired hospitality level, ranging from the pinnacle of luxury in the Pearl Lounge to the true fan experience in MATCH Club.</p>
                    </div>
                </div>
                <div className="row">
                    {
                        Products.length && Products.map((item,index) => {
                            return <div className="col-lg-4 col-md-6 col-ms-12" key={item.id}>
                                <div className="card">
                                    <img className="card-img-top" src={item.src} alt={item.alt} />
                                    <div className="card-body">
                                        <div className="contents">
                                            <img src={item.logo} />
                                            <div className="contents-item">
                                                <StarRating stars={5} size={20} />
                                                <h4 className="card-title">{item.title}</h4>
                                                <h5 className="card-title">{item.title1}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </section> 
        <section className="similar-view">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12"><h3>View Similar Experiences</h3></div>
                </div>
                <div className="row">
                    {
                        Experiences.length && Experiences.map(img => {
                            return(
                                <div className="col-lg-4 col-md-6 col-sm-12"  key={img.id}>
                                    <div className="card romance-card" >
                                        <img className=""  src={img.src}  />
                                        <div className="card-body">
                                            <span className="location"><FiMapPin /></span><span>{img.location}</span>
                                            <p>{img.content}</p>
                                            <div className="logo-sec"> 
                                                <span className="view-icon" onClick={() => handelCounterViews(img.id)}><FaEye /></span> <span>{img.view}</span>
                                                <span className="thumb-icon" onClick={() => handelCounterLikes(img.id)}><FaThumbsUp /></span> <span>{img.like}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })                            
                    }
                </div>
            </div>
        </section> 
    </div>;
}

export default InterestSportsifaDetails;
