import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageLaoder from '../atom/loader/pageLaoder';
import { aboutusAction } from '../../redux/actions/homeAction';
import ExploreInterest from '../atom/ExploreInteres';
import { Helmet } from 'react-helmet';

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";

const Aboutus = () => {
    let dispatch = useDispatch()

    const auth = useSelector((state) => state.home.auth);
    const bannerData = useSelector((state) => state.home.aboutBanner);

    const [banner, setBanner] = useState()
    const [pageContent, setPageContent] = useState()
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)


    useEffect(() => {
        dispatch(aboutusAction())
    }, [auth])


    useEffect(() => {
        if(bannerData !== undefined){
            const { banner, pageContent } = bannerData
            setBanner(banner)
            setPageContent(pageContent)
            if(bannerData.hasOwnProperty("seoContent") && bannerData.seoContent !== null){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = bannerData.seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
        }
    }, [bannerData])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="aboutus-page">
            <Helmet>
                <title>{ metaTitle !== null ? metaTitle : "About us" }</title>
                <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
                <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
                <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
                <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
                <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
                <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:type" content={ socialType !== null ? socialType : null } />
            </Helmet>
            { banner !== undefined ?
            <ExploreInterest
                items={1}
                loop = {false}
                margin= {10}
                animateOut= {'fadeOut'}
                outoplay = {true}
                autoplayHoverPause= {true}
                speed = {5000}
                responsiveClass = {true}
                banner={banner}
        /> : <PageLaoder />}
        
           
            <div className="comment-section">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">About Company & Founder</li>
                    </ul>
                    <div className="about-content">
                        { pageContent !== undefined ? <h1>{pageContent[0].title}</h1> : null}
                        { pageContent !== undefined ? 
                            <div dangerouslySetInnerHTML={{__html: pageContent[0].content}} /> :""
                        }
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-5">
                            <div className="founder-img">
                                { pageContent !== undefined ?
                                    <figure>
                                        <img src={`${imageUrl}/${pageContent[1].mediaFilePath}`} alt={pageContent[1].mediaAlText} title={pageContent[1].mediaTitle} />
                                        <figcaption>
                                            <p>{pageContent[1].mediaTitle}</p>
                                            <p>Founder</p>
                                        </figcaption>
                                    </figure> : ""
                                }
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-7">
                            <div className="aboutus-content">
                                { pageContent !== undefined ? <h1>{pageContent[1].title}</h1> : "" }
                                { pageContent !== undefined ? 
                                    <div dangerouslySetInnerHTML={{__html: pageContent[1].content}} /> :""
                                }
                                {/* <div className="vision">
                                    <h2>Vision</h2>
                                    <p>
                                        The mission statement or vision of the organisation is to curate experiences, based on interests, 
                                        events or special occasions, which coupled with the story that we weave around it, creates a happy memory, 
                                        a memory cherished for a lifetime, by our guests.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aboutus;