import React from 'react';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import '../../assets/css/animate.css'

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";


const HeroActivitis = (props) => {
    // console.log("Props databannner", props.banner)

    return (
        <div className="hero_banner">
            <div className="explore-slider">
                <OwlCarousel 
                    items={props.items}
                    loop = {props.loop}
                    margin= {props.margin}
                    animateOut= {props.animateOut}
                    autoplay = {props.outoplay}
                    autoplayHoverPause= {props.autoplayHoverPause}
                    autoplaySpeed = {props.speed}
                    responsiveClass = {props.responsiveClass}
                > 
                {
                    props.banner !== undefined && props.banner.map((data,i) => {
                        return<React.Fragment key={i}>
                        {   data.mediaListing.length && data.mediaListing.map((item,index) =>{
                                if(item.id === 3){
                                    return<figure key={index}>
                                        <img className="desktop" src={`${imageUrl}/${data.mediaFilePath}`} alt={data.mediaAlText} title={data.mediaTitle} />
                                        <img className="tablet"  src={`${imageUrl}/${data.mediaTabletFilePath}`} alt={data.mediaAlText} title={data.mediaTitle} />
                                        <img className="mobile"  src={`${imageUrl}/${data.mediaMobileFilePath}`} alt={data.mediaAlText} title={data.mediaTitle} />
                                    </figure>
                                }
                            })
                        }
                        </React.Fragment>
                    })
                }
                </OwlCarousel>
            </div>
        </div>
    )
}


export default HeroActivitis;