export const FETCH_AUTH_DATA = "FETCH_AUTH_DATA";
export const FETCH_AUTH_RESPONSE = "FETCH_AUTH_RESPONSE";
export const AUTH_DATA_FAILED = "AUTH_DATA_FAILED"


export const FETCH_ENQUERY_DATA = "FETCH_ENQUERY_DATA";
export const FETCH_ENQUERY_RESPONSE = "FETCH_ENQUERY_RESPONSE";
export const ENQUERY_DATA_FAILED = "ENQUERY_DATA_FAILED"


export const FETCH_SUBSCRIBE_DATA = "FETCH_SUBSCRIBE_DATA";
export const FETCH_SUBSCRIBE_RESPONSE = "FETCH_SUBSCRIBE_RESPONSE";
export const SUBSCRIBE_DATA_FAILED = "SUBSCRIBE_DATA_FAILED"


export const FETCH_CONTACTUS_DATA = "FETCH_CONTACTUS_DATA";
export const FETCH_CONTACTUS_RESPONSE = "FETCH_CONTACTUS_RESPONSE";
export const CONTACTUS_DATA_FAILED = "CONTACTUS_DATA_FAILED"


export const FETCH_EXPE_ENQUERY_DATA = "FETCH_EXPE_ENQUERY_DATA";
export const FETCH_EXPE_ENQUERY_RESPONSE = "FETCH_EXPE_ENQUERY_RESPONSE";
export const EXPE_ENQUERY_DATA_FAILED = "EXPE_ENQUERY_DATA_FAILED"