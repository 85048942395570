import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { homeDatas, interestDatas, destinationAction, bookYouNextAction,
        activitiesAction,
        fetchTestimonials,
        featuredToursAction,
} from '../../redux/actions/homeAction'
import OwlCarousel from 'react-owl-carousel';  
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useNavigate } from "react-router-dom";
import HeroBanner from '../atom/HeroBanner';
import Exprience from '../atom/Exprience';
import PageLaoder from '../atom/loader/pageLaoder';

import leftArrow from '../../assets/icons/left-arrow.png'
import rightArrowB from '../../assets/icons/right-arrowb.png'
import rightArrow from '../../assets/icons/right-arrow.png'

import { FaQuoteLeft,FaQuoteRight }  from "react-icons/fa";

import '../../assets/css/style.scss'
import '../../assets/css/mediastyle.scss'
import '../../assets/css/mediastyle.scss'
import '../../assets/css/animate.css'
import { Helmet } from 'react-helmet';
import InstagramHomeSlider from './Instagram/instagramSlider';



let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";


const options = {
    0: {
        items: 1,
        nav:true,
        dots: false
    },
    600: {
        items: 2,
        dots: false,
        nav:true,
    },
    1000: {
        items: 3,
        dots: false,
        loop: true,
        nav:true,
        navText: [`<img src=${leftArrow} />`,`<img src=${rightArrow} />`]

    },
}

const Home = () => {
    const navigate = useNavigate();
    let dispatch = useDispatch();

    const pageContent = useSelector((state) => state.home.homepage.pageContent);
    const homepage = useSelector((state) => state.home.homepage);
    const bannerData = useSelector((state) => state.home.homepage.banner);
    const interestData = useSelector((state) => state.home.interest);
    const destination = useSelector((state) => state.home.destination);
    // const tours = useSelector((state) => state.home.tours);
    const featuretours = useSelector((state) => state.home.featureTours);
    // const auth = useSelector((state) => state.home.auth);
    const testiData = useSelector((state) => state.home.testimonials);
    

    const [banner, setBanner] = useState()
    const [sectionFirst, setSectionFirst] = useState()
    const [sectionSecond, setSectionSecond] = useState()
    const [sectionThird, setSectionThird] = useState()
    const [sectionFourth, setSectionFourth] = useState()
    const [interest, setInterest] = useState([])
    const [logos, setLogos] = useState([])
    const [destData, setDestData] = useState()
    const [toursData, setToursData] = useState([])
    const [testimonial, setTestimonial] = useState([])
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)
   
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1.16,
        slidesToScroll: 1,
        // nextArrow:  <img src= {leftArrow} />,
        // prevArrow: <img src= {rightArrow} />,
        
    }

    const tourSettings = {
        dots: true,
        infinite: true,
        fade: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        nextArrow: false,
        prevArrow: false
      }

    useEffect(() => {
        dispatch(homeDatas()) 
        dispatch(interestDatas())
        dispatch(destinationAction())
        dispatch(bookYouNextAction())
        dispatch(activitiesAction())
        dispatch(featuredToursAction())
        // dispatch(toursAction())
        dispatch(fetchTestimonials())
	},[]);

    useEffect(() => {
        if(bannerData !== undefined ){
            setBanner(bannerData)
        }
	},[bannerData]);

    useEffect(() => {
       if(destination !== undefined){
            const destArry = Object.values(destination)
            setDestData(destArry)
       }
	},[destination]);

    useEffect(() => {
        if(pageContent !== undefined){
            setSectionFirst(pageContent[0])
            setSectionSecond(pageContent[1])
            setSectionThird(pageContent[2])
            setSectionFourth(pageContent[3])
        }
        if(homepage.hasOwnProperty("seoContent") && homepage.seoContent !== null ){
            const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
            metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = homepage.seoContent
            setMetaTitle(metaTitle)
            setMetaKeyword(metaKeyWord)
            setMetaDescription(metaDescription)
            setCanonicalURl(canonicalURl)
            setFocusKeyPhrase(focusKeyPhrase)
            setKeyPhraseSynonyms(keyPhraseSynonyms)
            setSchema(schema)
            setSocialDescription(socialDescription)
            setSocialIcon(socialIcon)
            setSocialIconPath(socialIconPath)
            setSocialTitle(socialTitle)
            setSocialType(socialType)
        }
	},[pageContent, homepage]);

    useEffect(() => {
        if(featuretours !== undefined){
           setToursData(featuretours)
        }
	},[featuretours]);

    useEffect(() => {
        if(interestData !== undefined){
            let icons = []
            interestData.forEach(item => {
                icons.push(item.iconPath)
            })
            setLogos(icons)
            setInterest(interestData)
        }
	},[interestData]);

    useEffect(() => {
        if(testiData !== undefined){
            setTestimonial(testiData)
        }
	},[testiData]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return <React.Fragment>
        <Helmet>
            <title>{ metaTitle !== null ? metaTitle : "luxurye scapades" }</title>
            <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
            <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
            <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
            <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
            <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
            <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
            <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
            <meta property="og:type" content={ socialType !== null ? socialType : null } />
        </Helmet>
        { banner !== undefined ? 
            <HeroBanner 
                items={1}
                loop = {true}
                margin= {10}
                animateOut= {'fadeOut'}
                outoplay = {true}
                autoplayHoverPause= {true}
                speed = {5000}
                responsiveClass = {true}
                banner={ banner !== undefined ? banner : ""}
            />: <PageLaoder />
        }

        <div className="wrapper home">
            {
                sectionFirst !== undefined ? <section className="about bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 col-sm-12 upper-img">
                                <figure>
                                    <img src={`${imageUrl}/${sectionFirst.mediaFilePath}`} alt={sectionFirst.mediaAlText} title={sectionFirst.mediaTitle} />
                                </figure>
                            </div>
                            <div className="col-md-7 col-sm-12">
                                <div className="inner-spacer">
                                    <h2>{sectionFirst.title}</h2>
                                    <div dangerouslySetInnerHTML={{__html: sectionFirst.content}} />
                                    <button className="btn-primary"
                                        onClick={() => navigate("/about-us")}
                                        // onClick={() => navigate(`/interest/${item.slug}`, {state:[item, item.slug]})}
                                    >Know More</button>
                                </div>
                            </div>
                        </div>
                        <figure className="bottom-img">
                        {sectionFirst !== undefined ?<img src={`${imageUrl}/${sectionFirst.media[0].mediaFilePath}`} alt={sectionFirst.mediaAlText} title={sectionFirst.mediaTitle} />: ""}
                        </figure>
                    </div>
                </section> : ""
            }

            <section className="explore-interest">
                { sectionSecond !== undefined ?
                    <div className="container">
                        <h2>{sectionSecond.title}</h2>   
                        <div className="info-spacer">
                            <div dangerouslySetInnerHTML={{__html: sectionSecond.content}} />
                        </div>
                    </div> : ""
                }
                <div className="explore-slider">
                    {Array.isArray(interest) && interest !== "string" ? <Slider {...settings}
                        customPaging = {(i) => <img src={`${imageUrl}/${logos[i]}`}  alt={i}/> }
                        dotsClass = "slick-dots custom-indicator">
                    {
                        Array.isArray(interest) &&  interest.map((item, index) => {
                        return <React.Fragment key={index}>
                        {
                            item.mdaMedia.map((key,i) => {
                                return (<li className="item" key={i} >
                                    <figure>
                                        <img src={`${imageUrl}/${key.mediaFilePath}`} alt={key.mediaAlText} title={key.mediaTitle} />
                                        <figcaption>
                                            <div className="title-icon">
                                                <img src={`${imageUrl}/${item.iconPath}`} alt={key.mediaAlText} title={key.mediaTitle} />
                                            </div>
                                            <h2>{item.interest}</h2>
                                            <button className="btn-primary explore-btn"
                                                onClick={() => navigate(`/interest/${item.slug}`, {state:[item, item.slug]})}
                                            >Explore</button>
                                        </figcaption>
                                    </figure>
                                </li>
                                )
                            })
                        }
                    </React.Fragment>})
                    }
                    </Slider> : null
                }
                </div>
            </section>
            <section className="destinations">
                <div className="container">
                    {
                        sectionThird !== undefined ? <div className="info-spacer">
                        <h2>{sectionThird.title}</h2>
                        <div className="info-spacer paddr">
                            <div dangerouslySetInnerHTML={{__html: sectionThird.content}} />
                        </div>
                    </div> : ""
                    }
                    <div className="destn-slider">
                        <OwlCarousel 
                            items="3"
                            autoplay={false} 
                            margin={25} 
                            nav={true}
                            loop={true}
                            navText= {[`<img src=${leftArrow} />`,`<img src=${rightArrow} />`]}
                            responsiveClass={true}
                            responsive ={ options}
                            > 
                            {
                                destData !== undefined && destData.map((item, index) => {
                                    return (<React.Fragment key={index}>
                                        {
                                            item.mdaMedia.map((key,i) => {
                                                return<React.Fragment key={i}>
                                                    {
                                                        key.mediaListing.map((li, j) => {
                                                        if(li.id === 6 ){
                                                            return (<div className="item" key={i}>
                                                                <div className="card">
                                                                    <figure>
                                                                        <img className="card-img-top" src={`${imageUrl}/${key.mediaFilePath}`} alt={key.mediaAlText} title={key.mediaTitle} />
                                                                    </figure>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">{item.country}</h5>
                                                                        <button className="discover-btn"  onClick={() => navigate(`/destinations/${item.slugName}`, {state:[item, item.slugName]})}
                                                                        >Discover</button>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                   </React.Fragment>
                                            })
                                        }
                                    </React.Fragment>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            {/* <section className='instagram'>
                <div className='container'>
                    <InstagramHomeSlider />
                </div>
            </section> */}
            <Exprience />
            <section className="experiences">
                <div className="container">
                    {
                        sectionFourth !== undefined ? <>  
                        <h2>{sectionFourth.title}</h2>
                        <div className="info-spacer">
                            <div dangerouslySetInnerHTML={{__html: sectionFourth.content}} />
                        </div>
                        </> : ""
                    }
                    <div className="exp-slider">
                        <Slider {...tourSettings} >
                            {
                                Array.isArray(toursData) && toursData.length && toursData.map((item, index) => {
                                    let prvPath = item.destination.charAt(0).toLowerCase() + item.destination.slice(1)
                                    return <React.Fragment key={index}>
                                        {
                                            item.mdaMedia.map((data,i) =>{
                                                const [ arrId ]  = data.mediaListing
                                                if(arrId.id === 9 ){
                                                    return<div className="item" key={i}>
                                                        <figure>
                                                            <img src={`${imageUrl}/${data.mediaFilePath}`} alt={data.mediaAlText} title={data.mediaTitle} />
                                                            <figcaption>
                                                                <h1> {item.no_of_days}<span>{" Days "}</span>{`/ ${item.no_of_nights}`} <span>{"Nights"}</span></h1>
                                                                <p>{item.tour}</p>
                                                                <button className="btn-primary m-auto explore-button" 
                                                                    onClick={() => navigate(`/destinations/${prvPath}/${item.slugName}`, {state:[ item, item.slugName]})}
                                                                >Explore Now</button>
                                                            </figcaption>
                                                        </figure>
                                                    </div>
                                                }
                                            })
                                        }
                                    </React.Fragment>
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </section>

            { testimonial.length ? <section className="testimonial">

                <div className="container">
                    <OwlCarousel 
                        items={1}
                        loop = {true}
                        autoplay={false} 
                        nav={true}
                        navText= {[`<img src=${rightArrowB} />`,`<img src=${rightArrowB} />`]}
                    > 
                        {
                            Array.isArray(testimonial) && testimonial.map((item, index) => {
                                return (<div className="item" key={index}>
                                    <figure>
                                        <figcaption>
                                            <h2>Experiential Comments</h2>
                                            <div className="testimonials_text_before"><FaQuoteLeft /></div>
                                            <p>{item.testimonialDetail}</p>
                                            <div className="testimonials_text_after" ><FaQuoteRight /></div>
                                            <div className="title"><h3><span>{item.name}</span></h3></div>
                                        </figcaption>
                                    </figure>
                                </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>
            </section> : null }
        </div>
    </React.Fragment>;
}


export default Home;