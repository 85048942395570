import React from 'react';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import '../../assets/css/animate.css'

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";


const HeroInterest = (props) => {

    const { mdaMedia, iconPath, interest } = props.banner

    console.log("Dynamic Banner data ",props.banner)
    return (
        <div className="hero_banner">
            <div className="explore-slider">
                <OwlCarousel 
                    items={props.items}
                    loop = {props.loop}
                    margin= {props.margin}
                    animateOut= {props.animateOut}
                    autoplay = {props.outoplay}
                    autoplayHoverPause= {props.autoplayHoverPause}
                    autoplaySpeed = {props.speed}
                    responsiveClass = {props.responsiveClass}
                > 
                {
                    
                    mdaMedia !== undefined && mdaMedia.map((item, index) => {
                        return (<div className="item" key={index}>
                        <figure>
                            <img className="desktop" src={`${imageUrl}/${item.mediaFilePath}`} alt={item.mediaAlText} title={item.mediaTitle} />
                            <img className="tablet"  src={`${imageUrl}/${item.mediaTabletFilePath}`} alt={item.mediaAlText} title={item.mediaTitle} />
                            <img className="mobile"  src={`${imageUrl}/${item.mediaMobileFilePath}`} alt={item.mediaAlText} title={item.mediaTitle} />
                            <figcaption>
                                <div className="title-icon">
                                    <img src={`${imageUrl}/${iconPath}`} alt={item.mediaAlText} title={item.mediaTitle} />
                                </div>
                                <h2>{interest}</h2>
                            </figcaption>
                        </figure>
                    </div>)
                      
                    })
                }
                </OwlCarousel>
            </div>
        </div>
    )
}


export default HeroInterest;