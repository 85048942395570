import { FETCH_HOME_DATA, FETCH_INTEREST_DATA, FETCH_DESTINATION_DATA,
    FETCH_BOOK_NEXT_DATA,FETCH_ACTIVITIES_DATA,FETCH_TOURS_DATA,FETCH_ACCOMMODATIONS_DATA,
    FETCH_EXPLORE_BANNER_DATA,FETCH_DESTINATION_BANNER_DATA, FETCH_ORGANIZATION_DATA, 
    FETCH_ABOUTUS_BANNER, FETCH_ENQUERY_BANNER, FETCH_CONTACTUS_BANNER, FETCH_COUNTRIES_DATA, 
    FETCH_SALUTATION_DATA, FETCH_TENTATIVEBUDGET_DATA, FETCH_HOLIDAYDURATION_DATA, 
    FETCH_VACCINATION_DATA, FETCH_ENQUIRSOURCE_DATA, FETCH_TESTIMONIALS_DATA, FETCH_TESTIMONIALS_BANNER,
    FETCH_FEATURED_TOURS, FETCH_ENQUIRY_TOURS, FETCH_ENQUIRY_ACTIVITY, FETCH_EXCEPTIONAL_EXPERIENCE, FETCH_EXPERIENCES_DATA
} from '../types/homeType'


export const homeDatas = () => ({
    type: FETCH_HOME_DATA,
})


export const exploreBanner = () => ({
    type: FETCH_EXPLORE_BANNER_DATA,
})

export const destinationBanner = () => ({
    type: FETCH_DESTINATION_BANNER_DATA,
})

export const interestDatas = () => ({
    type: FETCH_INTEREST_DATA,
})


export const destinationAction = () => ({
    type: FETCH_DESTINATION_DATA,
})


export const bookYouNextAction = () => ({
    type: FETCH_BOOK_NEXT_DATA,
})

export const activitiesAction = () => ({
    type: FETCH_ACTIVITIES_DATA,
})

export const toursAction = () => ({
    type: FETCH_TOURS_DATA,
})

export const accommodationsAction = () => ({
    type: FETCH_ACCOMMODATIONS_DATA,
})

export const organizationAction = () => ({
    type: FETCH_ORGANIZATION_DATA,
})

export const aboutusAction = () => ({
    type: FETCH_ABOUTUS_BANNER,
})

export const enqueryBannerAction = () => ({
    type: FETCH_ENQUERY_BANNER,
})

export const contactusAction = () => ({
    type: FETCH_CONTACTUS_BANNER,
})

export const countriesAction = () => ({
    type: FETCH_COUNTRIES_DATA,
})

export const salutationAction = () => ({
    type: FETCH_SALUTATION_DATA,
})

export const tentativebudgetAction = () => ({
    type: FETCH_TENTATIVEBUDGET_DATA,
})

export const holidaydurationAction = () => ({
    type: FETCH_HOLIDAYDURATION_DATA,
})

export const vaccinationAction = () => ({
    type: FETCH_VACCINATION_DATA,
})

export const sourceAction = () => ({
    type: FETCH_ENQUIRSOURCE_DATA,
})

export const fetchTestimonials = () => ({
    type: FETCH_TESTIMONIALS_DATA,
})

export const fetchTestiBanner = () => ({
    type: FETCH_TESTIMONIALS_BANNER,
})

export const featuredToursAction = () => ({
    type: FETCH_FEATURED_TOURS,
})

export const tourEnquiryAction = () => ({
    type: FETCH_ENQUIRY_TOURS,
})

export const activityEnquiryAction = () => ({
    type: FETCH_ENQUIRY_ACTIVITY,
})

export const exceptionalExperience = () => ({
    type: FETCH_EXCEPTIONAL_EXPERIENCE,
})

export const experienceAction = () => ({
    type: FETCH_EXPERIENCES_DATA,
})

