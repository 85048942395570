import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { destinationAction,destinationBanner } from '../../redux/actions/homeAction'
import { useNavigate,Link } from "react-router-dom";
import Exprience from '../atom/Exprience';
import ExploreInteres from '../atom/ExploreInteres';
import PageLaoder from '../atom/loader/pageLaoder';
 
let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";

const DiscoverDestinations = () => {
    const navigate = useNavigate();
    let dispatch = useDispatch();

    const destination = useSelector((state) => state.home.destination);
    const destBanner = useSelector((state) => state.home.destinationBanner);
    const auth = useSelector((state) => state.home.auth);

    const [destData, setDestData] = useState()
    const [dataset, setDataset] = useState()
    const [banner, setBanner] = useState()
    const [options, setOptions] = useState([])
    const [pagecontent, setPageContent] = useState()
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)

    useEffect(() => {
        dispatch(destinationBanner())
        dispatch(destinationAction())
	},[]);


    useEffect(() => {
       if(destBanner !== undefined){
            const { banner, pageContent } = destBanner
            setBanner(banner)
            setPageContent(pageContent)
            if(destBanner.hasOwnProperty("seoContent") &&  destBanner.seoContent !== null){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = destBanner.seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
       }
	},[destBanner]);

    useEffect(() => {
       if(destination !== undefined){
            let destArry = Object.values(destination)
            setDestData(destArry)
            setDataset(destArry)
            let option = destArry.map((li) => {
                return { lable: li.destination , value: li.slugName}
            })
            setOptions(option)
       }
	},[destination]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSelectChange = (value) =>{
        
        dataset.length && dataset.filter((item) => {
            if(value === item.slugName){
                return setDestData([item])
            }else if(value === "all"){
                let destArry = Object.values(destination)
                return setDestData(destArry)
            }
        })
    }

   
    return <React.Fragment>
            <Helmet>
                <title>{ metaTitle !== null ? metaTitle : "Destinations" }</title>
                <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
                <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
                <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
                <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
                <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
                <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:type" content={ socialType !== null ? socialType : null } />
            </Helmet>

            {banner !== undefined ?
                <ExploreInteres 
                items={1}
                loop = {false}
                margin= {10}
                animateOut= {'fadeOut'}
                outoplay = {true}
                autoplayHoverPause= {true}
                speed = {5000}
                responsiveClass = {true}
                banner={banner}
            /> : <PageLaoder />}
        <div className="discover-page">
            <section className="discover-sec">
                <div className="container">
                    <div className='row'>
                        <ul className="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">Discover Destinations</li>
                        </ul>
                    </div>
                    <h1>{pagecontent !== undefined ? pagecontent[0].title : ""}</h1>
                    <div className="row info-spacer">
                        <div className="col-12">
                            {  pagecontent !== undefined ? <div dangerouslySetInnerHTML={ {__html: pagecontent[0].content}} /> : ""}
                        </div>
                        <div className="col-md-7 col-sm-12">
                            <span>Destinations</span>
                            <select className="form-control" onChange={(e) => handleSelectChange(e.target.value)}>
                                <option value={"all"}>All</option>
                                {
                                    options.map((item, i) =>{
                                        return <option key={i} value={item.value}>{item.lable}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="offset-7"></div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="discove-list">
                        <div className="row">
                            {
                                destData !== undefined && destData.map((item, index) => {
                                    return <React.Fragment key={index}>
                                        {
                                            item.mdaMedia.map((key,i) => {
                                                {
                                                    return <React.Fragment key={i}>
                                                        {
                                                            key.mediaListing.map((li, j) => { 
                                                                if(li.id === 6){  
                                                
                                                                return (<div className="col-lg-4 col-md-6 col-ms-6" key={j}>
                                                                <div className="card">
                                                                    <figure>
                                                                        <img    
                                                                            className="card-img-top" 
                                                                            src={`${imageUrl}/${key.mediaFilePath}`} alt={key.mediaAlText} title={key.mediaTitle} 
                                                                            onClick={() => navigate(`/destinations/${item.slugName}`, {state:[item ,item.slugName]})}
                                                                        />
                                                                    </figure>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">{item.country}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )} 
                                                            })
                                                        }
                                                    </React.Fragment>
                                                }
                                            })
                                        }
                                    </React.Fragment>
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        <Exprience />
        </div>

    </React.Fragment>;
}

export default DiscoverDestinations;