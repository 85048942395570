//let url = "https://" + window.location.host + "/moslcms/cms/";
// let url = process.env.CMS_BASE_URL;
let baseUrl = process.env.REACT_APP_BASE_URL;

// let baseAPI = 'http://dxtechsol.com:8080/api/v1/';
// console.log(url, '<--urlEndpoint-->', baseAPI)

export const endpoints = {

    baseUrl,

    Posts: {
        posts: "/posts",
        users: "/users",
    },
    AuthUrl: {
        auth : "login_check",
        // enquiry : "enquiryFormSubmit",
        newsletter : "newsletter"
    },
    HomeUrl: {
        homePage: "cmsPage/home-page",
        destinationBanner: "cmsPage/discover-destinations",
        interest: "interests",
        countries: "countries",
        salutation: "salutation",
        tentativeBudget: "tentativeBudget",
        holiday_duration: "holiday_duration",
        vaccination: "vaccination",
        testimonials: "testimonials",
        commentsbanner: "cmsPage/experiential-comments",
        enquirsource: "enquiry_source",
        organization: "organization",
        destination: "general-information",
        bookYouNext: "cmsPage/why-book-your-next-holiday-with-luxury-escapades",
        activities: "activities",
        accommodations: "accommodations",
        tours: "tours",
        featuredtours: "featured-tours",
        enquiryFormSubmit: "enquiryFormSubmit",
        contactFormSubmit: "contactFormSubmit",
        exploreInterest: "cmsPage/explore-interest",
        aboutus: "cmsPage/about-luxury-escapades",
        enquirybanner: "cmsPage/enquiry",
        contactus: "cmsPage/contact-us",
        termsandconditions: "cmsPage/terms-and-conditions",
        tourenquiry: "cmsPage/tour-enquiry",
        activityenquiry: "cmsPage/activity-enquiry",
        exceptionalExperience: "cmsPage/exceptional-experiences",
        experiences: "experiences",
        experienceFormSubmit: "experienceFormSubmit",
    }
}