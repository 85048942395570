import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FacebookShareButton, LinkedinShareButton, EmailShareButton } from "react-share";
import { RWebShare } from "react-web-share";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';
import { accommodationsAction
} from '../../redux/actions/homeAction'
import { Link, useLocation, useNavigate,useParams } from 'react-router-dom';
import { FaEye,FaThumbsUp,FaMapMarkerAlt,FaPlus }  from "react-icons/fa";
import { FiMapPin }  from "react-icons/fi";
import { BsMoon }  from "react-icons/bs";
import { BiMinus }  from "react-icons/bi";
import { FaEnvelope,FaLinkedinIn, FaInstagram, FaFacebookF,FaYoutube } from "react-icons/fa";
import NavOption from '../atom/NavOption';
import ToursBanner from '../atom/ToursBanner';
import { authAction } from '../../redux/actions/AuthAction';
import PageLaoder from '../atom/loader/pageLaoder';
import { Helmet } from 'react-helmet';


// const array = ["Overview", "Itinerary", "Accommodations", "Inclusions", "Optionals", "Tour Info"]

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";
let baseUrl = process.env.REACT_APP_BASE_URL;


const InterestDestinationDetails = () => {
    // const location = useLocation();
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const { prvpath, path } = useParams();

    const tourSettings = {
        dots: true,
        infinite: true,
        fade: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true
    }


    const [collapse, setCollapse] = useState(null)
    const [optionalView, setOptionalView] = useState(false)
    const [tourCollapse, setTourCollapse] = useState(null)
    const [accommo, setAccommo] = useState()
    const [title, setTitle] = useState("")
    const [prvPath, setPrvpath] = useState("")
    // const [description, setDescription] = useState("")
    const [location, setLocation] = useState("")
    const [tourinfo, setTourInfo] = useState([])
    const [slider, setSlider] = useState([])
    const [open, setOpen] = useState(0)
    const [details,setDetails] = useState()
    const [itineraries,setItineraries] = useState()
    const [banner, setBanner] = useState()
    const [departureDate, setDepartureDate] = useState("")
    const [startDepartureDate, setStartDepartureDate] = useState("")
    const [lastDepartureDate, setLastDepartureDate] = useState("")
    const [navbarArr, setNavbarArr] = useState([])
    const [loader, setLoading] = useState(false);
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)

    useEffect(() => {
        const token = localStorage.getItem("token")
        const fetchData = async() =>{
            const fetchApi = await fetch(`${baseUrl}tour/${path}`,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + `${token}`,
                }
            })
            const data = await fetchApi.json();
            if(data.message === "success"){
                setDetails(data.data)
                setLoading(true)
            }
        }

        fetchData();
    }, [])


    useEffect(() => {
        dispatch(accommodationsAction())
    }, [])


    useEffect(() => {
        if( departureDate.length){
            setStartDepartureDate(departureDate[0])
            setLastDepartureDate(departureDate.slice(-1)[0])
        }
    }, [departureDate])


    useEffect(() => {
        if(details !== undefined){
            setBanner(details[0].mdaMedia)
            setTitle(details[0].tour)
            setPrvpath(prvpath.charAt(0).toUpperCase() + prvpath.slice(1))
            if(details[0].hasOwnProperty("itineraries")){
                setItineraries(details[0].itineraries)
            }
            if(details[0].hasOwnProperty("departureDate")){
                setDepartureDate(details[0].departureDate)
            }
            let joinlocation = [] , locationId
            details[0].locations.forEach(item => {
                joinlocation.push( item.location)
            })
            locationId = joinlocation.join(", ");
            setLocation(locationId)
            navArray();

            if(details[0].hasOwnProperty("seoContent") && details[0].seoContent !== null ){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = details[0].seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
        }
    }, [details])

    

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const navArray = () => {
        let array = ["Overview"];
        let data = [];
        let tourinform = null;
        details.forEach(item => {
            const { accommodations: accommo1 } = item
            if(item.hasOwnProperty("itineraries")){
                const {itineraries} = item
                if(itineraries.length){
                    array.push("Itinerary")
                }
            }
            
            if(accommo1 !== undefined && accommo1[0].hasOwnProperty("accommodations")){
                const  {accommodations}  = accommo1[0]
                accommodations.forEach(value => {
                    const {mdaMedia,isActive } = value
                    if(mdaMedia.length && isActive === true){
                        setAccommo(details[0].accommodations)
                        array.push("Accommodations")
                    }
                })
            }

            if(item.hasOwnProperty("inclusion") || item.hasOwnProperty("exclusion")){
                const { inclusion,exclusion } = item
                if(inclusion !== null || exclusion !== null){
                    array.push("Inclusions")
                }
            }

            if(item.hasOwnProperty("itineraries")){
                const { itineraries } = item
                if(itineraries.length){
                    let check = 0
                    itineraries.forEach(value => {
                        const { optionalActivities, itineraryMedia } = value
                        data.push({name: itineraryMedia.mediaTitle, file: itineraryMedia.mediaFilePath })
                        if(optionalActivities.length){
                            check ++ ;
                        }
                    })
                    setSlider(data)
                    if(check > 0 ){
                        setOptionalView(true)
                        array.push("Optionals")
                    }else{ setOptionalView(false) }
                }
            }
            
            if(item.hasOwnProperty("tourInformation")){
                const { tourInformation } = item
                if(tourInformation.length){
                    array.push("Tour info")
                }
            }
            if(item.hasOwnProperty("tourInformation")){
                const { tourInformation } = item  
                tourinform = tourInformation
            }
            if(item.hasOwnProperty("generalInformations")){
                const { generalInformations }  = item
                if( generalInformations.length && generalInformations[0].hasOwnProperty("details")){
                    if(!array.includes("Tour info")){
                        array.push("Tour info")
                    }
                    const { details } = generalInformations[0]
                    if( tourinform === null ){
                        tourinform = details
                    }else{
                        details.length && details.forEach(data => {
                            tourinform.push(data)
                        })
                    }
                }
            }
        })
        setTourInfo(tourinform)
        setNavbarArr(array)
    }

    const handelCounterViews = (id) =>{
       
    }
    
    const handelCounterLikes = (id) =>{
        
    }

    const handleCollapse = (i) =>{
        if(collapse === i){
            return setCollapse(null)
        }        
        if(collapse === "all"){
            setOpen("")
            return setCollapse("null")
        }        
        setCollapse(i)
        setOpen(null)
    }

    const handletourinfoCollapse = (i) =>{
        if(tourCollapse === i){
            return setTourCollapse(null)
        }        
        setTourCollapse(i)
    }

    // console.log("detailsdetails",details)

    let start = moment(startDepartureDate).format('L').toString().slice(-4);
    let end = moment(lastDepartureDate).format('L').toString().slice(-4);
    let final = start === end ? start : `${start} - ${end}`;

    return <div className="romance-interest"> 
            <Helmet>
                <title>{ metaTitle !== null ? metaTitle : null }</title>
                <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
                <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
                <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
                <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
                <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
                <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:type" content={ socialType !== null ? socialType : null } />
            </Helmet>

            {  banner !== undefined ?  
                <ToursBanner 
                items={1}
                loop = {false}
                margin= {10}
                animateOut= {'fadeOut'}
                outoplay = {true}
                autoplayHoverPause= {true}
                speed = {5000}
                responsiveClass = {true}
                banner={banner}
            /> : <PageLaoder /> }
        
        <div className="container">
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li className="non-active"><Link to="/destinations"> Discover Destinations</Link></li>
                        <li className="non-active"><Link to={`/destinations/${prvpath}`}>{prvPath}</Link></li>
                        <li className='active'>{ title !== null ? title : "" }</li>
                    </ul>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className="icon-list">
                        <ul className='details-icon'>
                            {/* <li><span className="icons" onClick={() => handelCounterViews()}><FaEye /></span> <span>500</span></li>
                            <li><span className="icons" onClick={() => handelCounterLikes()}><FaThumbsUp /></span> <span>352</span></li> */}
                            <li>
                                <RWebShare
                                    data={{
                                    text: title !== undefined && title.length ? title : null,
                                    url: `https://dev-le.sublimis.dev/details-of/${path}`,
                                    title: title !== undefined && title.length ? title : null
                                    }}
                                >
                                    <span className="icons">
                                        Share On
                                    </span>
                                </RWebShare>
                            </li>
                            <li>
                                <EmailShareButton
                                     url={`https://dev-le.sublimis.dev/tours/details//${path}`}
                                     className=""
                                >
                                    <FaEnvelope  />
                                </EmailShareButton>
                            </li>
                            <li>
                                <FacebookShareButton
                                    url={`https://dev-le.sublimis.dev/tours/details//${path}`}
                                    className=""
                                >
                                    <FaFacebookF />
                                </FacebookShareButton>
                            </li>
                            {/* <li>
                                <InstagramShareButton
                                    url={`https://dev-le.sublimis.dev/tours/details//${path}`}
                                    className=""
                                >
                                    <FaInstagram />
                                </InstagramShareButton>
                            </li> */}
                            <li>
                                <LinkedinShareButton
                                    url={`https://dev-le.sublimis.dev/tours/details//${path}`}
                                    className=""
                                >
                                    <FaLinkedinIn />
                                </LinkedinShareButton>
                            </li>
                            {/* <li>
                                <Link to="#" target="_blank" title="Youtube"><FaYoutube /></Link>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="interest-details">
                <div className="text-center interest1">
                    <h2>{ title !== null ? title : null }</h2>
                    { departureDate.length ? <div className="title">
                        <h3><span>{`Guaranteed Departure ${final}`}</span></h3>
                        {/* <h3><span>{`Guaranteed Departure ${ ((moment(startDepartureDate).format('L').toString().slice(-4)))} - ${((moment(lastDepartureDate).format('L').toString().slice(-4)))}`}</span></h3> */}
                    </div> : null }
                    { details !== undefined && details[0].description !== null && details[0].description ? <div dangerouslySetInnerHTML={{__html: details[0].description}} /> : null}
                    <ul className="interest-list">
                        <li className="map-logo"><span className="icon"><FiMapPin /></span> <span>{ location.length ? location : null }</span></li>
                        <li className="romance-logo">{details !== undefined && details[0].interests.map((item,i) => {
                            return<div key={i}> <span className="icon"><img src={`${imageUrl}/${item.iconPath}`} alt={item.interest} /></span><span className='content'>{`${item.interest}`}</span></div> 
                        })}</li>
                    </ul>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <button className="btn-primary inquire-btn"
                            onClick={() => navigate(`/contact-us`, {state: [details[0]] })}
                        >Enquire Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
            <NavOption array={navbarArr} />
        <div className="container">
            <div className="tour-overview" id="Overview">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        {   details !== undefined && details[0].mdaMedia.map((item,i) => {
                            return<React.Fragment key={i}>
                                {
                                    item.mediaListing.map((data,index) => {
                                        if(data.id === 7){
                                            return<figure key={index}>
                                                <img src={`${imageUrl}/${item.mediaFilePath}`} alt={item.mediaAlText} title= {item.mediaTitle} />
                                            </figure>
                                        }
                                    })
                                }
                            </React.Fragment>
                        })
                            
                        }
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        { details !== undefined &&  details[0].briefDescription ? <div dangerouslySetInnerHTML={{__html: details[0].briefDescription}} /> : null}
                    </div>
                </div>
            </div>
            <section id= "Itinerary">
                <div className="datewise-details">
                    <div className="row">
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between">
                                <h2>Itinerary <strong>(Day Wise)</strong></h2>
                                <span onClick={() => handleCollapse("all")}>Expand All</span>
                            </div>
                        </div>
                        <div className="offset-5"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            {
                                itineraries !== undefined && itineraries.map((item,i) =>{
                                    return<div className="accordion" key={i}>
                                    <div className="card">
                                        <div className={`card-header`} onClick={() => handleCollapse(i)}>
                                            <div className={`heading ${collapse === i || open === i ? "" : "diactive"}`}>
                                                <span>{`Day ${item.itineraryDay}`}</span>
                                                <div className="heading-title">
                                                    <FaMapMarkerAlt className="map-logo" />
                                                    <strong>{item.itineraryRouting}</strong>
                                                </div>
                                            </div>
                                            <span className="after">{collapse === i || open === i ? <BiMinus /> : <FaPlus />}</span>
                                        </div>
                                        <div className={`collapse ${ collapse === i || open === i ?  'show' : '' }`}>
                                            <div className="card-body">
                                                <div dangerouslySetInnerHTML={{__html: item.itineraryInDetail}} />
                                                <span>
                                                    <BsMoon /> {item.itineraryLocation}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                })
                            }
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 Itinerary-slider" >
                        <Slider {...tourSettings} >
                            {
                                slider.length && slider.map((item,index) => {
                                    return<figure key={index}>
                                    <img src={`${imageUrl}/${item.file}`} alt= {item.name} title= {item.name} />
                                    <span>{item.name}</span>
                                </figure>
                                })
                            }
                        </Slider>
                            
                        </div>
                    </div>
                </div>
            </section>
            <section id = "Accommodations">
                { accommo !== undefined && accommo.length ? <div className="accommodations">
                    <h2>Accommodations</h2>
                    <div className="custom-card">
                        <div className='row'>
                            {
                                accommo !== undefined && accommo.map((item,i) => {
                                    const {accommodations} = item
                                    
                                    const { mdaMedia } = accommodations[0]
                                    if(mdaMedia.length){
                                        
                                        return <div className='col-md-6 col-sm-12'>
                                        <div className="map-section" key={i}>
                                        <p>{item.location}</p>
                                        {
                                            item.accommodations.map((data, j) => {
                                                return<div className="card" key={j}>
                                                {
                                                    data.mdaMedia.map((itm,index) => {
                                                        if(itm.isActive){
                                                            return <figure key={index}>
                                                            <img src={`${imageUrl}/${itm.mediaFilePath}`} alt={item.alt} />
                                                            <span>{data.accommodation}</span>
                                                        </figure>
                                                        }
                                                    })
                                                }
                                            </div>
                                            })
                                        }
                                        </div>
                                    </div>
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="note-section">
                        { details !== undefined && details[0].note ? <div dangerouslySetInnerHTML={{__html: details[0].note}} /> : null}
                    </div>
                </div> : null}
            </section>
            <section id="Inclusions">
                <div className="accommodations">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 unorder-list">
                            { details !== undefined && details[0].inclusion ? 
                                <>
                                    <p>Inclusions</p>
                                    <div dangerouslySetInnerHTML={{__html: details[0].inclusion}} />
                                </> : null
                            }
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 unorder-list exclusions">
                            { details !== undefined && details[0].exclusion ? 
                                <>
                                    <p>Exclusions</p>
                                    <div className='exclusion-child' dangerouslySetInnerHTML={{__html: details[0].exclusion}} />
                                </> : null
                            }
                            
                            {/* { details !== undefined && details[0].note ?
                                <div className="please-note" dangerouslySetInnerHTML={{__html: details[0].note}} />
                            : null } */}
                        </div>
                    </div>
                </div>
            </section>
            <section id="Optionals">
                { optionalView && itineraries.length ?
                <div className="optional-tours">    
                    <h2>View Optional Tours</h2>
                    <div className="row">
                        {
                            itineraries !== undefined && itineraries.map((item,i) =>{
                                const { optionalActivities } = item
                                // console.log("Optional image item ", item)
                                return<React.Fragment key={i}>
                                    {
                                        optionalActivities.map((data,index) => {
                                            console.log("Optional image data ", data.mdaMedia, index)
                                            
                                            return<div className="col-lg-3 col-md-3 col-sm-6" key={index}>
                                            {
                                                data.mdaMedia.length && data.mdaMedia.map((key, j) => {
                                                    return<React.Fragment key={j}>
                                                    {
                                                        key.mediaListing.map((id, k) => {
                                                            console.log("key.mediaListing.map", id)
                                                            if(id.id === 4) {
                                                            return<div className='card' key={k}>
                                                                <figure key={j}>
                                                                    <img src={`${imageUrl}/${key.mediaFilePath}`} alt={key.alt} />
                                                                </figure>
                                                                <figcaption>
                                                                    <p>{`Available On ${item.itineraryDay} Day`}</p>
                                                                    <strong>{data.activity}</strong>
                                                                </figcaption>
                                                            </div>
                                                            }
                                                        })
                                                    }
                                                    </React.Fragment>
                                                })
                                            }
                                        </div>
                                        })
                                    }
                                </React.Fragment>
                            })
                        }
                    </div>
                </div> : null}
            </section>
            <section id="Tour info">
                {tourinfo !== null && tourinfo.length ?
                <div className="tour-info">
                    <h2>Tour Info</h2>
                    <div className="row">
                        {
                            tourinfo.length && tourinfo.map((item,i) =>{
                                return<div className="accordion" key={i}>
                                <div className="card">
                                    <div className="card-header" onClick={() => handletourinfoCollapse(i)}>
                                        <strong >{item.label}</strong>
                                        <span className="after">{tourCollapse === i ? <BiMinus /> : <FaPlus />}</span>
                                    </div>
                                    <div className={`collapse ${ tourCollapse === i ? 'show' : '' }`}>
                                        <div className="card-body">
                                            <div dangerouslySetInnerHTML={{__html: item.InformationValue}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            })
                        }
                    </div>
                </div> : ""
                }
            </section>
        </div>
        {!loader ? <PageLaoder /> : null}    
    </div>;
}

export default InterestDestinationDetails;
