import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../shared/navbar';
import Error404 from '../../assets/icons/error404.png'

const NotFound = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            <Navbar bgcolor={true} />
            <div className="wrapper thankyou-page">
                <ul className="breadcrumb">
                    <li><Link to={'/'}>Home</Link></li>
                    <li className="active">Error 404</li>
                </ul>
                <section className="container">
                    <div className="thankyou text-center">
                        <div className="thankyou-logo">
                            <img src={Error404} alt="Error" />
                            <h1>404</h1>
                            <h5>Sorry. this page can't found.</h5>
                            <span>Following are few links you may want to check</span>
                        </div>
                        <div className="thankyou-container">
                            <div className="row">
                                <div className="col-lg-2 col-md-4 col-sm-12"><button className="btn-outline"><a>Home</a></button></div>
                                <div className="col-lg-2 col-md-4 col-sm-12"><button className="btn-outline"><a>Explore Interest</a></button></div>
                                <div className="col-lg-2 col-md-4 col-sm-12"><button className="btn-outline"><a>Discover Destinations</a></button></div>
                                <div className="col-lg-2 col-md-4 col-sm-12"><button className="btn-outline"><a>Exceptional Experiences</a></button></div>
                                <div className="col-lg-2 col-md-4 col-sm-12"><button className="btn-outline"><a>About LE</a></button></div>
                                <div className="col-lg-2 col-md-4 col-sm-12"><button className="btn-outline"><a>Connect With US</a></button></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    )
}


export default NotFound;