import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import Sport_banner from "../../assets/images/Sport_banner.jpg"
import Cricket_1 from "../../assets/images/Cricket_1.jpg"
import Cricket from "../../assets/images/Cricket.jpg"
import F1_1 from "../../assets/images/F1_1.jpg"
import F1 from "../../assets/images/F1.jpg"
import Fifa_1 from "../../assets/images/Fifa_1.jpg"
import Fifa from "../../assets/images/Fifa.jpg"
import Golf_1 from "../../assets/images/Golf_1.jpg"
import Golf from "../../assets/images/Golf.jpg"

import Icon_Cricket from "../../assets/icons/Icon_Cricket.png"
import Icon_F1 from "../../assets/icons/Icon_F1.png"
import Icon_Football from "../../assets/icons/Icon_Football.png"
import Icon_SportBrawon from "../../assets/icons/Icon_SportBrawon.png"


import HeroInterest from '../atom/HeroInterest';


const banner = [
    { id: 1 , image: Sport_banner, logo: Icon_SportBrawon, alt:"Sport_banner", title:"Sport"}
]

const Icons = [
    { id: 3 , src: Icon_Football, title: "Football", url:"/interest/fifa/details"},
    { id: 1 , src: Icon_Cricket, title: "Cricket", url:"/interest/cricket/details"},
    { id: 2 , src: Icon_F1, title: "F1", url:"/interest/f1/details"},
    { id: 4 , src: Icon_SportBrawon, title: "Golf", url:"/interest/golf/details"},
]

let content =`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
            standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled 
            it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
            typesetting, remaining essentially unchanged Lorem Ipsum is simply dummy text of the printing and typesetting 
            industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
            took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
            but also the leap into electronic typesetting, remaining essentially unchanged..`

const PageContent = [
    { id: 1 , src: Fifa, img: Fifa_1, title: "Fifa", paragraph: content, url:"/interest/fifa/details"},
    { id: 2 , src: Cricket, img: Cricket_1, title: "Cricket", paragraph: content, url:"/interest/cricket/details"},
    { id: 3 , src: F1, img: F1_1, title: "F1", paragraph: content, url:"/interest/f1/details"},
    { id: 4 , src: Golf, img: Golf_1, title: "Golf", paragraph: content, url:"/interest/golf/details"}
]

const Sports = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <div className="interest-sport">
        <OwlCarousel 
            items={1}
            loop = {false}
            margin= {10}
            animateOut= {true}
            autoplay = {true}
        > 
        {
            
            banner !== undefined && banner.map((item, index) => {
                return (<React.Fragment key={index}>
                    {
                        <figure>
                            <img className="desktop" src={`${item.image}`} alt={item.alt} title={item.title} />
                            <figcaption>
                            </figcaption>
                        </figure>
                    }
                </React.Fragment>
                )
            })
        }
        </OwlCarousel>
         {/* <HeroInterest
            items={1}
            loop = {true}
            margin= {10}
            animateOut= {'fadeOut'}
            outoplay = {true}
            autoplayHoverPause= {true}
            speed = {5000}
            responsiveClass = {true}
            banner={banner}
        /> */}
        
        <div className="sport-section">
            <ul className="bread-crumb">
                <li><Link to="/">Home</Link></li>
                <li className="active">About Company & Founder</li>
            </ul>
            <div className="container">
                <h1>Sports</h1>
                <div className="inte-spacer">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled 
                        it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic 
                        typesetting, remaining essentially unchanged Lorem Ipsum is simply dummy text of the printing and typesetting 
                        industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining essentially unchanged..
                    </p>
                    <div className="row explore-int">
                        {
                            Icons.length && Icons.map(item =>{
                                return (
                                    <div className="card int-card" key={item.id}>
                                        <Link to={item.url}>
                                            <img src={item.src} />
                                            <span>{item.title}</span>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    {
                        PageContent.length && PageContent.map((item, index) => {
                            if(index % 2 === 0 ){
                                return <div className="left-image" key={item.id}>
                                    <div className="row">
                                        <div className="col-lg-4 col-lg-4 col-sm-4 left-img">
                                            <figure>
                                                <img src={item.src} alt={item.title} title={item.title} />
                                            </figure>
                                        </div>
                                        <div className="col-lg-8 col-sm-8">
                                            <div className="inner-spacer">
                                                <div className="info-spacer">
                                                    <h2>{item.title}</h2>
                                                    <p>{item.paragraph}</p>
                                                    <button className="btn-primary"><Link to={item.url}>Know More</Link></button>
                                                
                                                </div>
                                                <figure className="bottom-img">
                                                    <img src={item.img} alt={item.title} title={item.title} />
                                                </figure>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            }else{
                                return <div className="right-image" key={item.id}>
                                    <div className="row">
                                        <div className="col-lg-8 col-sm-8">
                                            <div className="inner-spacer">
                                                <div className="info-spacer">
                                                    <h2>{item.title}</h2>
                                                    <p>{item.paragraph}</p>
                                                    <button className="btn-primary"><Link to="/contact">Know More</Link></button>
                                                    <figure className="bottom-img">
                                                        <img src={item.img} alt={item.title} title={item.title} />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 right-img">
                                            <figure>
                                                <img src={item.src} alt={item.title} title={item.title} />
                                            </figure>
                                        </div>
                                    </div>  
                                </div>
                            }
                        })
                    }

                    {/* <div className="row">
                        <div className="col-lg-5 col-sm-5 positionimg">
                            <figure>
                                <img src={F1} alt="Fifa" title="Fifa" />
                            </figure>
                        </div>
                        <div className="col-lg-7 col-sm-7">
                            <div className="inner-spacer">
                                <h2>F1</h2>
                                <div className="info-spacer">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting 
                                        industry. Lorem Ipsum has been the industry's standard dummy
                                        text ever since the 1500s, when an unknown printer took a 
                                        galley of type and scrambled it to make a type specimen book. 
                                        It has survived not only five centuries, but also the leap into 
                                        electronic typesetting, remaining essentially unchanged. 
                                    </p>
                                    <button className="btn-primary"><Link to="/contact">Know More</Link></button>
                                    <figure className="bottom-img">
                                        <img src={F1_1} alt="Fifa" title="Fifa" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className="row">
                        <div className="col-lg-7 col-sm-7">
                            <div className="inner-spacer">
                                <h2>Golf</h2>
                                <div className="info-spacer">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting 
                                        industry. Lorem Ipsum has been the industry's standard dummy
                                        text ever since the 1500s, when an unknown printer took a 
                                        galley of type and scrambled it to make a type specimen book. 
                                        It has survived not only five centuries, but also the leap into 
                                        electronic typesetting, remaining essentially unchanged. 
                                    </p>
                                    <button className="btn-primary"><Link to="/contact">Know More</Link></button>
                                    <figure className="bottom-img">
                                        <img src={Golf_1} alt="Fifa" title="Fifa" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-5 positionimg">
                            <figure>
                                <img src={Golf} alt="Fifa" title="Fifa" />
                            </figure>
                        </div>
                    </div>   */}
                </div>
            </section>
        </div>
    </div>;
}

export default Sports;