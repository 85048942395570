import { FETCH_AUTH_DATA, FETCH_CONTACTUS_DATA, FETCH_ENQUERY_DATA, 
    FETCH_EXPE_ENQUERY_DATA, FETCH_SUBSCRIBE_DATA } from '../types/authType'


export const authAction = (payload) => ({
    type: FETCH_AUTH_DATA,
    payload
})


export const enqueryAction = (payload) => ({
    type: FETCH_ENQUERY_DATA,
    payload
})


export const subscribeAction = (payload) => ({
    type: FETCH_SUBSCRIBE_DATA,
    payload
})


export const contactusFormAction = (payload) => ({
    type: FETCH_CONTACTUS_DATA,
    payload
})


export const expFormAction = (payload) => ({
    type: FETCH_EXPE_ENQUERY_DATA,
    payload
})