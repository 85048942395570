import React,{useState, useEffect} from 'react';
import { Nav } from "react-bootstrap"
import { Link } from "react-scroll"




const NavOption = (props) => {

    const [click, setClick] = useState(true)

    const closeMenu = () => setClick(false)

    return <div className="container-fluid bg-light sticky-top">
        <div className="container">
            <Nav className="tab-options">
                {
                    props.array.length && props.array.map((item,i) => {
                    return <Nav.Item key={i} > 
                            <Link 
                                activeClass="tab-list active"
                                to={`${item}`} 
                                smooth={true} 
                                spy={true} 
                                offset={-150} 
                                duration={500}
                            >{item}</Link>
                        </Nav.Item>
                    })
                }
            </Nav>
        </div>
    </div>
}

export default NavOption;