import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/logo.png'
import MenuBar from './menubar';
import { FaEnvelope,FaLinkedinIn,FaInstagram,FaFacebookF,FaYoutube } from "react-icons/fa";
import { authAction } from '../redux/actions/AuthAction';
import { organizationAction } from '../redux/actions/homeAction';

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";

const Navbar = ({bgcolor}) => {
    let dispatch = useDispatch();

    const auth = useSelector((state) => state.home.auth);
    const organiz = useSelector((state) => state.home.organization);

    const [show, setShow] = useState(false)
    const [bgclr] = useState( bgcolor || false )
    const [organization, setOrganization] = useState(organiz)

    useEffect(() => {
        let header = document.querySelector('header');
        let img = document.querySelector('img');
        window.addEventListener('scroll', () => {
            if(window.pageYOffset > 50){
                header.classList.add('fixed-header', 'shadow')
                header.style.backgroundColor = "#440000";
                img.style.height = 50;
                img.style.transition = "1s";
            }else{
                header.classList.remove('fixed-header', 'shadow')
                header.style.backgroundColor = "transparent";
                img.style.height = "";
            }
        })
    },[])

    useEffect(() => {
        dispatch(organizationAction()) 
    },[]);

    useEffect(() => {
        if(organiz !== undefined){
            setOrganization(organiz)
        }
    }, [organiz])


    return (
        <React.Fragment>
            <header className="dark">
                <nav className={`navbar navbar-expand-lg navbar-light ${bgclr ? "navColor" : ""}`}>
                    <div className="container">
                    {   organization.companyLogoFilePath ? <>
                            <div className="logo_sec">
                                <Link className="navbar-brand" to="/">
                                    <img className='brand_logo' src={`${imageUrl}/${organization.companyLogoFilePath}`} alt={organization.company} title={organization.company} onClick={() => setShow(false)} />
                                </Link> 
                            
                            <button 
                                onClick={() => setShow(!show)}
                                className={`navbar-toggler toggle-overlay ${show ? "open-toggler" : ""}`}
                                type="button" data-bs-toggle="collapse" 
                                data-bs-target="#navbarNavDropdown"
                                aria-controls="navbarNavDropdown" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation">
                                <span>
                                    <span className="line"></span>
                                    <span className="m-line"></span>
                                    <span className="line"></span>
                                </span>
                                <span className="title">{show ? "Close" : "Menu"}</span>
                            </button>
                        </div>
                        
                        <ul className="social-list">
                            { organization.offices[0].officeEmail ? <li>
                                <a href={`mailto:${organization.offices[0].officeEmail}`} title="Mail"><FaEnvelope /></a>
                            </li> : null }
                            { organization.companyFacebookUrl ? <li>
                                <a href={organization.companyFacebookUrl} target="_blank" title="Facebook"><FaFacebookF /></a>
                            </li> : null }
                            { organization.companyInstagramUrl ? <li>
                                <a href={organization.companyInstagramUrl} target="_blank" title="Instagram"><FaInstagram /></a>
                            </li> : null}
                            { organization.companyLinkedinUrl ? <li>
                                <a href={organization.companyLinkedinUrl} target="_blank" title="Linkedin"><FaLinkedinIn /></a>
                            </li> : null }
                            { organization.companyYoutubeUrl ? <li>
                                <a href={organization.companyYoutubeUrl} target="_blank" title="Youtube"><FaYoutube /></a>
                            </li> : null }
                        </ul> </> : null
                        }
                    </div>
                </nav>
            </header>
            {
                show ? <MenuBar onClick={() => setShow(!show)} data= {organization} /> : null
            }
        </React.Fragment>
    )
}
export default Navbar;