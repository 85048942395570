import React,{ useEffect,useState } from 'react';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import '../../assets/css/animate.css'
let imageUrl = process.env.REACT_APP_IMAGE_URL;



const ToursBanner = (props) => {

    const [banner, setBanner] = useState(props.banner)

    useEffect(() => {
        if(props.banner){
            setBanner(props.banner)
        }
    }, [props.banner])

    // console.log("Banner dAta", props.banner)

    return (
        <div className="hero_banner">
            <OwlCarousel 
                items={props.items}
                loop = {props.loop}
                margin= {props.margin}
                animateOut= {props.animateOut}
                autoplay = {props.outoplay}
                autoplayHoverPause= {props.autoplayHoverPause}
                autoplaySpeed = {props.speed}
                responsiveClass = {props.responsiveClass}
            > 
                {
                    banner !== undefined && banner.map((item, index) => {
                        const [ bannerId ] = item.mediaListing
                        if( bannerId.id === 9 || bannerId.id === 11 || bannerId.id === 5){
                            return (<div className="item" key={index}>
                                <figure>
                                    <img className="desktop" src={`${imageUrl}/images/${item.mediaFilePath}`} alt={item.bannerImageAlt} title={item.bannerImageTitle} />
                                    <img className="tablet" src={`${imageUrl}/images/${item.mediaTabletFilePath}`} alt={item.bannerImageAlt} title={item.bannerImageTitle} />
                                    <img className="mobile" src={`${imageUrl}/images/${item.mediaMobileFilePath}`} alt={item.bannerImageAlt} title={item.bannerImageTitle} /> 
                                </figure>
                            </div>)
                        }
                    })
                }
            </OwlCarousel>
        </div>
    );
}


export default ToursBanner;