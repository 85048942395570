import {
    FETCH_POST_RESPONSE,POST_FAILED
} from "../types/postType";

const initialState = {
    posts: [],
}

export default function reducer(state = initialState, action) {
    let payload = action.payload;
    switch (action.type) {
        case FETCH_POST_RESPONSE:
            console.log("Post reducers ",payload)
            return { ...state, posts: payload }
        case POST_FAILED:
            return { ...state, posts: payload }
        default:
            return state
    }

}