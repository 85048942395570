import {
    FETCH_HOME_RESPONSE,HOME_DATA_FAILED,
    FETCH_INTEREST_RESPONSE,INTEREST_DATA_FAILED,
    FETCH_DESTINATION_RESPONSE,DESTINATION_DATA_FAILED,
    FETCH_BOOK_NEXT_RESPONSE,BOOK_NEXT_DATA_FAILED,
    FETCH_ACTIVITIES_RESPONSE,ACTIVITIES_DATA_FAILED, 
    FETCH_TOURS_RESPONSE, TOURS_DATA_FAILED, FETCH_ACCOMMODATIONS_RESPONSE,
    ACCOMMODATIONS_DATA_FAILED, FETCH_EXPLORE_BANNER_RESPONSE, 
    EXPLORE_BANNER_DATA_FAILED, FETCH_DESTINATION_BANNER_RESPONSE, 
    DESTINATION_BANNER_DATA_FAILED, FETCH_ORGANIZATION_RESPONSE, 
    ORGANIZATION_DATA_FAILED, ABOUTUS_RESPONSE_BANNER, 
    ABOUTUS_BANNER_FAILED, ENQUERY_RESPONSE_BANNER, ENQUERY_BANNER_FAILED, 
    CONTACTUS_RESPONSE_BANNER, CONTACTUS_BANNER_FAILED, COUNTRIES_RESPONSE_DATA, 
    COUNTRIES_DATA_FAILED, SALUTATION_DATA_FAILED, SALUTATION_RESPONSE_DATA, 
    TENTATIVEBUDGET_RESPONSE_DATA, TENTATIVEBUDGET_DATA_FAILED, 
    HOLIDAYDURATION_RESPONSE_DATA, HOLIDAYDURATION_DATA_FAILED, 
    VACCINATION_RESPONSE_DATA, VACCINATION_DATA_FAILED, ENQUIRSOURCE_RESPONSE_DATA, 
    ENQUIRSOURCE_DATA_FAILED, TESTIMONIALS_RESPONSE_DATA, TESTIMONIALS_DATA_FAILED, 
    TESTIMONIALS_RESPONSE_BANNER, TESTIMONIALS_BANNER_FAILED, FEATURED_TOURS_RESPONSE, 
    FEATURED_TOURS_FAILED, ENQUIRY_ACTIVITY_FAILED, ENQUIRY_ACTIVITY_RESPONSE, 
    ENQUIRY_TOURS_RESPONSE, ENQUIRY_TOURS_FAILED, EXCEPTIONAL_EXPERIENCE_RESPONSE, EXCEPTIONAL_EXPERIENCE_FAILED, EXPERIENCES_DATA_RESPONSE, EXPERIENCES_DATA_FAILED
} from "../types/homeType";
import { CONTACTUS_DATA_FAILED, EXPE_ENQUERY_DATA_FAILED, FETCH_AUTH_RESPONSE, FETCH_CONTACTUS_RESPONSE, 
    FETCH_EXPE_ENQUERY_RESPONSE, 
    FETCH_SUBSCRIBE_RESPONSE, SUBSCRIBE_DATA_FAILED } from "../types/authType"

const initialState = {
    auth: false,
    homepage: [],
    interest: [],
    destination: [],
    bookNext: [],
    activities: [],
    tours: [],
    acommodations: [],
    explorBanner: [],
    destinationBanner: [],
    organization: [],
    subscribe: [],
    contactBanner: [],
    enquiryBanner: [],
    aboutBanner: [],
    contactus: [],
    countries: [],
    salutation: [],
    budget: [],
    holidayduration: [],
    vaccination: [],
    sourcers: [],
    testimonials: [],
    testibanner: [],
    featureTours: [],
    enquiryTours: [],
    featureActiviti: [],
    exceptionalExp: [],
    exceptionals: [],
    excEnquiery: [],
}

export default function reducer(state = initialState, action) {
    let payload = action.payload;
    switch (action.type) {
        case FETCH_AUTH_RESPONSE:
            return { ...state, auth: payload.token }
        case FETCH_HOME_RESPONSE:
            return { ...state, homepage: payload.data }
        case HOME_DATA_FAILED:
            return { ...state, homepage: payload, auth: false }
        case FETCH_INTEREST_RESPONSE:
            return { ...state, interest: payload.data }
        case INTEREST_DATA_FAILED:
            return { ...state, interest: payload, auth: false }
        case FETCH_DESTINATION_RESPONSE:
            return { ...state, destination: payload.data }
        case DESTINATION_DATA_FAILED:
            return { ...state, destination: payload, auth: false }
        case FETCH_BOOK_NEXT_RESPONSE:
            return { ...state, bookNext: payload.data }
        case BOOK_NEXT_DATA_FAILED:
            return { ...state, bookNext: payload, auth: false }
        case FETCH_ACTIVITIES_RESPONSE:
            return { ...state, activities: payload.data }
        case ACTIVITIES_DATA_FAILED:
            return { ...state, activities: payload, auth: false }
        case FETCH_TOURS_RESPONSE:
            return { ...state, tours: payload.data }
        case TOURS_DATA_FAILED:
            return { ...state, tours: payload, auth: false }
        case FETCH_ACCOMMODATIONS_RESPONSE:
            return { ...state, acommodations: payload.data }
        case ACCOMMODATIONS_DATA_FAILED:
            return { ...state, acommodations: payload, auth: false }
        case FETCH_EXPLORE_BANNER_RESPONSE:
            return { ...state, explorBanner: payload.data }
        case EXPLORE_BANNER_DATA_FAILED:
            return { ...state, explorBanner: payload, auth: false }
        case FETCH_DESTINATION_BANNER_RESPONSE:
            return { ...state, destinationBanner: payload.data }
        case DESTINATION_BANNER_DATA_FAILED:
            return { ...state, destinationBanner: payload, auth: false }
        case FETCH_ORGANIZATION_RESPONSE:
            return { ...state, organization: payload.data }
        case ORGANIZATION_DATA_FAILED:
            return { ...state, organization: payload, auth: false }
        case FETCH_SUBSCRIBE_RESPONSE:
            return { ...state, subscribe: payload.data }
        case SUBSCRIBE_DATA_FAILED:
            return { ...state, subscribe: payload, auth: false }
        case ABOUTUS_RESPONSE_BANNER:
            return { ...state, aboutBanner: payload.data }
        case ABOUTUS_BANNER_FAILED:
            return { ...state, aboutBanner: payload, auth: false }
        case ENQUERY_RESPONSE_BANNER:
            return { ...state, enquiryBanner: payload.data }
        case ENQUERY_BANNER_FAILED:
            return { ...state, enquiryBanner: payload, auth: false }
        case CONTACTUS_RESPONSE_BANNER:
            return { ...state, contactBanner: payload.data }
        case CONTACTUS_BANNER_FAILED:
            return { ...state, contactBanner: payload, auth: false }
        case FETCH_CONTACTUS_RESPONSE:
            return { ...state, contactus: payload.data }
        case CONTACTUS_DATA_FAILED:
            return { ...state, contactus: payload, auth: false }
        case COUNTRIES_RESPONSE_DATA:
            return { ...state, countries: payload.data }
        case COUNTRIES_DATA_FAILED:
            return { ...state, countries: payload, auth: false }
        case SALUTATION_RESPONSE_DATA:
            return { ...state, salutation: payload.data }
        case SALUTATION_DATA_FAILED:
            return { ...state, salutation: payload, auth: false }
        case TENTATIVEBUDGET_RESPONSE_DATA:
            return { ...state, budget: payload.data }
        case TENTATIVEBUDGET_DATA_FAILED:
            return { ...state, budget: payload, auth: false }
        case HOLIDAYDURATION_RESPONSE_DATA:
            return { ...state, holidayduration: payload.data }
        case HOLIDAYDURATION_DATA_FAILED:
            return { ...state, holidayduration: payload, auth: false }
        case VACCINATION_RESPONSE_DATA:
            return { ...state, vaccination: payload.data }
        case VACCINATION_DATA_FAILED:
            return { ...state, vaccination: payload, auth: false }
        case ENQUIRSOURCE_RESPONSE_DATA:
            return { ...state, sourcers: payload.data }
        case ENQUIRSOURCE_DATA_FAILED:
            return { ...state, sourcers: payload, auth: false }
        case TESTIMONIALS_RESPONSE_DATA:
            return { ...state, testimonials: payload.data }
        case TESTIMONIALS_DATA_FAILED:
            return { ...state, testimonials: payload, auth: false }
        case TESTIMONIALS_RESPONSE_BANNER:
            return { ...state, testibanner: payload.data }
        case TESTIMONIALS_BANNER_FAILED:
            return { ...state, testibanner: payload, auth: false }
        case FEATURED_TOURS_RESPONSE:
            return { ...state, featureTours: payload.data }
        case FEATURED_TOURS_FAILED:
            return { ...state, featureTours: payload, auth: false }
        case ENQUIRY_ACTIVITY_RESPONSE:
            return { ...state, featureActiviti: payload.data }
        case ENQUIRY_ACTIVITY_FAILED:
            return { ...state, featureActiviti: payload, auth: false }
        case ENQUIRY_TOURS_RESPONSE:
            return { ...state, enquiryTours: payload.data }
        case ENQUIRY_TOURS_FAILED:
            return { ...state, enquiryTours: payload, auth: false }
        case EXCEPTIONAL_EXPERIENCE_RESPONSE:
            return { ...state, exceptionalExp: payload.data }
        case EXCEPTIONAL_EXPERIENCE_FAILED:
            return { ...state, exceptionalExp: payload, auth: false }
        case EXPERIENCES_DATA_RESPONSE:
            return { ...state, exceptionals: payload.data }
        case EXPERIENCES_DATA_FAILED:
            return { ...state, exceptionals: payload, auth: false }
        case FETCH_EXPE_ENQUERY_RESPONSE:
            return { ...state, excEnquiery: payload.data }
        case EXPE_ENQUERY_DATA_FAILED:
            return { ...state, excEnquiery: payload, auth: false }
        default:
            return state
    }
}