import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ExploreInterest from '../atom/ExploreInteres';
import { authAction, contactusFormAction, expFormAction } from '../../redux/actions/AuthAction';
import { activityEnquiryAction, contactusAction, sourceAction } from '../../redux/actions/homeAction';
import PageLaoder from '../atom/loader/pageLaoder';
import { alphabatesWithSpace, fetchWithWait, isValidMobileNo, numberText } from '../../helper/method';
import { Helmet } from 'react-helmet';

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";

const ExpEnquiry = () => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let hasError = false;
    
    const stdCode= [
        {label: "+91", value: "101"},
        // {label: "+19", value: "+19"},
        // {label: "+12", value: "+12"},
        // {label: "+022", value: "+022"}
    ]

    const auth = useSelector((state) => state.home.auth);
    const bannerData = useSelector((state) => state.home.contactBanner.banner);
    const sourcers = useSelector((state) => state.home.sourcers);
    const featureActiviti = useSelector((state) => state.home.featureActiviti);

    const [activityId] = useState( location.state ? location.state : 0 )
    const [sStdCode, setStdCode] = useState("101")
    const [datas, setDatas] = useState(location.state)
    const [fname, setName] = useState("")
    const [lname, setLName] = useState("")
    const [email, setEmail] = useState("")
    const [mobileno, setMobileno] = useState("")
    const [yourmessage, setYourMessage] = useState("")
    
    const [errors, setErrors] = useState({
            errName: "",
            errLName: "",
            errStdCode: "",
            errEmail: "",
            errMobileno: ""
        })


    const [banner, setBanner] = useState()
    const [pageTitle, setPageTitle] = useState("")
    const [pageContent, setPageContent] = useState([])
    const [loading, setLoading] = useState(false)
    const [source, setSource] = useState()
    const [metaTitle, setMetaTitle] = useState("")
    const [metaKeyword, setMetaKeyword] = useState("")
    const [metaDescription, setMetaDescription] = useState("")
    const [canonicalURl, setCanonicalURl] = useState("")
    const [focusKeyPhrase, setFocusKeyPhrase] = useState("")
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState("")
    const [schema, setSchema] = useState("")
    const [socialDescription, setSocialDescription] = useState("")
    const [socialIcon, setSocialIcon] = useState("")
    const [socialIconPath, setSocialIconPath] = useState("")
    const [socialTitle, setSocialTitle] = useState("")
    const [socialType, setSocialType] = useState("")


    useEffect(() => {
        dispatch(contactusAction())
        dispatch(sourceAction())
        dispatch(activityEnquiryAction())
    }, [])


    useEffect(() => {
        if(featureActiviti !== undefined){
            setBanner(featureActiviti.banner)
            setPageTitle(featureActiviti.pageTitle)
            setPageContent(featureActiviti.pageContent)

            if(featureActiviti.hasOwnProperty("seoContent") && featureActiviti.seoContent !== null){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = featureActiviti.seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
        }
    }, [featureActiviti])

    useEffect(() => {
        if(Array.isArray(sourcers) && sourcers.length){
            setSource(sourcers[0].value)
        }
    }, [sourcers]);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const handleStateChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name === "fname"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setName(check)
                handleError(e)
            }
        }
        if(name === "lname"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setLName(check)
                handleError(e)
            }
        }
        if(name === "stdCode"){
            setStdCode(value)
            handleError(e)
        }
        if(name === "yourmessage"){
            setYourMessage(value)
        }
        if(name === "email"){
            setEmail(value)
            handleError(e)
        }
        if(name === "mobileno"){
            let check = value
            if(numberText(check)){
                setMobileno(check)
                handleError(e)
            }
        }
    }

    const handleError = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let allState = errors;

        if(name === "fname"){
            if(!value.length){
                allState["errName"] = "Enter your First name"
            }else if(value.length < 3){
                allState["errName"] = "First Name should be 3 charector"
            }else{
                allState["errName"] = ""
            }
        }
        if(name === "lname"){
            if(!value.length){
                allState["errLName"] = "Enter your Last name"
            }else if(value.length < 3){
                allState["errLName"] = "Last Name should be 3 charector"
            }else{
                allState["errLName"] = ""
            }
        }
        if(name === "stdCode"){
            if(!value.length){
                allState["errStdCode"] = "Select Code"
            }else{
                allState["errStdCode"] = ""
            }
        }
        if(name === "email"){
            let mailformat =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!value.length){
                allState["errEmail"] = "Enter Email ID"
            }else if(!value.match(mailformat)){
                allState["errEmail"] = "Enter a valid email address"
            }else{
                allState["errEmail"] = ""
            }
        }
        if(name === "mobileno"){
            if(!value.length){
                allState["errMobileno"] = "Enter Mobile number"
            }else if(value){
                if(!isValidMobileNo(value)){
                    allState["errMobileno"] = "Invalid Mobile No."
                }else{
                    allState["errMobileno"] = ""
                }
            }
        }
        setErrors(prevError => ({
			...prevError,
			...allState
		}))
    }

    const checkVailidation = () =>{
        hasError = false;
        let allState = errors;
        let mobile = mobileno.toString()
        
        if(!email.length){
            let mailformat =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!email.length){
                allState["errEmail"] = "Enter Email ID"
                hasError = true
            }else if(!email.match(mailformat)){
                allState["errEmail"] = "Enter a valid email address"
                hasError = true
            }
        }
        if(!fname.length){
            allState["errName"] = "Enter your first name"
            hasError = true
        }else if(fname.length < 3){
            allState["errName"] = "First Name should be 3 charector"
            hasError = true
        }
        if(!lname.length){
            allState["errLName"] = "Enter your last name"
            hasError = true
        }else if(lname.length < 3){
            allState["errLName"] = "Last Name should be 3 charector"
            hasError = true
        }
        if(!sStdCode.length){
            allState["errStdCode"] = "Select Code"
            hasError = true
        }
        if(!mobile.length){
            allState["errMobileno"] = "Enter Mobile number"
            hasError = true
        }else if( mobile.length ){
            if(!isValidMobileNo(mobileno)){
                allState["errMobileno"] = "Invalid Mobile No."
            }else{
                allState["errMobileno"] = ""
            }
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))

        return hasError
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        if(!checkVailidation()){
        setLoading(true)
            const data = {
                firstName : fname,
                lastName : lname,
                email : email,
                phoneCode : sStdCode,
                mobile : mobileno,
                message: yourmessage,
                prdExperience: activityId,
                source: source
            }
            fetchWithWait({ dispatch, action: expFormAction(data) }).then((res) => {
                if(res.message == "success"){
                    setLoading(false)
                    navigate("/thank-youexp", {state: "enquiry"})
                }
            }).catch((e) => {
                setLoading(false)
                console.log(`error`, e)
            })
        }
    }


    return (
        <div className='Activity-Enquiry'>

            <Helmet>
                <title>{ metaTitle !== null ? metaTitle : "Experience Enquiry" }</title>
                <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
                <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
                <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
                <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
                <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
                <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:type" content={ socialType !== null ? socialType : null } />
            </Helmet>

            { banner !== undefined ?
                <ExploreInterest
                    items={1}
                    loop = {false}
                    margin= {10}
                    animateOut= {'fadeOut'}
                    outoplay = {true}
                    autoplayHoverPause= {true}
                    speed = {5000}
                    responsiveClass = {true}
                    banner={banner}
            /> : <PageLaoder />}
            <div className="wrapper">
                <section className="container">
                    <div className="contact-form">
                        <ul className="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">Exceptional Enquiry Form</li>
                        </ul>
                        <div className="enquire-box">
                         <h2>Exceptional Enquiry Form</h2>
                        { pageContent !== undefined && pageContent.length ? <div dangerouslySetInnerHTML={{__html: pageContent[0].content}} /> : null }
                        {   datas !== null ? 
                            <div className="enquiry-section">
                            {datas.length && datas[0].activity ? <p>{datas[0].activity}</p> : 
                            <>{datas.length && datas[0].tour ? <p>{datas[0].tour}</p> : null }</> 
                            }
                            { datas.length && datas[0].mstCountry ? <span>{datas[0].mstCountry}</span> : 
                                <>{datas.length && datas[0].destination ? <span>{datas[0].destination}</span> : null }</> 
                            }
                        </div> : ""}
                        <form method="post">
                            <div className="row">
                                <div className="col-sm-6 col-lg-6">
                                    <div className="form-group">
                                        <input 
                                            className="form-control"
                                            type="text" 
                                            name="fname" 
                                            value={fname}
                                            required="required"
                                            onBlur={handleError}
                                            placeholder="Enter your first name *" 
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                        <span className="text-danger">{errors.errName}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <div className="form-group">
                                        <input 
                                            className="form-control"
                                            type="text" 
                                            name="lname" 
                                            value={lname}
                                            required="required"
                                            onBlur={handleError}
                                            placeholder="Enter your last Name *" 
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                        <span className="text-danger">{errors.errLName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-6">
                                    <div className="form-group">
                                        <input 
                                            className="form-control"
                                            type="text" 
                                            name="email" 
                                            value={email}
                                            required="required" 
                                            placeholder="Email address *" 
                                            onBlur={handleError}
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                        <span className="text-danger">{errors.errEmail}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="sr-only" htmlFor="inlineFormInputGroup">Mobile *</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text select-option">
                                                    <select className="form-select"
                                                        onChange={(e) =>handleStateChange(e)}
                                                        onBlur={(e) =>handleError(e)}
                                                        value={sStdCode}
                                                        name="stdCode"
                                                    >
                                                        <option value="+91">+91</option>
                                                        {/* {
                                                            stdCode.map((item,index) =>{
                                                                return <option key={index} value={item.value}>{item.label}</option>
                                                            })
                                                        } */}
                                                    </select>
                                                   
                                                </div>
                                            </div>
                                            <input 
                                                className="form-control"
                                                type="tel" 
                                                name="mobileno" 
                                                value={mobileno.toString()}
                                                placeholder="Enter Mobile number*" 
                                                maxLength= "10"
                                                onBlur={handleError}
                                                onChange={handleStateChange}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <span className="text-danger">{errors.errStdCode} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {errors.errMobileno}</span>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row my-4">
                                <div className="col-sm-12 col-lg-12">
                                    <div className="form-group">
                                        <textarea 
                                            className="form-control" 
                                            rows="4" 
                                            cols="50" 
                                            placeholder="Your message (Optional)" 
                                            name="yourmessage"
                                            value={yourmessage}
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="button-center">
                                <button className="btn-sec" onClick={handleSubmit}><a href="thank-you.shtml">Submit</a></button>
                            </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <>
                {loading &&
                    <PageLaoder />
                }
            </>
        </div>
    );
}

export default ExpEnquiry;