import React,{useEffect, useState} from 'react';
import { Link ,useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { experienceAction } from '../../redux/actions/homeAction';
import Navbar from '../../shared/navbar';
import PageLaoder from '../atom/loader/pageLaoder';
import { Helmet } from 'react-helmet';


let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";
let baseUrl = process.env.REACT_APP_BASE_URL;


const ExceptionalExperienceDetails = () => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const { path } = useParams();

    const pageData = useSelector((state) => state.home.exceptionals);

    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(0) 
    const [prvPath, setPrvpath] = useState("") 
    const [similarCard, setSimilarCard] = useState([]) 
    const [details, setDetails] = useState([])
    const [banner, setBanner] = useState([])
    const [name, setName] = useState("")
    const [country, setCountry] = useState("")
    const [description, setDescription] = useState("")
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      }

    useEffect(() => {
        dispatch(experienceAction())
    }, [])

    useEffect(() => {
        const token = localStorage.getItem("token")
        const fetchData = async() =>{
            const fetchApi = await fetch(`${baseUrl}experience/${path}`,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + `${token}`,
                }
            })
            const data = await fetchApi.json();
            setDetails(data.data)
            if(data.message === "success"){
                setLoading(true)
            }else{ setLoading(true) }
        }
        fetchData();
    }, [])

    useEffect(() => {
        if(details.length){
            let arr = []
            details.forEach(item => {
                const { description,mdaMedia, mstCountry, name,id } = item
                setName(name)
                setCountry(mstCountry)
                setDescription(description)
                setId(id)
                setPrvpath(path.charAt(0).toUpperCase() + path.slice(1))
                mdaMedia.forEach(data => {
                    const { mediaListing } = data
                    mediaListing.map(key => {
                        if(key.id === 16 ){
                            arr.push(data)
                        }
                    })
                })
            })
            setBanner(arr)

            if(details[0].hasOwnProperty("seoContent") && details[0].seoContent !== null){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = details[0].seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
        }
    },[details])


    useEffect(() => {
        let arr = []
        let count = 0
       if(pageData.length){
            pageData.forEach((item) => {
                if(count < 3 && item.isDisplay ){
                    if( item.id !== id ){
                        arr.push(item);
                        count ++
                    }
                }
            })
            setSimilarCard(arr)
            count = 0
       }
    }, [pageData])

    const handleSimilarView = (value) => {
        navigate(`/experiences/${value}`)
        window.location.reload()
    }
    const handleViewAll = () => {
        navigate(`/experiences/`)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <React.Fragment>

            <Helmet>
                <title>{ metaTitle !== null ? metaTitle : "Experience" }</title>
                <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
                <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
                <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
                <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
                <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
                <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:type" content={ socialType !== null ? socialType : null } />
            </Helmet>

            <Navbar bgcolor={true} />
            <div className="exceptional-exp">
                <section className="experience">
                    <div className="container">
                        <div className="row">
                            <ul className="breadcrumb">
                                <li><Link to="/">Home</Link></li> 
                                <li className="non-active"><Link to="/experiences">Exceptional Experience</Link></li>
                                <li className="active">{prvPath}</li>
                            </ul>
                        </div>

                        <div className='row'>
                        {country.length ? <p className='exp-country'>{country}</p> : "" }
                            { name.length ? <h2>{name}</h2> : "" }
                        </div>
    
                        <div className="row exp-row">
                        <div className='col-md-6 col-sm-12 expe-slider'>
                            <Slider {...settings}>
                            { 
                                banner.length && banner.map((item,index) => {
                                    const { mediaFilePath, mediaListing, isActive, mediaAlText, mediaTitle, mediaType, mediaPath } = item
                                    return<React.Fragment key={index} >
                                        {
                                            mediaListing.length && mediaListing.map((li,j) =>{
                                                if( isActive && li.id === 16 ){
                                                    return <div className='exprince-details' key={j}>
                                                    <div className='exce-img'>
                                                        <figure className={`${mediaType === "video" ? "fbdetail" : ""}`}>
                                                        { mediaType === "image" ? 
                                                            <img src={`${imageUrl}/${mediaFilePath}`} alt={mediaAlText} title= {mediaTitle} /> : 
                                                            <iframe src={`${mediaPath}`} scrolling="no" frameBorder="0" 
                                                            allow="autoplay" autoPlay={true} allowFullScreen={true}
                                                            data-allowfullscreen="true"
                                                            data-autoplay="true"
                                                            data-show-captions="true"
                                                            ></iframe>
                                                        }
                                                        </figure>
                                                    </div>
                                                </div>
                                                }
                                            })
                                        }
                                    </React.Fragment>
                                })
                            }
                            </Slider>
                        </div>

                        <div className='col-md-6 col-sm-12 exprince-details'>
                                <div className='exce-exp'>
                                    { description.length ? <div dangerouslySetInnerHTML={{__html: description}} /> : null }
                                    <button className='btn-primary'
                                    onClick={() => navigate(`/experiences-enquiry`, {state: id })}>Enquire Now</button>  
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <h2>View Other Experiences</h2>
                            {
                                similarCard.length && similarCard.map((item,index) => {
                                    const { mdaMedia,isDisplay } = item
                                    return<React.Fragment key={index}>
                                        {
                                        isDisplay ? mdaMedia.map((data, i) => {
                                            const { mediaListing, isActive,mediaPath,mediaType } = data
                                            return<React.Fragment key={i}>
                                                {
                                                    mediaListing.map((li,j) => {
                                                        if( isActive && li.id === 14 ){
                                                            return <div key={j} className='col-lg-4 col-md-6 col-sm-12 exceptional'>
                                                            <div className='card' onClick={() => handleSimilarView(item.slug)}>
                                                                <figure>
                                                                { mediaType === "image" ? 
                                                                    <img src={`${imageUrl}/${data.mediaFilePath}`} alt={data.mediaAlText} title= {data.mediaTitle} /> : 
                                                                    <iframe src={`${mediaPath}`} scrolling="no" frameBorder="0" 
                                                                    allow="autoplay" autoPlay={true} allowFullScreen={true}
                                                                    data-allowfullscreen="true"
                                                                    data-autoplay="true"
                                                                    data-show-captions="true"
                                                                    ></iframe>
                                                                }
                                                                </figure>
                                                                <figcaption>
                                                                    <p>{item.mstCountry}</p>
                                                                    <h3>{item.name}</h3>
                                                                </figcaption>
                                                            </div>
                                                            </div>
                                                        }
                                                    })
                                                }
                                            </React.Fragment>
                                        })  : null
                                        }
                                    </React.Fragment>
                                })
                            }
                        </div>
                        <div className='row'>
                            <div className='view-all'>
                                <button className='btn-primary' onClick={handleViewAll}>View All Experiences</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            { !loading ? <PageLaoder /> : null }
        </React.Fragment>
    )
}


export default ExceptionalExperienceDetails;