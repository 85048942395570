import React,{ useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  Route, Routes } from 'react-router-dom';
import Footer from '../shared/footer';
import Navbar from '../shared/navbar';
import Contact from './Pages/Contact';
import Home from './Pages/Home';
import ExploreInterest from './Pages/exploreInterest';
import InterestRomance from './Pages/InterestRomance';
import InterestRomanceTourDetails from './Pages/IntrestRomanceTourDetails';
import ThankYou from './Pages/Thankyou';
import NotFound from './Pages/NotFound';
import Enquiry from './Pages/Enquiry';
import Comments from './Pages/Comments';
import Aboutus from './Pages/Aboutus';
import DiscoverDestinations from './Pages/DiscoverDestinations';
import Sports from './Pages/Sports';
import DestinationsList from './Pages/DestinationsList';
import InterestSportsifaDetails from './Pages/InterestSportsifaDetails';
import InterestDestinationDetails from './Pages/InterestDestinationDetails';
import { organizationAction } from '../redux/actions/homeAction';
// import TermsAndConditions from './Pages/TermsAndConditions';
import { PrivateRoute } from './hoc/PrivateRoute';
import ExceptionalExperience from './Pages/ExceptionalExperience';
import ExceptionalExperienceDetails from './Pages/ExceptionalExperienceDetails';
import ExpEnquiry from './Pages/ExpEnquiry';
import ThankyouExp from './Pages/ThankyouExp';
import InstagramPosts from './Pages/Instagram';

const Router = () => {

  let dispatch = useDispatch();

  const organiz = useSelector((state) => state.home.organization);

  const [organization, setOrganization] = useState(organiz)

  useEffect(() => {
      dispatch(organizationAction()) 
	},[]);

  useEffect(() => {
    if(organiz !== undefined){
      setOrganization(organiz)
    }
  }, [organiz])

  return (
    <React.Fragment>
      <PrivateRoute>
        <Navbar />
      </PrivateRoute>

        <Routes>
          <Route element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>} path="/" exact />

          <Route element={
            <PrivateRoute>
              <Contact />
            </PrivateRoute>} path="/contact-us" exact />

          <Route element={
            <PrivateRoute>
              <Enquiry />
            </PrivateRoute> } path="/activity-enquiry" exact />

          <Route element={
            <PrivateRoute>
              <ExploreInterest />
            </PrivateRoute> } path={`/interest/`} exact />

          <Route element={
            <PrivateRoute>
              <InterestRomance />
            </PrivateRoute>
          } path="/interest/:path" exact />

          <Route element={
            <PrivateRoute>
              <Comments />
            </PrivateRoute>} path="/comments" exact />

          <Route element={
            <PrivateRoute>
              <InterestRomanceTourDetails />
            </PrivateRoute>} path="/interest/:prvpath/:path" exact />

          <Route element={
            <PrivateRoute>
              <DiscoverDestinations />
            </PrivateRoute> } path="/destinations" exact />

          <Route element={
            <PrivateRoute>
              <DestinationsList />
            </PrivateRoute>} path="/destinations/:path" exact />

          <Route element={
            <PrivateRoute>
              <InterestDestinationDetails />
            </PrivateRoute>} path="/destinations/:prvpath/:path" exact />
            
          <Route element={<Sports />} path="/interest/sports" exact />
          <Route element={<InterestSportsifaDetails />} path="/interest/fifa/details" exact />

          <Route element={
            <PrivateRoute>
              <Aboutus />
            </PrivateRoute>} path="/about-us" exact />

          {/* <Route element={<TermsAndConditions data={organization} />} path="/termsand/condition" exact /> */}

          <Route element={
            <PrivateRoute>
              <ThankYou data={organization} />
            </PrivateRoute>} path="/thank-you" exact />

          <Route element={
            <PrivateRoute>
              <ThankyouExp data={organization} />
            </PrivateRoute>} path="/thank-youexp" exact />

          <Route element={
            <PrivateRoute>
              <ExceptionalExperience />
            </PrivateRoute>} path="/experiences" exact />

          <Route element={
            <PrivateRoute>
              <ExceptionalExperienceDetails />
            </PrivateRoute>} path="/experiences/:path" exact />

          <Route element={
            <PrivateRoute>
              <ExpEnquiry />
            </PrivateRoute>} path="/experiences-enquiry" exact />

          {/* <Route element={
            <PrivateRoute>
              <InstagramPosts />
            </PrivateRoute>} path="/instagram" exact /> */}

          <Route element={<NotFound />} path="*" />
        </Routes>
      <PrivateRoute>
        <Footer data={organization} />
      </PrivateRoute>
    </React.Fragment>
  )
}

export default Router;