import Api from '.';
import { endpoints } from './endpoints';

// const token = localStorage.getItem("token")

export default class postApi extends Api {

    Auth(data) {
        let url = this.buildUrl(endpoints.AuthUrl.auth)
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response)
    }

    Posts(data){
        let url = this.buildUrl(endpoints.Posts.posts)
        return this.fetch(url, "GET", null, data).then(response => response)
    }

    HomeApi(data){
        let url = this.buildUrl(endpoints.HomeUrl.homePage)
        return this.fetch(url, "GET", null, data ).then(response => response)
    }

    InterestApi(data){
            let url = this.buildUrl(endpoints.HomeUrl.interest)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    DestinationApi(data){
            let url = this.buildUrl(endpoints.HomeUrl.destination)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    BookYourNextApi(data){
            let url = this.buildUrl(endpoints.HomeUrl.bookYouNext)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Activities(data){
            let url = this.buildUrl(endpoints.HomeUrl.activities)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Tours(data){
            let url = this.buildUrl(endpoints.HomeUrl.tours)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    FeaturedTours(data){
            let url = this.buildUrl(endpoints.HomeUrl.featuredtours)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Accommodations(data){
            let url = this.buildUrl(endpoints.HomeUrl.accommodations)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    ExploreBanner(data){
            let url = this.buildUrl(endpoints.HomeUrl.exploreInterest)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    DestinationBanner(data){
            let url = this.buildUrl(endpoints.HomeUrl.destinationBanner)
        return this.fetch(url, "GET", null, data).then(response => response)
    }

    Enquiry(data) {
            let url = this.buildUrl(endpoints.HomeUrl.enquiryFormSubmit)
            return this.fetch(url, "POST", JSON.stringify(data), null).then(response => response)
    }

    Newsletter(data) {
            let url = this.buildUrl(endpoints.AuthUrl.newsletter)
            return this.fetch(url, "POST", JSON.stringify(data), null).then(response => response)
    }

    ContactFormSubmit(data) {
            let url = this.buildUrl(endpoints.HomeUrl.contactFormSubmit)
            return this.fetch(url, "POST", JSON.stringify(data), null).then(response => response)
    }

    Organization(data) {
            let url = this.buildUrl(endpoints.HomeUrl.organization)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Aboutus(data) {
            let url = this.buildUrl(endpoints.HomeUrl.aboutus)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Enquirybanner(data) {
            let url = this.buildUrl(endpoints.HomeUrl.enquirybanner)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Contactus(data) {
            let url = this.buildUrl(endpoints.HomeUrl.contactus)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Countries(data) {
            let url = this.buildUrl(endpoints.HomeUrl.countries)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Salutation(data) {
            let url = this.buildUrl(endpoints.HomeUrl.salutation)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    TentativeBudget(data) {
            let url = this.buildUrl(endpoints.HomeUrl.tentativeBudget)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    HolidayDuration(data) {
            let url = this.buildUrl(endpoints.HomeUrl.holiday_duration)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Vaccination(data) {
            let url = this.buildUrl(endpoints.HomeUrl.vaccination)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Enquirsource(data) {
            let url = this.buildUrl(endpoints.HomeUrl.enquirsource)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Testimonials(data) {
            let url = this.buildUrl(endpoints.HomeUrl.testimonials)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    TestimonialsBanner(data) {
            let url = this.buildUrl(endpoints.HomeUrl.commentsbanner)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    TermsAndcConditions(data) {
            let url = this.buildUrl(endpoints.HomeUrl.termsandconditions)
            return this.fetch(url, "GET", null, data).then(response => response)
    }

    Tourenquiry(data) {
        let url = this.buildUrl(endpoints.HomeUrl.tourenquiry)
        return this.fetch(url, "GET", null, data).then(response => response)
    }

    Activityenquiry(data) {
        let url = this.buildUrl(endpoints.HomeUrl.activityenquiry)
        return this.fetch(url, "GET", null, data).then(response => response) 
    }

    ExceptionalExperience(data) {
        let url = this.buildUrl(endpoints.HomeUrl.exceptionalExperience)
        return this.fetch(url, "GET", null, data).then(response => response)
    }

    Experiences(data) {
        let url = this.buildUrl(endpoints.HomeUrl.experiences)
        return this.fetch(url, "GET", null, data).then(response => response)
    }

    ExperienceFormSubmit(data) {
        let url = this.buildUrl(endpoints.HomeUrl.experienceFormSubmit)
        return this.fetch(url, "POST", JSON.stringify(data), null).then(response => response)
}


    // GetRegisteredBanks(data) {
    //     let url = this.buildUrl(endpoints.quickbuy.GetRegisteredBanks)
    //     return this.fetch(url, "POST", JSON.stringify(data)).then(response => response)
    // }
    // folioBrokerList(data) {
    //     let url = this.buildUrl(endpoints.quickbuy.folioBrokerList)
    //     return this.fetch(url, "GET", null, data).then(response => response)
}