import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookYouNextAction } from '../../redux/actions/homeAction'
import { Link } from 'react-router-dom';

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";


const Exprience = () => {
    let dispatch = useDispatch();
    const bookNextData = useSelector((state) => state.home.bookNext);
    const auth = useSelector((state) => state.home.auth);
    
    const [bookNextTrip, setBookNextTrip] = useState(bookNextData)

    useEffect(() => {
        if(auth !== false){
            const token = {token : true}
            dispatch(bookYouNextAction(token))
        }
	},[auth]);

    useEffect(() => {
        if(bookNextData !== undefined){
            setBookNextTrip(bookNextData)
        }
	},[bookNextData]);

    

    const { pageContent} = bookNextTrip

    return <section className="bg-gray journey-wrapper">
    <div className="container">
        <div className="journey">
            {
                pageContent !== undefined ? <>
                    {
                        pageContent.map((item,index) => {
                            return<div className="row" key={index}>
                            <div className="col-md-5 col-sm-12 positionimg">
                                <figure>
                                    <img src={`${imageUrl}/${item.mediaFilePath}`} alt={item.mediaAlText} title={item.mediaTitle} />
                                </figure>
                            </div>
                            <div className="col-md-7 col-sm-12">
                                <div className="inner-spacer">
                                    <h2>{item.title}</h2>
                                    <div className="info-spacer">
                                        <figure className="bottom-img">
                                            {
                                                item.media.map((key,i) =>{
                                                   return <div  key={i}>
                                                        <img className="desktop" src={`${imageUrl}/${key.mediaFilePath}`} alt={key.mediaAlText} title={key.mediaTitle} />
                                                        {/* <img className="tablet"  key={i} src={`${imageUrl}/${key.mediaTabletPath}`} alt={key.mediaAlText} title={key.mediaTitle} />
                                                        <img className="mobile"  key={i} src={`${imageUrl}/${key.mediaMobilePath}`} alt={key.mediaAlText} title={key.mediaTitle} /> */}
                                                   </div>
                                                })
                                            }
                                        </figure>
                                            <div dangerouslySetInnerHTML={{__html: item.content}} />
                                        <button className="btn-primary"><Link to="/contact-us">Connect With Us</Link></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        })
                    }
                </> : ""
            }
        </div>
    </div>
</section>    
}

export default Exprience;