import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { numberText, isValidMobileNo, fetchWithWait, alphabatesWithSpace } from '../../helper/method';
import { enqueryAction } from '../../redux/actions/AuthAction';
import PageLaoder from '../atom/loader/pageLaoder';
import { activityEnquiryAction, countriesAction, enqueryBannerAction, holidaydurationAction, salutationAction, 
    sourceAction, tentativebudgetAction, tourEnquiryAction, vaccinationAction } from '../../redux/actions/homeAction';
import ExploreInterest from '../atom/ExploreInteres';

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";

const Contact = () => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let hasError = false;

    const noOfAdult = [
        {id: 1, value: 1},
        {id: 2, value: 2},
        {id: 3, value: 3},
        {id: 4, value: 4},
        {id: 5, value: 5},
        {id: 6, value: 6},
    ]
    const noOfChildren = [
        {id: 0, value: 0},
        {id: 1, value: 1},
        {id: 2, value: 2},
        {id: 3, value: 3}
    ]

    // const auth = useSelector((state) => state.home.auth);
    // const bannerData = useSelector((state) => state.home.enquiryBanner.banner);
    // const countries = useSelector((state) => state.home.countries);
    const salutation = useSelector((state) => state.home.salutation);
    const durationOfHoliday = useSelector((state) => state.home.holidayduration);
    const tentativeBudget = useSelector((state) => state.home.budget);
    const vaccination = useSelector((state) => state.home.vaccination); 
    const sourcers = useSelector((state) => state.home.sourcers);
    const enquiryTours = useSelector((state) => state.home.enquiryTours);
    // const featureActiviti = useSelector((state) => state.home.featureActiviti);

    const [tourId] = useState( location.state ? location.state[0].id : 0 )
    const [source, setSource] = useState()
    const [sSalutation, setSalutation] = useState("")
    const [sStdCode, setStdCode] = useState("101")
    const [sNoOfAdult, setNoOfAdult] = useState("")
    const [sNoOfChildren, setNoOfChildren] = useState("")
    const [sDurationOfHoliday, setDurationOfHoliday] = useState("")
    const [sTentativeBudget, setTentativeBudget] = useState("")
    const [sVaccination, setVaccination] = useState("")
    const [fname, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobileno, setMobileno] = useState("")
    const [intentaionOfHoliday, setIntentaionOfHoliday] = useState("")
    const [experience, setExperience] = useState("")
    const [spacilEvent, setSpacilEvent] = useState("")
    const [veg, setVeg] = useState(true)
    const [nonVeg, setNonVeg] = useState(false)
    const [vegan, setVegan] = useState(false)
    const [place1, setPlace1] = useState("")
    const [place2, setPlace2] = useState("")
    const [place3, setPlace3] = useState("")
    const [hobbies, setHobbies] = useState("")
    const [pageTitle, setPageTitle] = useState("")
    const [pageContent, setPageContent] = useState([])
    const [count, setCount] = useState(1)
    const [errors, setErrors] = useState({
            errName: "",
            errSalutation: "",
            errStdCode: "",
            errNoOfAdult: "",
            errNoOfChildren: "",
            errDurationOfHoliday: "",
            errVaccination: "",
            errEmail: "",
            errTentativeBudget: "",
            errMobileno: "",
            errDite: "",
        })

    const [banner, setBanner] = useState([])
    const [loading, setLoading] = useState(false)
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)

    useEffect(() => {
        dispatch(enqueryBannerAction())
        dispatch(countriesAction())
        dispatch(salutationAction())
        dispatch(tentativebudgetAction())
        dispatch(holidaydurationAction())
        dispatch(vaccinationAction())
        dispatch(sourceAction())
        dispatch(tourEnquiryAction())
        dispatch(activityEnquiryAction())
    }, [])


    useEffect(() => {
        if(enquiryTours !== undefined){
            setBanner(enquiryTours.banner)
            setPageTitle(enquiryTours.pageTitle)
            setPageContent(enquiryTours.pageContent)
            if(enquiryTours.hasOwnProperty("seoContent") && enquiryTours.seoContent !== null){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = enquiryTours.seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
        }
    }, [enquiryTours])


    useEffect(() => {
        handleErrorCheck()
    }, [count]);

    useEffect(() => {
        if(Array.isArray(sourcers) && sourcers.length){
            setSource(sourcers[0].value)
        }
    }, [sourcers]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const handleErrorCheck = () =>{
        let allState = errors;
        if(count > 0){
            allState["errDite"] = ""
        }else{
            allState["errDite"] = "Select Diet"
        }
        setErrors(prevError => ({
			...prevError,
			...allState
		}))
    }

    const handleStateChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let checked = e.target.checked;
        
        if(name === "salutation"){
            setSalutation(value)
            handleError(e);
        }
        if(name === "fname"){
            let check = String(value)
            if(alphabatesWithSpace(check)){
                setName(check)
                handleError(e);
            }
        }
        if(name === "stdCode"){
            setStdCode(value)
            handleError(e);
        }
        if(name === "noOfAdult"){
            setNoOfAdult(value)
            handleError(e);
        }
        if(name === "noOfChildren"){
            setNoOfChildren(value)
            handleError(e);
        }
        if(name === "durationOfHoliday"){
            setDurationOfHoliday(value)
            handleError(e);
        }
        if(name === "tentativeBudget"){
            setTentativeBudget(value)
            handleError(e);
        }
        if(name === "vaccination"){
            setVaccination(value)
            handleError(e);
        }
        if(name === "email"){
            setEmail(value)
            handleError(e);
        }
        if(name === "mobileno"){
            let check = value
            if(numberText(check)){
                setMobileno(check)
                handleError(e);
            }
        }
        if(name === "intentaionOfHoliday"){
            setIntentaionOfHoliday(value)
            handleError(e);
        }
        if(name === "experience"){
            setExperience(value)
            handleError(e);
        }
        if(name === "spacilEvent"){
            setSpacilEvent(value)
            handleError(e);
        }
        if(name === "place1"){
            setPlace1(value)
        }
        if(name === "place2"){
            setPlace2(value)
        }
        if(name === "place3"){
            setPlace3(value)
        }
        if(name === "hobbies"){
            setHobbies(value)
        }
        if(name === "veg"){
            setVeg( checked ? true : false )
            handleCount(e);
        }
        if(name === "nonVeg"){
            setNonVeg( checked ? true : false )
            handleCount(e);
        }
        if(name === "vegan"){
            setVegan( checked ? true : false )
            handleCount(e);
        }
    }

    const handleCount = (e) => {
        let name = e.target.name
        let checked = e.target.checked
        if(name === "veg"){
           checked ? setCount(count + 1) : setCount(count - 1)
        }
        if(name === "nonVeg"){
           checked ? setCount(count + 2) : setCount(count - 2)
        }
        if(name === "vegan"){
           checked ? setCount(count + 3) : setCount(count - 3)
        }
    }

    const handleError = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let allState = errors;
        // console.log("Name",name, "Value",value, "Count", count)

        if(name === "salutation"){
            if(!value.length){ 
                allState["errSalutation"] = "Select Salutation"
            }else{
                allState["errSalutation"] = ""
            }
        }
        if(name === "fname"){
            if(!value.length){
                allState["errName"] = "Enter your full name"
            }else if(value.length < 3){
                allState["errName"] = "Name length should be 3 charector"
            }else{
                allState["errName"] = ""
            }
        }
        if(name === "stdCode"){
            if(!value.length){
                allState["errStdCode"] = "Select Code"
            }else{
                allState["errStdCode"] = ""
            }
        }
        if(name === "noOfAdult"){
            if(!value.length){
                allState["errNoOfAdult"] = "Select no. of Adults"
            }else{
                allState["errNoOfAdult"] = ""
            }
        }
        if(name === "noOfChildren"){
            if(!value.length){
                allState["errNoOfChildren"] = "Select no. of Children"
            }else{
                allState["errNoOfChildren"] = ""
            }
        }
        if(name === "durationOfHoliday"){
            if(!value.length){
                allState["errDurationOfHoliday"] = "Select Duration of holiday"
            }else{
                allState["errDurationOfHoliday"] = ""
            }
        }
        if(name === "tentativeBudget"){
            if(!value.length){
                allState["errTentativeBudget"] = "Select Tentative Budget"
            }else{
                allState["errTentativeBudget"] = ""
            }
        }
        if(name === "vaccination"){
            if(!value.length){
                allState["errVaccination"] = "Select Vaccination status"
            }else{
                allState["errVaccination"] = ""
            }
        }
        if(name === "email"){
            let mailformat =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!value.length){
                allState["errEmail"] = "Enter Email ID"
            }else if(!value.match(mailformat)){
                allState["errEmail"] = "Enter a valid email address"
            }else{
                allState["errEmail"] = ""
            }
        }
        if(name === "mobileno"){
            if(!value.length){
                allState["errMobileno"] = "Enter Mobile number"
            }else if(value.length ){
                if(!isValidMobileNo(value)){
                    allState["errMobileno"] = "Invalid Mobile No."
                }else{
                    allState["errMobileno"] = ""
                }
            }
        }
        setErrors(prevError => ({
			...prevError,
			...allState
		}))
    }

    const checkVailidation = () =>{
        hasError = false;
        let allState = errors;
        let mobile = mobileno.toString()

        if(!email.length){
            let mailformat =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!email.length){
                allState["errEmail"] = "Enter Email ID"
                hasError = true
            }else if(!email.match(mailformat)){
                allState["errEmail"] = "Enter a valid email address"
                hasError = true
            }
        }
        if(!fname.length){
            allState["errName"] = "Enter your full name"
            hasError = true
        }else if(fname.length < 3 ){
            allState["errName"] = "Name length should be 3 charector"
            hasError = true
        }
        if(!sSalutation.length){
            allState["errSalutation"] = "Select Salutation"
            hasError = true
        }
        if(!sStdCode.length){
            allState["errStdCode"] = "Select Code"
            hasError = true
        }
        if(!sNoOfAdult.length){
            allState["errNoOfAdult"] = "Select no. of Adults"
            hasError = true
        }
        if(!sNoOfChildren.length){
            allState["errNoOfChildren"] = "Select no. of Children"
            hasError = true
        }
        if(!sDurationOfHoliday.length){
            allState["errDurationOfHoliday"] = "Select Duration of holiday"
            hasError = true
        }
        if(!sVaccination.length){
            allState["errVaccination"] = "Select Vaccination status"
            hasError = true
        }
        if(!sTentativeBudget.length){
            allState["errTentativeBudget"] = "Select Tentative Budget"
            hasError = true
        }
        if(!mobile.length){
            allState["errMobileno"] = "Enter Mobile number"
            hasError = true
        }else if(mobile.length){
            if(!isValidMobileNo(mobileno)){
                allState["errMobileno"] = "Invalid Mobile No."
            }else{
                allState["errMobileno"] = ""
            }
        }
        if(count > 0){
            allState["errDite"] = ""
        }else{
            allState["errDite"] = "Select Diet"
        }

        setErrors(prevError => ({
			...prevError,
			...allState
		}))
        return hasError
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        if(!checkVailidation()){
            setLoading(true)
            let data = {
                guestName : fname,
                adults : sNoOfAdult,
                childrens : sNoOfChildren,
                salutation : sSalutation,
                guestEmail : email,
                phoneCode : sStdCode,
                guestMobile : mobileno,
                durationOfHoliday : sDurationOfHoliday,
                holidayIntention : intentaionOfHoliday,
                holidayExperience : experience,
                holidaySpecialEvent : spacilEvent,
                tentativeBudget : sTentativeBudget,
                vaccination : sVaccination,
                dietVEG : veg,
                dietNonVeg : nonVeg,
                dietVegan : vegan,
                visitedPlaceOne : place1,
                visitedPlaceTwo : place2,
                visitedPlaceThree : place3,
                guestHobbies : hobbies,
                source : source,
                prdTour : tourId,
            }
            fetchWithWait({ dispatch, action: enqueryAction(data) }).then((res) => {
                if(res.message === "success"){
                    setLoading(false)
                    navigate("/thank-you", {state: "Tour"})
                }
            }).catch((e) => {
                setLoading(false)
                console.log(`error`, e)
            })
        }
    }

    console.log("Tour Enquiry banner", banner)

    return (
        <div className='Tour-Enquiry'>
            <Helmet>
                <title>{ metaTitle !== null ? metaTitle : "Contact us" }</title>
                <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
                <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
                <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
                <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
                <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
                <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:type" content={ socialType !== null ? socialType : null } />
            </Helmet>

        { banner !== undefined && banner.length ?
            <ExploreInterest
                items={1}
                loop = {false}
                margin= {10}
                animateOut= {'fadeOut'}
                outoplay = {true}
                autoplayHoverPause= {true}
                speed = {5000}
                responsiveClass = {true}
                banner={banner}
        /> : <PageLaoder />}
            <div className="wrapper">
                <section className="container">
                    <div className="enquiry-form">
                        <ul className="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">{pageTitle !== undefined && pageTitle.length ? pageTitle : "Tour Enquiry"}</li>
                        </ul>
                        <div className="enquire-box">
                         { pageTitle !== undefined && pageTitle.length ? <h2>{pageTitle} Form</h2> : "form"} 
                       { pageContent !== undefined && pageContent.length ? <div dangerouslySetInnerHTML={{__html: pageContent[0].content}} /> : null }
                        <div className="mandate-txt">*Mandatory Fields</div>
                        <form method="post">
                            <div className="row">
                                <div className="col-sm-3 col-lg-3">
                                    <div className="form-group">
                                        <div className="select-option">
                                            <select 
                                                required="required" 
                                                className="form-control form-select"
                                                onChange={(e) =>handleStateChange(e)}
                                                onBlur={(e) =>handleError(e)}
                                                value={sSalutation}
                                                name="salutation"
                                            >
                                                <option value="">Salutation *</option>
                                                {
                                                    salutation.map((item,index) =>{
                                                        return <option key={index} value={item.value}>{item.label}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <span className="text-danger">{errors["errSalutation"]}</span>
                                    </div>
                                </div>
                                <div className="col-sm-9 col-lg-9">
                                    <div className="form-group">
                                        <input 
                                            className="form-control"
                                            type="text" 
                                            name="fname" 
                                            value={fname}
                                            required="required"
                                            onBlur={handleError}
                                            placeholder="Name of Guest *" 
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                        <span className="text-danger">{errors.errName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-6">
                                    <div className="form-group">
                                        <input 
                                            className="form-control"
                                            type="text" 
                                            name="email" 
                                            value={email}
                                            required="required" 
                                            placeholder="Email address *" 
                                            onBlur={handleError}
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                        <span className="text-danger">{errors.errEmail}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="sr-only" htmlFor="inlineFormInputGroup">Mobile *</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text select-option">
                                                    <select className="form-select"
                                                        onChange={(e) =>handleStateChange(e)}
                                                        onBlur={(e) =>handleError(e)}
                                                        value={sStdCode}
                                                        name="stdCode"
                                                    >
                                                        <option value="101">+91</option>
                                                        {/* {
                                                            stdCode.map((item,index) =>{
                                                                return <option key={index} value={item.value}>{item.label}</option>
                                                            })
                                                        } */}
                                                    </select>
                                                   
                                                </div>
                                            </div>
                                            <input 
                                                className="form-control"
                                                type="tel"  
                                                name="mobileno" 
                                                value={mobileno}
                                                required="required" 
                                                placeholder="Enter Mobile number*" 
                                                onBlur={handleError}
                                                onChange={handleStateChange}
                                                maxLength= "10"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <span className="text-danger">{errors.errStdCode} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {errors.errMobileno}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 col-lg-3">
                                    <div className="form-group">
                                        <div className="select-option">
                                            <select 
                                                required="required" 
                                                className="form-control form-select"
                                                value={sNoOfAdult}
                                                onChange={(e) =>handleStateChange(e)}
                                                onBlur={(e) =>handleError(e)}
                                                name="noOfAdult"
                                            >
                                                <option value="">Adult *</option>
                                                {
                                                    noOfAdult.map((item,index) =>{
                                                        return <option key={index} value={item.value}>{item.id}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <span className="text-danger">{errors.errNoOfAdult}</span>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-lg-3">
                                    <div className="form-group">
                                        <div className="select-option">
                                            <select 
                                                required="required" 
                                                className="form-control form-select"
                                                value={sNoOfChildren}
                                                onChange={(e) =>handleStateChange(e)}
                                                onBlur={(e) =>handleError(e)}
                                                name="noOfChildren"
                                            >
                                                <option value="">Children *</option>
                                                {
                                                    noOfChildren.map((item,index) =>{
                                                        return <option key={index} value={item.value}>{item.id}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <span className="text-danger">{errors.errNoOfChildren}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="select-option">
                                            <select 
                                                value={sDurationOfHoliday} 
                                                onChange={(e) =>handleStateChange(e)}
                                                onBlur={(e) =>handleError(e)}
                                                required="required" 
                                                className="form-control form-select"
                                                name="durationOfHoliday"
                                            >

                                                <option value="">Duration of holiday *</option>
                                                {
                                                    durationOfHoliday.map((item,index) =>{
                                                        return <option key={index} value={item.value}>{item.label}</option>
                                                    })
                                                }
                                            </select>
                                        
                                        </div>
                                        <span className="text-danger">{errors.errDurationOfHoliday}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-lg-12">
                                    <div className="form-group">
                                        <textarea 
                                            className="form-control" 
                                            rows="3" 
                                            cols="50" 
                                            placeholder="Intention behind the holiday" 
                                            name="intentaionOfHoliday"
                                            value={intentaionOfHoliday}
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-lg-12">
                                    <div className="form-group">
                                        <textarea 
                                            className="form-control" 
                                            rows="3" 
                                            cols="50" 
                                            placeholder="Any specific experience / experiences they want to enjoy during the said holiday" 
                                            name="experience" 
                                            value={experience}
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-lg-12">
                                    <div className="form-group">
                                        <textarea 
                                            className="form-control" 
                                            rows="3" cols="50" 
                                            placeholder="Any special event during the holiday" 
                                            name="spacilEvent"
                                            value={spacilEvent}
                                            onChange={handleStateChange} 
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="select-option">
                                            <select 
                                                required="required" 
                                                className="form-control form-select m-0" 
                                                value={sTentativeBudget}
                                                onChange={(e) =>handleStateChange(e)}
                                                onBlur={(e) =>handleError(e)}
                                                name="tentativeBudget"
                                            >
                                                <option value="">Tentative Budget *</option>
                                                {
                                                    tentativeBudget.map((item,index) =>{
                                                        return <option key={index} value={item.value}>{item.label}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <span className="text-danger d-block">{errors.errTentativeBudget}</span>
                                        <span>Tentative budget planned for the holiday (Land Alone)</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <div className="form-group">
                                        <div className="select-option">
                                            <select 
                                                required="required" 
                                                className="form-control form-select"
                                                value={sVaccination}
                                                onChange={(e) =>handleStateChange(e)}
                                                onBlur={(e) =>handleError(e)}
                                                name="vaccination"
                                            >
                                                <option value="">Vaccination Status *</option>
                                                {
                                                    vaccination.map((item,index) =>{
                                                        return <option key={index} value={item.value}>{item.label}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <span className="text-danger">{errors.errVaccination}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-6">
                                    <label>Diet *</label>
                                    <div className="form-group">
                                        <div className="form-check form-check-inline">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                id="veg" 
                                                value={veg}
                                                checked={veg}
                                                name="veg"
                                                onChange={handleStateChange}
                                                // onBlur={(e) =>handleError(e)}
                                            />
                                                <label className="form-check-label" htmlFor="veg">Veg</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                id="non-veg" 
                                                value={nonVeg} 
                                                checked={nonVeg} 
                                                name="nonVeg"
                                                onChange={handleStateChange}
                                                // onBlur={(e) =>handleError(e)}
                                            />
                                                <label className="form-check-label" htmlFor="non-veg">Non-veg</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                id="vegan" 
                                                value={vegan}
                                                name="vegan"
                                                checked={vegan}
                                                onChange={handleStateChange}
                                                // onBlur={(e) =>handleError(e)}
                                            />
                                                <label className="form-check-label" htmlFor="vegan">Vegan</label>
                                        </div>
                                    <div>
                                        <span className="text-danger">{errors.errDite}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                    <label>Last 3 holiday places visited</label>
                                    <div className="form-group place-inline">
                                        <input 
                                            className="form-control"
                                            type="text" 
                                            name="place1" 
                                            value={place1}
                                            required="required" 
                                            placeholder="Place 1" 
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                        <input 
                                            className="form-control"
                                            type="text" 
                                            name="place2" 
                                            value={place2}
                                            required="required" 
                                            placeholder="Place 2"  
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                        <input 
                                            className="form-control"
                                            type="text" 
                                            name="place3" 
                                            value={place3}
                                            required="required" 
                                            placeholder="Place 3" 
                                            onChange={handleStateChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-lg-12"> <label>Hobbies</label></div>
                                    <div className="col-sm-5 col-lg-5">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="hobbies" 
                                                value={hobbies}
                                                required="required" 
                                                placeholder="Hobbies" 
                                                onChange={handleStateChange}
                                                className="form-control" 
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="button-center">
                                    <button className="btn-sec" onClick={handleSubmit}><a href="thank-you.shtml">Submit</a></button>
                                </div>
                                </form>
                            </div>
                        </div>
                </section>
            </div>
            <>
                {loading &&
                    <PageLaoder />
                }
            </>
        </div>
    );
}

export default Contact;