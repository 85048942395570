import React,{useEffect,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Monsary from '../atom/Monsary';
import Exprience from '../atom/Exprience';
import ExploreInteres from '../atom/ExploreInteres';
import { interestDatas, exploreBanner } from '../../redux/actions/homeAction';
import PageLaoder from '../atom/loader/pageLaoder';
import { Helmet } from 'react-helmet';


let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";

const ExploreInterest = () => {

    const navigate = useNavigate();
    let dispatch = useDispatch();


    const bannerData = useSelector((state) => state.home.explorBanner);
    const interestData = useSelector((state) => state.home.interest);
    

    const [banner, setBanner] = useState()
    const [interest, setInterest] = useState(interestData)
    const [pageContent, setPageContent] = useState()
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)

    let second =  interest.length/2
    
    useEffect(() => {
        dispatch(interestDatas())
        dispatch(exploreBanner())
	},[]);


    useEffect(() => {
        if(interestData !== undefined){
            setInterest(interestData)
        }
    }, [interestData])

    useEffect(() => {
        if(bannerData !== undefined){
            setBanner(bannerData.banner)
            setPageContent(bannerData.pageContent)
            if(bannerData.hasOwnProperty("seoContent") && bannerData.seoContent !== null ){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = bannerData.seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
        }
    }, [bannerData])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return <div className="interest-page">
        <Helmet>
            <title>{ metaTitle !== null ? metaTitle : "Interest" }</title>
            <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
            <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
            <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
            <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
            <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
            <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
            <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
            <meta property="og:type" content={ socialType !== null ? socialType : null } />
        </Helmet>

        {banner !== undefined ? 
            <ExploreInteres 
                items={1}
                loop = {false}
                margin= {10}
                animateOut= {'fadeOut'}
                outoplay = {true}
                autoplayHoverPause= {true}
                speed = {5000}
                responsiveClass = {true}
                banner={banner}
        /> : <PageLaoder />}
        
        <section className="explore-interest explore-int">
            <div className="container">
                <div className="row">
                    <ul className="breadcrumb">
                        <li><Link to="/">Home</Link></li> 
                        <li className="active">Explore Interest</li>
                    </ul>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2>{bannerData.pageTitle}</h2>
                            <div className="info-spacer">
                            {
                                pageContent !== undefined  && pageContent.map((item,index) => {
                                    return<div key={index} dangerouslySetInnerHTML={{__html: item.content}} />
                                })
                            }
                                <div className="row explore-int">
                                    {
                                        interest.length && interest.slice(0,second).map(item =>{
                                            return (
                                                <div className="card int-card" key={item.id}
                                                    onClick={() => navigate(item.slug !== "sports" ? `/interest/${item.slug}` : "/interest/sports", {state:[item, item.slug]})}
                                                >
                                                    <figure>
                                                        <img src={`${imageUrl}/${item.iconPath}`} alt={item.interest} title={item.interest} />
                                                    </figure>
                                                    <span>{item.interest}</span>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        interest.length && interest.slice(second).map(item =>{
                                            return (
                                                <div className="card int-card" key={item.id}
                                                    onClick={() => navigate(item.slug !== "sports" ? `/interest/${item.slug}` : "/interest/sports", {state:[item, item.slug]})}
                                                >
                                                    <figure>
                                                        <img src={`${imageUrl}/${item.iconPath}`} alt={item.interest} title={item.interest} />
                                                    </figure>
                                                    <span>{item.interest}</span>
                                                </div>
                                            )
                                        })
                                    }   
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
        </section>
        <section className=''>
            <div className='container'>
                <div className="row image-gallery">
                    <Monsary interest = {interest} columnsCount="2" gap="5" />  
                </div>
            </div>
        </section>    
       <Exprience />             
    </div>;
}

export default ExploreInterest;