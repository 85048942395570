import React,{useState,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/logo.png'
import { FaEnvelope,FaLinkedinIn,FaInstagram,FaFacebookF,FaYoutube } from "react-icons/fa";
import { subscribeAction } from '../redux/actions/AuthAction';
import { fetchWithWait } from '../helper/method';

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";

const Footer = ({data}) => {

    let dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState("")
    const [success, setSuccess] = useState("")
    const [errEmail, setErrEmail] = useState("")
    let hasError;


    const handleState = (e) =>{
        let name = e.target.name
        let value  = e.target.value
        if(name === "email"){
            setEmail(value)
            handleError(e)
        }
    }

    const handleError = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log("handle OnBlure Change", name, "value", value)

    if(name === "email"){
        let mailformat =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!email.length){
                setErrEmail("Email address is required")
            }else if(!email.match(mailformat)){
                setErrEmail("Enter a valid email address")
            }else{
                setErrEmail("")
            }
        }
    }

    const checkVailidation = () =>{
        hasError = false;

        let mailformat =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!email.length){
            setErrEmail("Email address is required")
            hasError = true
        }else if(!email.match(mailformat)){
            setErrEmail("Enter a valid email address")
            hasError = true
        }
        return hasError
    }

    

    const handleSubscribe = (e) => {
        e.preventDefault()
        if(!checkVailidation()){
            const token = localStorage.getItem("token")
            let data = {
                email: email
            }
            if(token.length){
                let token = {
                    token : true,
                    data: data
                }
                fetchWithWait({ dispatch, action: subscribeAction(token) }).then((res) => {
                    setSuccess(res.data)
                    setEmail("")
				}).catch((e) => {
					console.log(`error`, e)
				})
            }
        }
    }

    return (
        <footer>
            <div className="container">
                <div className="row footer-list">
                    <div className="col-sm-3 col-md-12 col-lg-3">
                        <li className='list-item'>Get the latest from Lux Voyages by Yusuf</li>
                    </div>
                    <div className="col-sm-5 col-md-12 col-lg-5">
                        <li className='list-item input'>
                            <input 
                                type="email"
                                placeholder="Email id"
                                name="email"
                                className="footer-text"
                                value={email}       
                                onChange={handleState}
                                onBlur={handleError}
                                autoComplete="off"
                            />
                            <button className="btn-primary footer-btn" onClick={handleSubscribe}>Subscribe</button>
                        </li>
                        { success.length ? <span style={{color:"#fff100",textAlign:"left", padding: 0 }}>{success}</span> : "" }
                        { errEmail.length ? <span style={{color: "#fff100", textAlign:"left", padding: 0 }}>{errEmail}</span> : "" }
                    </div>
                    <div className="col-sm-4 col-md-12 col-lg-4">
                        <li className='list-item'>
                            <button className="btn-primary explore-button"><Link to="contact-us">Contact With Us</Link></button>
                        </li>
                    </div>
                </div>
                <div className="row footer-list">
                    <div className="footer-items">
                        { data.companyLogoFilePath ?
                            <Link to={"/"} className='logo'><img src={`${imageUrl}/${data.companyLogoFilePath}`} alt={data.company} title={data.company} /></Link>
                            : null
                        }
                        <div className="">
                            <ul>
                                <li className="li-style"><Link to={"/interest/"}>Explore Interest</Link></li>
                                <li className="li-style"><Link to={"/destinations"}>Discover Destinations</Link></li>
                            </ul>
                        </div>
                        <div className="">
                            <ul>
                                {/* <li className="li-style"><Link to={"/comments"}>Experiential Comments</Link></li> */}
                                <li className="li-style"><Link to={"/about-us"}>About LE</Link></li>
                                <li className="li-style"><Link to={"/experiences"}>Exceptional Experiences</Link></li>
                                {/* <li className="li-style"><Link to={"/instagram"}>Instagram</Link></li> */}
                            </ul>
                        </div>
                        <div className="unorder-list">
                        { data.companyLogoFilePath ?
                            <ul>
                                <li className='list-item'>
                                    <a href={`mailto:${data.offices[0].officeEmail}`} >{data.offices[0].officeEmail}</a>
                                </li>
                                <li>
                                    <ul className="social-list">
                                        { data.offices[0].officeEmail ? <li>
                                            <a href={`mailto:${data.offices[0].officeEmail}`} title="Mail"><FaEnvelope /></a>
                                        </li> : null }
                                        { data.companyFacebookUrl ? <li>
                                            <a href={data.companyFacebookUrl} target="_blank" title="Facebook"><FaFacebookF /></a>
                                        </li> : null }
                                        { data.companyInstagramUrl ? <li>
                                            <a href={data.companyInstagramUrl} target="_blank" title="Instagram"><FaInstagram /></a>
                                        </li> : null }
                                        { data.companyLinkedinUrl ? <li>
                                            <a href={data.companyLinkedinUrl} target="_blank" title="Linkedin"><FaLinkedinIn /></a>
                                        </li> : null }
                                        { data.companyYoutubeUrl ? <li>
                                            <a href={data.companyYoutubeUrl} target="_blank" title="Youtube"><FaYoutube /></a>
                                        </li> : null }
                                    </ul>
                                </li>
                            </ul> : null
                        }
                        </div>
                    </div>
                </div>
                <div className="row copyright">
                    <div className="col-sm-4 col-md-4">
                        {/* <p>
                            <Link to="/termsand/condition" >Terms & Conditions</Link> | <Link to="/termsand/condition" >Disclaimer</Link>
                        </p> */}
                        <p>Copyright © 2022 Lux Voyages by Yusuf. All rights reserved.</p>
                    </div>
                    <div className="col-sm-4 col-md-4">
                        {/* <p>Copyright © 2022 Luxury Escapades. All rights reserved.</p> */}
                    </div>
                    <div className="col-sm-4 col-md-4 powered-by">
                        <p>Powered by <a href="https://www.sublimis.tech/" target="_blank">ePorium</a> | <a href="https://www.sublimis.tech/" target="_blank">Sublimis</a></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;