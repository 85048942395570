import React,{useEffect,useState} from 'react';
import { useNavigate, Link, useParams  } from "react-router-dom";
import { useDispatch } from 'react-redux';
import moment from 'moment';

import Exprience from '../atom/Exprience';
import ToursBanner from '../atom/ToursBanner';
import PageLaoder from '../atom/loader/pageLaoder';
import { Helmet } from 'react-helmet';
 

let imageUrl = process.env.REACT_APP_IMAGE_URL + "/images";
let baseUrl = process.env.REACT_APP_BASE_URL;


const DestinationsList = () => {
    const navigate = useNavigate();
    // let dispatch = useDispatch();
    const { path } = useParams();


    const [banner, setBanner] = useState()
    const [bannerdata, setBannerData] = useState()
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [discovedata, setDiscoverData] = useState()
    const [details, setDetails] = useState()
    const [options, setOptions] = useState([])
    const [metaTitle, setMetaTitle] = useState(null)
    const [metaKeyword, setMetaKeyword] = useState(null)
    const [metaDescription, setMetaDescription] = useState(null)
    const [canonicalURl, setCanonicalURl] = useState(null)
    const [focusKeyPhrase, setFocusKeyPhrase] = useState(null)
    const [keyPhraseSynonyms, setKeyPhraseSynonyms] = useState(null)
    const [schema, setSchema] = useState(null)
    const [socialDescription, setSocialDescription] = useState(null)
    const [socialIcon, setSocialIcon] = useState(null)
    const [socialIconPath, setSocialIconPath] = useState(null)
    const [socialTitle, setSocialTitle] = useState(null)
    const [socialType, setSocialType] = useState(null)


    useEffect(() => {
        const token = localStorage.getItem("token")
        const fetchData = async() =>{
            const fetchApi = await fetch(`${baseUrl}destinations/${path}/tours`,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                }
            })
            const data = await fetchApi.json();
            console.log("discover data ",data)
            setDiscoverData(data.data.tours)
            setDetails(data.data.tours)
            setBannerData(data.data.destinations)
            if(data.message === "success"){
                setLoading(true)
            }else{ setLoading(true) }
        }
        fetchData();
    }, [])


    useEffect(() => {
        let arr = [], option = [];
        if(details !== undefined){
            details.forEach(item => {
                const {interests} = item
                interests.map(key =>{
                    if(!arr.includes(key.interest)){
                        arr.push(key.interest)
                        option.push({label: key.interest, value: key.interest})
                    }
                })
            });
        }
        setOptions(option)
    }, [details])


    useEffect(() => {
        if(loading){
            const  {mdaMedia, destination, description, seoContent} = bannerdata[0]
            setBanner(mdaMedia[0])
            setTitle(destination)
            setDescription(description)

            if(bannerdata[0].hasOwnProperty("seoContent") && seoContent !== null){
                const {canonicalURl, focusKeyPhrase, keyPhraseSynonyms, metaDescription, metaKeyWord, 
                metaTitle, schema, socialDescription, socialIcon, socialIconPath, socialTitle, socialType } = bannerdata[0].seoContent
                setMetaTitle(metaTitle)
                setMetaKeyword(metaKeyWord)
                setMetaDescription(metaDescription)
                setCanonicalURl(canonicalURl)
                setFocusKeyPhrase(focusKeyPhrase)
                setKeyPhraseSynonyms(keyPhraseSynonyms)
                setSchema(schema)
                setSocialDescription(socialDescription)
                setSocialIcon(socialIcon)
                setSocialIconPath(socialIconPath)
                setSocialTitle(socialTitle)
                setSocialType(socialType)
            }
        }
    }, [loading])

    const handleSelectChange = (value) =>{
        let arr = []
        details.length && details.filter((item) => {
            const { interests } = item
            interests.forEach(key => {
                if(value === key.interest){
                    arr.push(item)
                    setDiscoverData([...arr])
                }else if(value === "All"){
                    return setDiscoverData(details)
                }
            })
        })
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const handelCounterViews = (data) =>{
        console.log("data")
    }
    
    const handelCounterLikes = (id) =>{
        console.log("data")
    }

    return <React.Fragment>

            <Helmet>
                <title>{ metaTitle !== null ? metaTitle : "Destinations" }</title>
                <meta name="description" content ={ metaDescription !== null ? metaDescription : null } />
                <meta name="keywords" content ={ metaKeyword !== null ? metaKeyword : null } />
                <link rel="canonical" href ={ canonicalURl !== null ? `${canonicalURl}` : null } />
                <meta property="og:title" content={socialTitle !== null ? socialTitle : null } />
                <meta property="og:description" content={socialDescription !== null ? socialDescription : null} />
                <meta property="og:image" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:url" content={ socialIconPath !== null ? `${imageUrl}/${socialIconPath}` : null } />
                <meta property="og:type" content={ socialType !== null ? socialType : null } />
            </Helmet>

           { banner !== undefined ?
            <ToursBanner 
                items={1}
                loop = {false}
                margin= {10}
                animateOut= {'fadeOut'}
                outoplay = {true}
                autoplayHoverPause= {true}
                speed = {5000}
                responsiveClass = {true}
                banner={banner}
            /> : <PageLaoder /> }
        <div className="discover-page">
            <section className="discover-sec">
                <div className="container">
                    <div className='row'>
                        <ul className="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li className="non-active"><Link to="/destinations">Discover Destinations</Link></li>
                            <li className="active">{title !== null ? title : "" }</li>
                        </ul>
                    </div>
                    {loading ? <h1>{bannerdata[0].destination}</h1> : null }
                    <div className="row info-spacer">
                        <div className="col-md-12 col-sm-12">
                            {description !== null ? <div dangerouslySetInnerHTML={{__html: description }} />: null }
                        </div>
                            <div className="col-md-7 col-sm-12">
                                <span>Interest</span>
                                <select className="form-control" onChange={(e) => handleSelectChange(e.target.value)}>
                                    <option value={"All"}>All</option>
                                    {
                                        options.length && options.map((item, i) => {
                                            return <option key = {i} value = {item.value} >{item.label}</option>
                                        })
                                    }
                                </select>
                            </div>
                        <div className="offset-7"></div>
                    </div>
                </div>
            </section>
            { discovedata !== undefined && discovedata.length ? <section>
                <div className="container">
                    <div className="distination-list">
                        <div className="row">
                            {
                                discovedata !== undefined && discovedata.map((item,index) =>{
                                    return <React.Fragment key={index}>
                                        {
                                            item.mdaMedia.map((data,i) =>{
                                                const [bannerId] = data.mediaListing
                                                if( data.isActive && bannerId.id === 12 ){
                                                    return (<div className="col-md-6 col-ms-12" key={i}>
                                                        <div className="card">
                                                        <figure 
                                                        onClick={() => navigate(`/destinations/${path}/${item.slugName}`, {state:[item ,item.slugName]})}>
                                                            <img src={`${imageUrl}/${data.mediaFilePath}`} alt={data.mediaAlText} title={data.mediaTitle} />
                                                            {item.departureDate ? <span>{`Guaranteed Departuren ${ ((moment(item.departureDate[0]).format('L').toString().slice(-4)))}`}</span> : null}
                                                        </figure>
                                                        <div className="card-body">
                                                            <p>{item.tour}</p>
                                                                {
                                                                    item.interests.map((logo,j) =>{
                                                                        return <span className="logo" key={j}>
                                                                        <img src={`${imageUrl}/${logo.iconPath}`} alt={data.mediaAlText} title={data.mediaTitle} />
                                                                        </span>
                                                                    })
                                                                }
                                                            {/* <div className="logo-sec"> 
                                                                <span className="view-icon" onClick={() => handelCounterViews()}><FaEye /></span> <span>{201}</span>
                                                                <span className="thumb-icon" onClick={() => handelCounterLikes()}><FaThumbsUp /></span> <span>{203}</span>
                                                            </div> */}
                                                        </div>
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            })
                                        }
                                    </React.Fragment>
                                })
                            }
                        </div>
                    </div>
                </div>
            </section> : <div className='container my-5'>
                <div className='card'>
                    <h3 style={{padding:"1rem",fontSize:"20px"}}>No records found</h3>
                </div>
            </div>
            }
        <Exprience />
        {!loading ? <PageLaoder /> : null}    
        </div>

    </React.Fragment>;
}

export default DestinationsList;